<template>
  <div>
    <div class="p-grid">
      <backBtn />
    </div>
    <ContextMenu :model="menuModel" ref="cm" />
    <div class="p-grid">
      <div class="p-col-6">
        <div class="card card-w-title pt-2 ">
          <DataTable 
            :value="table_data" 
            class="p-datatable-responsive" 
            :rows="20" 
            contextMenu 
            :contextMenuSelection.sync="cMenu" 
            @row-contextmenu="onRowContextMenu" 
            :lazy="true" 
            :loading="loading">
            <template #header>Transaction Detail</template>
            <Column field="order_number" header="Order Number" />
            <Column field="record_date" header="Order Date" />
            <Column field="dist_id" header="Distributor ID" />
            <Column field="dist_name_1" header="Distributor’s Name" />
            <Column field="country" header="Country" />
          </DataTable>
        </div>
      </div>
      <div class="p-col-6">
        <Accordion class="card card-w-title p-field p-col-12 p-md-12 accordion-custom">
          <AccordionTab header="Add New Comment">
            <div class="p-datatable card card-w-title p-field p-col-12 p-md-12 float-r" >
              <h4 class="p-datatable-header">Reason/Comment</h4>
              <Textarea id="txt" :value="value" :autoResize="true" rows="5" cols="30" class="w-inherit" />
            </div>
            <Button label="Save" class="m-2 float-right" @click="saveComment"/>
            <div class="card card-w-title p-field p-col-12 p-md-12">
              <DataTable class="comment_table" :value="new_comment_info_table">
                <template #header>Reason/Comment Preview</template>
                <Column field="comment_date" header="Date"></Column>
                <Column field="comment_user" header="User"></Column>
                <Column field="comment_body" header="Comment"></Column>
                <Column bodyStyle="text-align: center" >
                    <template #header></template>
                    <template #body="slotProps">
                      <Button type="button" icon="pi pi-trash" :slotProps="slotProps" @click="deleteComment(slotProps.data.row_id)" class="center"></Button>
                    </template>
                </Column>
                <template #empty>
                  No new comments
                </template>
              </DataTable>
            </div>
          </AccordionTab>
        </Accordion>
        <div class="card card-w-title p-field p-col-12 p-md-12">
          <FileUpload 
            name="files" 
            chooseLabel="Upload" 
            :multiple="true"
            :customUpload="true"
            @uploader="myUploader"
            accept=".csv, .xlx, .xlsx, .pdf, .doc, .txt, .jpg, .jpeg, .png, .docx, .PNG, .JPG, .JPEG" 
            :maxFileSize="10000000" >
            <template #empty>
              <p>Drag and drop files here to upload.</p>
            </template>
          </FileUpload>
        </div>
      </div>
    </div>
    <!-- choose type + action section  -->
    <div class="row">
      <div class="col-12 mb-1 mt-3">
        <div class="col-4 d-inline-block">
          <h6>Exception Type</h6>
          <Dropdown class="col-12" v-model="selectedType" :options="exc_type" optionLabel="exception_type" @change="onDropdownChange" />
        </div>
        <div class="col-4 d-inline-block">
          <h6>Action</h6>
          <Dropdown class="col-12" v-model="selectedAction" :options="exc_action" optionLabel="action_name" :disabled="!selectedType" @change="onAction"/>
        </div>
        <div class="col-4 d-inline-block">
          <h6>Date</h6>
          <Calendar id="icon" v-model="usaTime" :showIcon="true" dateFormat="dd/mm/yy" disabled />
        </div>
      </div>
    </div>
    <hr>
    <!-- table section order -->
    <div class="row px-3">
      <div class="card col-6" v-if="selectedAction">
        <DataTable :value="product_info" editMode="cell" class="editable-cells-table order">
          <template #header>{{ headerTitle }}</template>
          <Column field="sku" header="Item ID"></Column>
          <Column field="sku_name1" header="Item Name"></Column>
          <Column field="quantity" header="Qty"></Column>
          <Column field="qty_shipped_empty" header="Qty for Return" v-if="showQTYReturn">
            <template #editor="slotProps">
              <InputNumber v-model="slotProps.data[slotProps.column.field]" @input="onCellEdit($event, slotProps, table = `qty`)"/>
            </template>
          </Column>
          <template #empty>No product</template>
        </DataTable>
      </div>
      <!-- table section release -->
      <div class="card col-6" v-if="showTableRelease">
        <DataTable :value="product_info_release" editMode="cell" class="editable-cells-table pfr">
          <template #header>
            <div class="table-header">
              <i class="pi pi-plus" id="addRelease" @click="openModal"></i>
              <span class="m-auto">Products for Release</span>
            </div>
          </template>
          <Column header="#" class="w-25">
            <template #body="slotProps">
              <i class="pi pi-ban" @click="deleteAddedProduct(event = 'release', slotProps)"></i>
            </template>
          </Column>
          <Column field="release_sku_id" header="Item ID"></Column>
          <Column field="release_sku_name" header="Item Name"></Column>
          <Column field="release_sku_quantity" header="Qty for Release" v-if="showTableRelease">
            <template #editor="slotProps">
              <InputNumber v-model="slotProps.data[slotProps.column.field]" @input="onCellEdit($event, slotProps, table = `release`)"/>
            </template>
          </Column>
          <template #empty>No product</template>
        </DataTable>
      </div>
      <!-- table section wrong -->
      <div class="card col-6" v-if="wrong">
        <DataTable :value="product_info_wrong" editMode="cell" class="editable-cells-table pfr">
          <template #header>
            <div class="table-header">
              <i class="pi pi-plus" id="addWrong" @click="openModal"></i>
              <span class="m-auto">{{ wrongHeader }}</span>
            </div>
          </template>
          <Column header="#" class="w-25">
            <template #body="slotProps">
              <i class="pi pi-ban" @click="deleteAddedProduct(event = 'wrong', slotProps)"></i>
            </template>
          </Column>
          <Column field="wrong_sku_id" header="Item ID"></Column>
          <Column field="wrong_sku_name" header="Item Name"></Column>
          <Column field="wrong_sku_quantity" header="Qty">
            <template #editor="slotProps">
                <InputNumber v-model="slotProps.data[slotProps.column.field]" @input="onCellEdit($event, slotProps, table = `wrong`)"/>
            </template>
          </Column>
          <template #empty>No product</template>
        </DataTable>
      </div>
      <!-- table section LOST PRODUCT -->
      <div class="card col-6" v-if="lost">
        <DataTable :value="product_info_lost" editMode="cell" class="editable-cells-table pfr">
          <template #header>
            <div class="table-header">
              <i class="pi pi-plus" id="addLost" @click="openModal"></i>
              <span class="m-auto">Lost Products</span>
            </div>
          </template>
          <Column header="#" class="w-25">
            <template #body="slotProps">
              <i class="pi pi-ban" @click="deleteAddedProduct(event = 'lost', slotProps)"></i>
            </template>
          </Column>
          <Column field="lost_sku_id" header="Item ID"></Column>
          <Column field="lost_sku_name" header="Item Name"></Column>
          <Column field="lost_sku_quantity" header="Qty">
            <template #editor="slotProps">
                <InputNumber v-model="slotProps.data[slotProps.column.field]" @input="onCellEdit($event, slotProps, table = `lost`)"/>
            </template>
          </Column>
          <template #empty>No product</template>
        </DataTable>
      </div>
    </div>
    <!-- payment releated section  -->
    <div class="col-3 pr-3 mt-3" v-if="explanationShow">
      <p>Explanation</p>
      <InputText class="col-sm-12" v-model="explanation"/>
    </div>
    <!-- credit card section -->
    <div class="my-4" v-if="paymentRelatedShow">
      <div class="col-12 pl-0">
        <h6 class="col-12">Credit Card Details:</h6>
        <div class="col-3 d-inline-block">
          <p>Credit Card Number</p>
          <InputMask class="col-12" mask="9999-9999-9999-9999" v-model="creditCardNumber" placeholder="****-****-****-****" />
        </div>
        <div class="col-3 d-inline-block">
          <p>Cardholder Name</p>
          <InputText class="col-12" v-model="cardName" />
        </div>
        <div class="col-3 d-inline-block">
          <p>Expiry Date</p>
          <Calendar v-model="expiry_date" :showIcon="true" :manualInput="false" dateFormat="yy-mm-dd" @date-select="formatCalendarDate($event, calendar = 'expiry_date')"/>
        </div>
      </div>
    </div>
    <!-- transaction section  -->
    <div class="my-4" v-if="paymentRelatedShow">
      <div class="col-12 pl-0">
        <h6 class="col-12">Transaction Details:</h6>
        <div class="col-3 d-inline-block">
          <p>Transaction Amount</p>
          <InputText class="col-12" v-model="transaction_amount" />
        </div>
        <div class="col-3 d-inline-block">
          <p>Transaction Date</p>
          <Calendar v-model="transaction_date" :showIcon="true" dateFormat="yy-mm-dd" :maxDate="maxDateValue" :manualInput="false" @date-select="formatCalendarDate($event, calendar = 'transaction_date')"/>
        </div>
      </div>
    </div>
    <!-- csr lead section only show in adverse event and defective pr. -->
    <div v-if="adverse_event" class="mt-3 hr row col-12">
      <div class="col-3 d-inline-block">
        <p>Batch or Lot Number:</p>
        <InputText class="col-sm-12" v-model="batch_lot_number"/>
      </div>
      <div class="col-3 d-inline-block">
        <p>Date Received</p>
        <Calendar class="pr-0 mb-2" id="received_date" v-model="received_date" :manualInput="false" :showIcon="true" dateFormat="yy-mm-dd" @date-select="formatCalendarDate($event, calendar = 'received_date')" />
      </div>
      <div class="col-3 d-inline-block">
        <p>Storage Conditions</p>
        <Textarea class="col-sm-12" v-model="storage_conditions" :autoResize="true" rows="3" cols="30"/>
        <small class="text-danger d-block position-absolute" v-if="storage_conditions.length > 100">Character limit exceeded!</small>
      </div>
    </div>
    <!-- payment e-mail  -->
    <div class="col-sm-12 mt-4 hr" v-if="showPayment">
      <label for="payment_email" class="d-block">Email for Payment</label>
      <InputText class="col-sm-3 mb-2 custom-width" id="payment_email" v-model="payment_email" placeholder="payment@email.com" @change="emailChecker"/>
      <small class="row col-sm-12 text-danger d-block" v-if="error">E mail address is not correct!</small>
    </div>
    <!-- reason for cancelation/exchange  -->
    <div class="hr" v-if="reasonSection">
      <h6 class="col-sm-12">{{ reasonTitle }}</h6>
      <div class="col-sm-4">
        <Dropdown class="col-12 mb-2" v-model="reasonName" :options="reasons" optionLabel="reason_value" placeholder="Select a Reason" @change="onResonsChange"/>
      </div>
      <!-- other reason for cancelation/exchange  -->
      <div class="col-sm-4" v-if="otherReasonInput">
        <Textarea 
          class="col-12" 
          type="text" 
          v-model="otherReason" 
          placeholder="Reason is..."
          :autoResize="true" 
          rows="5" 
          cols="30" />
      </div>
    </div>
    <!-- shipping section  -->
    <div class="shipping-box my-4 hr" v-if="shipping">
      <h6 class="col-sm-12">Shipping Address:</h6>
      <div class="col-sm-3 d-inline-block mb-2">
        <p>Country/Region:</p>
        <InputText class="col-sm-12" v-model="current_country" />
      </div>
      <div class="col-sm-3 d-inline-block">
        <p>Street Address:</p>
        <InputText class="col-sm-12" v-model="current_address" />
      </div>
      <div class="col-sm-2 d-inline-block">
        <p>City:</p>
        <InputText class="col-sm-12" v-model="current_city" />
      </div>
      <div class="col-sm-2 d-inline-block">
        <p>State/Province:</p>
        <InputText class="col-sm-12" v-model="current_state" />
      </div>
      <div class="col-sm-2 d-inline-block">
        <p>Zip/Postal Code:</p>
        <InputText class="col-sm-12" v-model="current_zip" />
      </div>
    </div>
    <!-- new shipping section  -->
    <div class="shipping-box my-4 hr" v-if="newShipping">
      <h6 class="col-sm-12">New Shipping Address:</h6>
      <div class="col-sm-3 d-inline-block mb-2">
        <p>Country/Region:</p>
        <InputText class="col-sm-12" v-model="new_country" />
      </div>
      <div class="col-sm-3 d-inline-block">
        <p>Street Address:</p>
        <InputText class="col-sm-12" v-model="new_address" />
      </div>
      <div class="col-sm-2 d-inline-block">
        <p>City:</p>
        <InputText class="col-sm-12" v-model="new_city" />
      </div>
      <div class="col-sm-2 d-inline-block">
        <p>State/Province:</p>
        <InputText class="col-sm-12" v-model="new_state" />
      </div>
      <div class="col-sm-2 d-inline-block">
        <p>Zip/Postal Code:</p>
        <InputText class="col-sm-12" v-model="new_zip" />
      </div>
    </div>
    <!-- customer redirect  -->
    <div class="hr" v-if="this.action_name === 13">
      <h6 class="col-sm-12">Customer's Information</h6>
      <div class="col-sm-2 d-inline-block align-bottom mb-2">
        <label for="phone" class="d-block">Phone</label>
        <InputMask class="col-sm-12" mask="(999) 9999-999" v-model="phone" placeholder="(999) 9999-999" @change="checkPhone"/>
      </div>
      <div class="col-sm-2 d-inline-block align-bottom mb-2">
        <label for="e_mail" class="d-block">E-mail</label>
        <InputText class="col-sm-12" id="e_mail" v-model="e_mail" placeholder="max@max.com" @change="emailChecker"/>
        <small class="text-danger d-block position-absolute" v-if="error">E mail address is not correct!</small>
      </div>
    </div>

    <!-- return item option  -->
    <div class="hr" v-if="showReturnItem">
      <div class="col-sm-2 d-inline-block mb-2">
        <h6>Return Item Options:</h6>
        <SelectButton v-model="rio" :options="rio_options" @input="returnItemChanged"/>
      </div>
      <div class="col-sm-2 d-inline-block align-bottom mb-2" v-if="e_label">
        <label for="e_mail" class="d-block">E-mail</label>
        <InputText class="col-sm-12" id="e_mail" v-model="e_mail" placeholder="max@max.com" @change="emailChecker"/>
        <small class="text-danger d-block position-absolute" v-if="error">E mail address is not correct!</small>
      </div>
      <div class="col-sm-2 d-inline-block align-bottom mb-2" v-if="e_label">
        <label for="phone" class="d-block">Phone</label>
        <InputMask class="col-sm-12" mask="(999) 9999-999" v-model="phone" placeholder="(999) 9999-999" @change="checkPhone"/>
      </div>
    </div>
    <!-- pickUp section  -->
    <div class="mt-3" v-if="pickUP">
      <h6 class="col-sm-12">Picking up Address:
        <Button icon="pi pi-copy" class="p-button" @click="copyAddress"/>
      </h6>
      <div class="col-sm-3 mb-3 d-inline-block">
        <p>Country/Region</p>
        <InputText class="col-sm-12" type="text" v-model="PUcountry" />
      </div>
      <div class="col-sm-3 d-inline-block">
        <p>Street Address</p>
        <InputText class="col-sm-12" type="text" v-model="PUaddress" />
      </div>
      <div class="col-sm-2 d-inline-block">
        <p>City</p>
        <InputText class="col-sm-12" type="text" v-model="PUcity" />
      </div>
      <div class="col-sm-2 d-inline-block">
        <p>State/Province</p>
        <InputText class="col-sm-12" type="text" v-model="PUstate" />
      </div>
      <div class="col-sm-2 d-inline-block">
        <p>Zip/Postal Code</p>
        <InputText class="col-sm-12" type="text" v-model="PUzip" />
      </div>
    </div>
    <!-- last section status/submit -->
    <div class="status mt-3 hr" v-if="selectedAction">
      <div class="col-sm-3">
        <h6>Status</h6>
        <Dropdown class="col-12" v-model="selectedStatus" :options="statusDropdown" optionLabel="status_value" placeholder="Open" @change="onStatusChange" />
      </div>
    </div>
    <div class="row">
      <div class="col-12 my-2 pr-4">
        <Button class="float-right" label="Submit" @click="submitForm" :disabled="!this.itsAllOK || this.error"/>
      </div>
    </div>
    <!-- POP UP -->
    <Dialog :visible.sync="productDialog" :style="{width: '900px'}" :modal="true" class="p-fluid popUp">
      <DataTable
        :value="werehouse"
        :scrollable="true"
        scrollHeight="400px"
        :loading="loading"
        dataKey="id"
        class="werehouse"
        :filters="filters">
        <template #header>
          <div class="table-header">
            <span class="p-input-icon-right">
              <i class="pi pi-search" />
              <InputText v-model="filters['global']" placeholder="Global Search" />
            </span>
          </div>
        </template>
        <Column field="item_code" header="Item ID"></Column>
        <Column field="item_name_1" header="Item Name"></Column>
        <Column>
          <template #body="slotProps">
            <Button icon="pi pi-plus" class="center" @click="addProduct(slotProps.data)" />
          </template>
        </Column>
      </DataTable>
    </Dialog>
  </div>
</template>

<script>
import axios from "axios";
// import methods from mixins
import onActionRender from '../../store/onActionRender.js'
import mainMethods from '../../store/mainMethods.js'
// component
import backBtn from '../html/back_button.vue'

export default {
  mixins: [onActionRender, mainMethods],
  components: {
    backBtn
  },
  data() {
    return {
      axios_data: [],
      table_data: [],
      editingCellRows: {},
      exc_type: [],
      exc_action: [],
      reasons: [],
      reason: null,
      otherReason: "",
      otherReasonInput: false,
      reasonTitle: "",
      selectedType: null,
      selectedAction: null,
      loading: true,
      type_id: null,
      cMenu: {},
      new_comment_info:[],
      new_comment_info_table: [],
      action_name: null,
      files: [],
      product_info_release: [],
      value: null,
      date_created: null,
      order_date: null,
      order_number: null,
      dist_id: null,
      dist_name_1: null,
      country: null,
      comment_body: null,
      copyText: null,
      headerTitle: "Order Details",
      wrongHeader: '',
      out_of_stock_skus: '',
      storage_conditions: '',
      lost: false,
      action: null,
      showQTYReturn: true,
      showTableRelease: false,
      reasonSection: false,
      qty_shipped_empty: null,
      exp_ic: null,
      user: null,
      PROD_INFO: [],
      comm: false,
      // context menu
      menuModel: [
        {
          label: 'Copy',
          icon: 'pi pi-fw pi-copy',
          command: () => this.copy(this.table_data)
        }
      ],
      
      uploads: [],
      werehouse: [],
      productDialog: false,
      product_info: [],
      filters: {},
      submitted: false,
      itsAllOK: false,
      // shipping box
      current_country: null,
      current_address: null,
      current_city: null,
      current_state: null,
      current_zip: null,
      shipping: false,
      newShipping: false,
      new_country: '',
      new_address: '',
      new_city: '',
      new_state: '',
      new_zip: '',
      // return item option section
      showReturnItem: false,
      e_label: false,
      phone: null,
      e_mail: null,
      PUcountry: null,
      PUaddress: null,
      PUcity: null,
      PUstate: null,
      PUzip: null,
      return_item_option: null,
      rio: null,
      pickUP: false,
      rio_options: ['E-Label', '3 Pickup'],
      changeType: false,
      // reason
      reason_cancellation: [],
      reason_cancellations: [],
      other_reason_cancellation: [],
      // errors
      wrong: false,
      error: false,
      product_info_wrong: [],
      product_info_lost: [],
      payment_email: null,
      showPayment: false,
      openModalID: null,
      reason_cancellation: [],
      reasonName: null,
      selectedStatus: null,
      statusDropdown: [],
      product_compliance: [],
      status_id: 1,
      usaTime: null,
      department: null,
      role: null,
      batch_lot_number: null,
      received_date:null,
      adverse_event: false,
      explanationShow: false,
      miscellaneousRefund: [],
      customerJSON: [],
      explanation: '',
      paymentRelatedShow: false,
      creditCardNumber: '',
      cardName: '',
      expiry_date: null,
      transaction_amount: null,
      transaction_date: null,
      maxDateValue: null
    };
  },
  mounted() {
    this.getUserName()
    this.getException()
    this.addQTYEmpty()
    this.setMaxDate()
    this.getData()
  },
  methods: {
    getData() {
      axios.get(`api/v1/order/view/${this.$route.params.id}`).then(res => {
        this.axios_data = res.data[0]
        this.table_data = res.data
        // DATA hold all response date
        const DATA = this.axios_data
        this.cMenu = DATA;
        this.product_info = DATA.product_information;
        this.date_created = DATA.record_date;
        this.e_mail = DATA.email
        this.phone = DATA.phone
        this.payment_email = DATA.email
        this.current_country = DATA.country
        this.current_address = DATA.address1
        this.current_city = DATA.city
        this.current_state = DATA.state
        this.current_zip = DATA.zipcode
        this.loading = false;
        this.getUSADate()
        this.getStatusAll(DATA)
        // get item from warehouse
        this.getWerehouse(DATA.warehouse_number)
      })
    },
    // set canada date
    getUSADate() {
      const current_date = new Date().toISOString().slice(0,10)
      this.usaTime = current_date.toLocaleString("en-US", {timeZone: "America/Vancouver"});
    },
    // format calendar date do USA
    formatCalendarDate(event, id) {
      const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(event);
      const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(event);
      const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(event);
      switch (id) {
        case "transaction_date":
          this.transaction_date = `${ye}-${mo}-${da}`;
        break;
        case "received_date":
          this.received_date = `${ye}-${mo}-${da}`;
        break;
        case "expiry_date":
          this.expiry_date = `${ye}-${mo}-${da}`;
        break;
      }
    },
    // get all status option
    getStatusAll() {
      axios.get(`api/v1/local/exceptions/status/all`).then(res => {
        res.data.map(val => {
          let statusAll = {
            "id": val.id,
            "status_value": val.status_value
            };
          this.statusDropdown.push(statusAll);
        });
        // remove reassigned and closed status on ticket created
        this.statusDropdown.splice(3, 2);
      });
    },
    // on status dropdown change
    // by defaut status_id = 1 / open
    onStatusChange(event) {
      this.status_id = event.value.id
    },
    // get all exception type from category_id = 4 + payment releated
    getException() {
      axios.get(`api/v1/exception/type/view/category_id = 4 OR exception_message = 'Payment Related'/''/''`).then(res => {
        res.data.map(val => {
          let dropdownData = {
            "id": val.id,
            "exception_type": val.exception_message
            };
          this.exc_type.push(dropdownData);
        });
      });
    },
    // get all product for spec werehouse by ID
    getWerehouse(wereHouse) {
      axios.get(`api/v1/inventory/conditions/warehouse/${wereHouse}`).then(res => {
        this.werehouse = res.data
      });
    },
    // add qty empty key for every product info
    // this added because need to check table before submiting
    addQTYEmpty() {
      this.product_info.map(function(el) {
        let o = Object.assign({}, el);
        o.qty_shipped_empty = null
        return o;
      })
    },
    openModal(event) {
      // set ID for open modal
      this.openModalID = event.target.id
      this.product = {};
      this.submitted = false;
      this.productDialog = true;
      // clear search input
      this.filters = {};
    },
    // add product from werehouse to spec. table
    addProduct(params) {
      switch (this.openModalID) {
        case 'addRelease':
          this.product_info_release.push({
            "release_sku_id": params.item_code,
            "release_sku_name": params.item_name_1,
            "release_sku_quantity": null
          })
          // disabled sumbit button before add qty
          this.itsAllOK = false
          // close modal after product added
          this.productDialog = false;
        break;
        case 'addWrong':
          this.product_info_wrong.push({
            "wrong_sku_id": params.item_code,
            "wrong_sku_name": params.item_name_1,
            "wrong_sku_quantity": null
          })
          // disabled sumbit button before add qty
          this.itsAllOK = false
          // close modal after product added
          this.productDialog = false;
        break;
        case 'addLost':
          this.product_info_lost.push({
            "lost_sku_id": params.item_code,
            "lost_sku_name": params.item_name_1,
            "lost_sku_quantity": null
          })
          // disabled sumbit button before add qty
          this.itsAllOK = false
          // close modal after product added
          this.productDialog = false;
        break;
      }
    },
    /**
     * delete added product from table
     * table - release or wrong
     * after delete check if table empty and show msg if something wrong
    */
    deleteAddedProduct(table, props) {
      switch (table) {
        case 'release':
          this.product_info_release.splice(props.index, 1)
          this.product_info_release.every(e =>
            e.release_sku_quantity !== undefined &&
            e.release_sku_quantity !== 0 &&
            e.release_sku_quantity !== null
          ) ? this.itsAllOK = true : this.itsAllOK = false
          this.product_info_release.length === 0 ? this.itsAllOK = false : 0
        break;
        case 'wrong':
          this.product_info_wrong.splice(props.index, 1)
          this.product_info_wrong.every(e =>
            e.wrong_sku_quantity !== undefined &&
            e.wrong_sku_quantity !== null &&
            e.wrong_sku_quantity !== 0
          ) ? this.itsAllOK = true : this.itsAllOK = false
          this.product_info_wrong.length == 0 ? this.itsAllOK = false : 0
        break;
        case 'lost':
          this.product_info_lost.splice(props.index, 1)
          this.product_info_lost.every(e =>
            e.lost_sku_quantity !== undefined &&
            e.lost_sku_quantity !== null &&
            e.lost_sku_quantity !== 0
          ) ? this.itsAllOK = true : this.itsAllOK = false
          this.product_info_lost.length == 0 ? this.itsAllOK = false : 0
        break;
      }
    },
    /**
     * Exc type ORDER CANCELLATION
     * reasons change triger show/hide addition elements
    */
    onResonsChange(event) {
      this.itsAllOK = true
      this.reason = event.value.id
      const REASON = event.value.id
      switch (REASON) {
        case 2: case 4: case 6:
          this.showReturnItem = true
        break;
        case 8:
          this.otherReasonInput = true
          this.showReturnItem = true
        break;
        default:
          this.showReturnItem = false
          this.e_label = false
          this.pickUP = false
          this.otherReasonInput = false
        break;
      }
      const TYPE = this.type_id
      // If product exchanged only show other reason
      if (TYPE === 18 && REASON === 8) {
        this.otherReasonInput = true
        this.showReturnItem = false
      }
    },
    /**
     * Exception type dropdown
    */
    onDropdownChange(event) {
      // if type change reload page
      if (this.changeType) (this.$router.go())
      this.type_id = event.value.id
      axios.get(`api/v1/local/exception/action/type/${this.type_id}`).then(res => {
        res.data.map(val => {
          let dropdownData = {
            "id": val.id,
            "action_name": val.action_name
            };
          this.exc_action.push(dropdownData);
          // type choosen
          this.changeType = true
        });
      })
    },
    /**
     * Return item option
     * render element base on selected options
    */
    returnItemChanged(selected) {
      switch (selected) {
        case "E-Label":
          this.pickUP = false
          this.e_label = true
          this.return_item_option = true
        break;
        case "3 Pickup":
          this.pickUP = true
          this.return_item_option = false
          this.e_label = true
        break;
      }
    },
    /**
     * Add new value after cell edit base on table
    */
    onCellEdit(newValue, props, table) {
      switch (table) {
        case 'qty':
          this.editingCellRows[props.index] = {...props.data};
          if (newValue > this.editingCellRows[props.index].quantity) {
            this.product_info[props.index].qty_shipped_empty = this.editingCellRows[props.index].quantity
          }
          this.product_info.every(e => e.qty_shipped_empty !== 0) ? this.itsAllOK = true : this.itsAllOK = false
        break;
        case 'release':
          this.product_info_release.every(e =>
            e.release_sku_quantity !== undefined &&
            e.release_sku_quantity !== 0 &&
            e.release_sku_quantity !== null) ? this.itsAllOK = true : this.itsAllOK = false

          this.product_info_release[props.index].release_sku_quantity = newValue
        break;
        case 'wrong':
          this.product_info_wrong.every(e =>
            e.wrong_sku_quantity !== undefined &&
            e.wrong_sku_quantity !== 0 &&
            e.wrong_sku_quantity !== null) ? this.itsAllOK = true : this.itsAllOK = false

            this.product_info_wrong[props.index].wrong_sku_quantity = newValue
        break;
        case 'lost':
          this.product_info_lost.every(e =>
            e.lost_sku_quantity !== undefined &&
            e.lost_sku_quantity !== 0 &&
            e.lost_sku_quantity !== null) ? this.itsAllOK = true : this.itsAllOK = false

            this.product_info_lost[props.index].lost_sku_quantity = newValue
        break;
      }
    },
    /**
     * Prepare JSON data for send to back
    */
    prepareData() {
      this.product_info.map(val => {
        let new_prod_info_arr = {
            "sku_id": val.sku,
            "sku_name": val.sku_name1,
            "sku_quantity": val.quantity,
            "sku_quantity_return": val.qty_shipped_empty
          };
        this.PROD_INFO.push(new_prod_info_arr);
      })

      Array.prototype.push.apply(this.PROD_INFO,this.product_info_release);
      Array.prototype.push.apply(this.PROD_INFO,this.product_info_wrong);
      Array.prototype.push.apply(this.PROD_INFO,this.product_info_lost);
      // if exc type order cancellation or product exchange
      if (this.type_id === 17 || this.type_id === 18) {
        this.reason_cancellation.push({
          "reason_id": this.reason,
          "value_other": this.otherReason
        })
      }
    },
    /**
     * if adverse event/defective product choosen (type 19/20) + all action this ticket go to csrlead department
     * if payment releated choosen (type 23) + all action this ticket go to accounting department
     * 
    */
    ticketFlowOnCreate() {
      switch (this.type_id) {
        case 19: case 20:
          this.department = 'csrlead'
          break;
        case 23:
          this.department = 'accounting'
          break;
        default:
          this.department = 'logistics'
          break;
      }
      return this.department
    },
    // map ticket flow
    ticketMaps() {
      switch (this.status_id) {
        case 1:
          this.department = this.ticketFlowOnCreate()
        break;
        case 2: case 3:
          this.department = this.role
        break;
      }
      return this.department
    },
    prepareComplianceJSON() {
      let prod_com = {
        batch_lot_number: this.batch_lot_number,
        received_date: this.received_date
      }
      switch (this.type_id) {
        case 19: case 20:
          this.product_compliance.push(prod_com);
          break;
        default:
          this.product_compliance = []
          break;
      }
    },
    prepareAccJSON() {
      let accJSON = {
        explanation: this.explanation,
        credit_card_number: this.creditCardNumber,
        cardholder_name: this.cardName,
        expiry_date: this.expiry_date,
        trx_amount: parseFloat(this.transaction_amount),
        trx_date: this.transaction_date
      };
      this.miscellaneousRefund.push(accJSON);

      return this.miscellaneousRefund
    },
    prepareCustomerRedirectJSON () {
      let customerJSON = {
        redirect_country: this.new_country,
        redirect_street_address: this.new_address,
        redirect_city: this.new_city,
        redirect_state: this.new_state,
        redirect_zip_code: this.new_zip
      };
      this.customerJSON.push(customerJSON);

      return this.customerJSON
    },
    prepareJSON(obj, department, accExplanation, customer) {
      const datas = [
        {
          order_number: this.axios_data.order_number,
          order_date: this.axios_data.record_date,
          dist_id: this.axios_data.dist_id,
          dist_name_1: this.axios_data.dist_name_1,
          dist_name_2: this.axios_data.dist_name_2,
          iso2: this.axios_data.iso2,
          iso3: this.axios_data.iso3,
          country: this.axios_data.country,
          exception_type: this.type_id,
          action_id: this.action_name,
          status: this.status_id,
          created_by: this.user,
          date_created: this.usaTime,
          em_uploads: obj,
          em_comments: this.new_comment_info,
          product_info: this.PROD_INFO,
          return_label_info: [],
          warehouse_number: this.axios_data.warehouse_number,
          return_item_option: this.return_item_option,
          phone_number: this.phone,
          pickup_country: this.PUcountry,
          pickup_street_address: this.PUaddress,
          pickup_city: this.PUcity,
          pickup_state: this.PUstate,
          pickup_zip_code: this.PUzip,
          email: this.e_mail,
          payment_email: this.payment_email,
          country: this.current_country,
          street_address: this.current_address,
          city: this.current_city,
          state: this.current_state,
          zip_code: this.current_zip,
          exception_corrections: [],
          customer_reship: [],
          reason_for_cancellation: this.reason_cancellation,
          department: department,
          product_compliance: this.product_compliance,
          accounting: accExplanation,
          returned_from: '',
          customer_redirect: customer,
          out_of_stock_skus: this.out_of_stock_skus,
          storage_conditions: this.storage_conditions
        }
      ];
      return datas
    },
    myUploader(event){
      document.body.style.cursor = 'wait'
      let uploadedFiles = 0
      for (let i = 0; i < event.files.length; i++) {
        let element = event.files[i]
        let files = element
        let data = new FormData();
        data.append('files', files);
        this.imageUpload(data).then((res) => {
            if(res.code === 200){
                let tmp = {
                      "path": res.path[0]
                    }
                this.uploads.push(tmp)
                uploadedFiles++
            }
            if (event.files.length === uploadedFiles) {
              this.submiting(this.uploads)
            }
        })
      }      
    },
    tableChecker() {
      // check wrong table
      if ((this.wrong && this.product_info_wrong.some(e => e.wrong_sku_quantity === null)) ||
          (this.wrong && this.product_info_wrong.every(e => e.wrong_sku_quantity === null))) {
            this.showToast(`Something went wrong!`, `${this.wrongHeader} must be added!`, 'warning', 2500)
            return false
      }
      // check wrong table
      if ((this.lost && this.product_info_lost.some(e => e.lost_sku_quantity === null)) ||
          (this.lost && this.product_info_lost.every(e => e.lost_sku_quantity === null))) {
            this.showToast(`Something went wrong!`, `Lost Product must be added!`, 'warning', 2500)
            return false
      }
      // check order product for return
      if (this.showQTYReturn && this.product_info.every(e => e.qty_shipped_empty == null)) {
        this.showToast(`Something went wrong!`, `${this.headerTitle} can't be empty!`, 'warning', 2500)
        return false
      }
      // check release table
      if (
        this.showTableRelease &&
        this.product_info_release.some(e => e.release_sku_quantity === null || e.release_sku_quantity === 0) ||
        this.showTableRelease &&
        this.product_info_release.every(e => e.release_sku_quantity === null || e.release_sku_quantity === 0)
        ) {
          this.showToast(`Something went wrong!`, `Relese Product can't be empty or 0!`, 'warning', 2500)
          return false
      }
    },
    submiting(obj) {
      document.body.style.cursor = 'wait'
      // check if table correct populated
      const TABLE_CHECKER = this.tableChecker()
      if (TABLE_CHECKER === false) return
      
      // get json
      this.prepareData()

      // get department
      const department = this.ticketMaps()

      // populate compilance table
      this.prepareComplianceJSON()

      // get and set all data for submit
      const accExplanation = this.prepareAccJSON()

      const customer = this.prepareCustomerRedirectJSON()
      
      // prepare main JSON
      const DATAS = this.prepareJSON(obj, department, accExplanation, customer)
      // Insert this exception
      axios.post(`api/v1/local/exception/insert`, DATAS).then(res => {
        const RESPONSE = res.data[0]
        switch (RESPONSE.message) {
          // if exception exist for this order number and type show msg
          case `exist`:
            const RESPONSE_RESULT = res.data[0].result[0]
            const CREATE = this.$createElement
            const vNodesMsg = CREATE(
              'div',
              { class: ['text-center', 'mb-0'] },
              [
                CREATE('p', { class: 'd-block' }, `Created by - ${RESPONSE_RESULT.created_by}`),
                CREATE('p', { class: 'd-block' }, `Date Created - ${RESPONSE_RESULT.date_created}`),
                CREATE('p', { class: 'd-block' }, `Status: ${RESPONSE_RESULT.status}`)
              ],
            )
            this.showToast("Exception already exists", [vNodesMsg], 'danger', 10000)
            document.body.style.cursor = 'default'
          break;
          case `Exception created successfully`:
            this.showToast('Exception Created', RESPONSE.message, 'success', 1000)
            document.body.style.cursor = 'default'
            this.back()
          break;
          default:
            this.showToast(`Something went wrong!`, RESPONSE.message, 'danger', 10000)
            document.body.style.cursor = 'default'
          break;
        }
      })
    }
  }
}
</script>

<style lang="scss">
.table-header {
    display: flex;
    align-items: center;
    & i {
      margin-right: 15px;
    }
}
.v-middle {
  vertical-align: middle;
  & button.p-button {
    height: 35px !important;
  }
}
.p-input-icon-right>i {
  right: 0 !important;
}
.popUp .p-dialog-header .p-dialog-header-icons {
  margin-left: 100% !important;
}
.custom-width {
  width: 23.5%;
}
</style>
