<template>
    <div>
        <div class="p-grid">
            <div class="p-col-12 px-0">
                <DataTable
                    :value="ticket"
                    class="p-datatable-responsive"
                    :loading="loading"
                    :paginator="true"
                    :rows="25"
                    :filters="filters"
                    :autoLayout="true"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" 
                    :rowsPerPageOptions="[25,50,75,100]"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" >
                    <template #header>
                        <span>Cognition List</span>
                        <div class="table-header-custom">
                            <span class="p-input-icon-right">
                                <i class="pi pi-search mr-1" />
                                <InputText v-model="filters['global']" placeholder="Global Search" />
                            </span>
                        </div>
                    </template>
                    <Column field="id" header="Exception ID" sortable></Column>
                    <Column field="exception_type_name.exception_type" header="Exception Type" sortable></Column>
                    <Column field="created_by" header="Created by" sortable></Column>
                    <Column field="order_number" header="Order Number" sortable></Column>
                    <Column field="dist_id" header="Distributor ID" sortable></Column>
                    <Column field="dist_name_1" header="Distributor’s Name" sortable></Column>
                    <Column field="country" header="Country" sortable></Column>
                    <Column field="date_created" header="Date Created" sortable></Column>
                    <Column headerStyle="width: 4em; text-align: center" bodyStyle="text-align: center">
                        <template #header>#</template>
                        <template #body="slotProps">
                            <router-link
                                :to="{ path: '/cognition-worklist/ticket/' + slotProps.data.id }" >
                                <Button 
                                    type="button" 
                                    icon="pi pi-pencil"
                                    class="center" />
                            </router-link>
                        </template>
                    </Column>
                    <template #empty>
                        No records found.
                    </template>
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import EventBus from "../../event-bus";

export default {
    data() {
        return {
            ticket: null,
            loading: false,
            status: 1,
            filters: {},
            id: 0,
            type: null
        };
    },
    mounted() {
        this.loading = true
        this.getData()
        // on tab changed received new id(status) from eventbus
        EventBus.$on('selectTab', (status) => {
            this.loading = true
            this.status = status
            this.global = null
            this.getData()
        })
    },
    methods: {
        getData() {
            axios.get(`/api/v1/nai-exceptions/read-by-status`, {
                params: {
                    page: 0,
                    size: 5000,
                    sort: 'desc',
                    status: this.status
                }
            }).then(res => {
                this.ticket = res.data.content
                this.loading = false
                this.dataArived()
            })
        },
        dataArived() {
            let pending = false
            EventBus.$emit('cognition_status_change', pending)
        }
    }
}
</script>

<style lang="scss">
    .table-header-custom {
        position: absolute;
        right: 10px;
        top: 5px;
    }
</style>
