<template>
    <div class="col-4 mb-3" v-if="showChargeAmount">
        <p>Charge Amount</p>
        <InputText class="col-12" v-model="charge_amount" disabled />
    </div>
</template>

<script>
export default {
    props: {
        propsData: {
            type: Object
        }
    },
    data() {
        return {
            charge_amount: this.propsData.charge_amount,
            showChargeAmount: false
        }
    },
    mounted() {
        this.render()
    },
    methods: {
        render() {
            switch (this.propsData.action_id) {
                case 2: case 3:
                    this.showChargeAmount = true
                break;
            }
            // for this two type this section show on action 4
            if (this.propsData.exception_type_id === 14 || this.propsData.exception_type_id === 18) {
                switch (this.propsData.action_id) {
                    case 4:
                        this.showChargeAmount = true
                    break;
                }
            }
        }
    },
}
</script>