<template>
  <div>
    <div class="p-grid">
      <backBtn />
    </div>
    <div class="p-grid">
      <div class="p-col-12">
        <div class="card card-w-title border-none">
          <p class="m-0 p-0 counter" v-if="!loading">Showing {{ totalRecords }} ticket for this order.</p>
          <DataTable
            :value="transaction"
            class="p-datatable-responsive"
            :lazy="true"
            :loading="loading">
            <template #header>Transaction View</template>
            <Column field="id" header="Exception ID"></Column>
            <Column field="exception_message" header="Exception Type"></Column>
            <Column field="status" header="Status"></Column>
            <Column field="department" header="Current Department"></Column>
            <Column field="order_number" header="Order Number"></Column>
            <Column field="order_date" header="Order date"></Column>
            <Column field="dist_id" header="Distributor ID"></Column>
            <Column field="dist_name_1" header="Distributor’s Name"></Column>
            <Column field="country" header="Country"></Column>
            <Column field="created_by" header="Created by"></Column>
            <Column field="date_created" header="Date Created"></Column>
            <Column headerStyle="width: 8em; text-align: center" bodyStyle="text-align: center">
              <template #header></template>
              <template #body="slotProps">
                <router-link
                  :to="{ path: '/dashboard/view/ticket/' + id, query:{exType: type} }"
                  :slotProps="slotProps" >
                  <Button type="button" icon="pi pi-search" @click="updateEx(slotProps.data.id, slotProps.data.exception_type_id)" @click.middle="updateEx(slotProps.data.id, slotProps.data.exception_type_id)" class="center"></Button>
                </router-link>
              </template>
            </Column>
            <template #empty>
              No records found.
            </template>
          </DataTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// component
import backBtn from '../html/back_button.vue'

export default {
  components: {
    backBtn
  },
  data() {
    return {
      transaction: null,
      loading: false,
      id: 0,
      type: 0,
      totalRecords: 0
    };
  },
  mounted() {
    this.loading = true;
    this.getData()
  },
  methods: {
    // get data for this order number
    getData() {
      axios.get(`api/v1/local/exception/order/view/${this.$route.params.id}`).then(res => {
        this.transaction = res.data;
        this.totalRecords = res.data.length
        this.loading = false;
      })
    },
    updateEx(id, type) {
      this.id = id
      this.type = type
    },
  }
};
</script>

<style lang="scss">
.border-none {
  border: none !important;
}
.center {
  justify-content: end !important;
  padding: 6px !important;
}
.counter {
  text-align: end;
  font-size: small;
  font-style: italic;
}
</style>
