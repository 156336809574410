
/**
 * Add main package for app
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import { BootstrapVue } from 'bootstrap-vue';
import VueHtml2Canvas from 'vue-html2canvas';
import PrimeVue from 'primevue/config';

import 'regenerator-runtime/runtime';

Vue.use(VueHtml2Canvas);
/**
 * Import PRIME VUE component
 */
import Button from "primevue/button";
import Breadcrumb from "primevue/breadcrumb";
import Calendar from "primevue/calendar";
import Checkbox from "primevue/checkbox";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import FileUpload from "primevue/fileupload";
import InputText from "primevue/inputtext";
import InputNumber from 'primevue/inputnumber';
import InputMask from 'primevue/inputmask';
import Menu from "primevue/menu";
import Menubar from "primevue/menubar";
import Paginator from "primevue/paginator";
import SelectButton from "primevue/selectbutton";
import Textarea from "primevue/textarea";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import ToggleButton from "primevue/togglebutton";
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import ContextMenu from 'primevue/contextmenu';
import Toolbar from 'primevue/toolbar';
import Tooltip from 'primevue/tooltip';
import ProgressSpinner from 'primevue/progressspinner';
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmDialog from 'primevue/confirmdialog';

Vue.use(BootstrapVue);
Vue.use(ConfirmationService);

import "primevue/resources/primevue.min.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "prismjs/themes/prism-coy.css";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.config.productionTip = false;
// Button animation
Vue.use(PrimeVue, {ripple: true});
/**
 * Use prime vue component
 */
Vue.component('ConfirmDialog', ConfirmDialog);
Vue.directive('Tooltip', Tooltip);
Vue.component('Accordion', Accordion);
Vue.component('AccordionTab', AccordionTab);
Vue.component("Button", Button);
Vue.component("Breadcrumb", Breadcrumb);
Vue.component("Calendar", Calendar);
Vue.component("Checkbox", Checkbox);
Vue.component("Column", Column);
Vue.component("DataTable", DataTable);
Vue.component("Dialog", Dialog);
Vue.component("Dropdown", Dropdown);
Vue.component("FileUpload", FileUpload);
Vue.component("InputText", InputText);
Vue.component("InputNumber", InputNumber);
Vue.component("InputMask", InputMask);
Vue.component("Menu", Menu);
Vue.component("Menubar", Menubar);
Vue.component("Paginator", Paginator);
Vue.component("SelectButton", SelectButton);
Vue.component("TabView", TabView);
Vue.component("TabPanel", TabPanel);
Vue.component("Textarea", Textarea);
Vue.component("ToggleButton", ToggleButton);
Vue.component('ContextMenu', ContextMenu);
Vue.component('Toolbar', Toolbar);
Vue.component('ProgressSpinner', ProgressSpinner);

/**
 * set base url for axios from env file
 */
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
/**
 * get token from cookie
 */
const getAuthToken = () => $cookies.get("token");
/**
 * get current page
 */
const loginPage = () => window.location.pathname;
/**
 * redirect to login page
 */
const backToLogin = () => window.location = "/login";
/**
 * Add a request interceptor
 */
axios.interceptors.request.use(function (config) {
  if (!getAuthToken() && loginPage() !== "/login") {
    backToLogin();
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});
/**
 * Add a 401 response interceptor for login
 * Show toast message if Username or Password is incorrect!
 */
axios.interceptors.response.use(function (response) {
  if (response.request.response.includes('"code" : 401')) {
    // backToLogin();
  } else {
    return response;
  }
}, function (error) {
  if (401 === error.response.status) {
    const vm = new Vue();
    vm.$bvToast.toast('Username or Password is incorrect!', {
      title: "Unsuccess",
      autoHideDelay: 5000,
      variant: 'danger',
    })
  } if (error.response.status === 400 && error.response.config.url === "api/v1/inquiries/create") {
    const vm = new Vue();
    vm.$bvToast.toast(error.response.data.message, {
      title: "Unsuccess",
      autoHideDelay: 5000,
      variant: 'danger',
    })
  } else {
    return Promise.reject(error);
  }
});
/**
 * Import main pages
 */
import Access from "./views/main_page/Access";
import Error from "./views/main_page/Error";
import Login from "./views/main_page/Login";
import NotFound from "./views/main_page/NotFound";
/**
 * Render page base on this.$route.path
 */
new Vue({
  el: "#app",
  computed: {
    ViewComponent() {
      switch (this.$route.path) {
        case "/login":
          return Login;
        case "/error":
          return Error;
        case "/access":
          return Access;
        case "/notfound":
          return NotFound;
        default:
          return App;
      }
    }
  },
  router,
  store,
  render(h) {
    return h(this.ViewComponent);
  }
});
