<template>
  <div class="route-bar">
    <div class="route-bar-breadcrumb">
      <ul>
        <li>
          <button class="p-link" @click="home">
            <i class="pi pi-home"></i>
          </button>
        </li>
        <template v-for="(path, i) of $route.path.split('/')">
          <li :key="i" v-if="$route.path !== '/' && path === ''">/</li>
          <li :key="i" v-else>{{ $route.path.split("/")[i] + " / " }}</li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    home() {
      window.location = "/";
    }
  }
};
</script>
