<template>
  <div>
    <spinnerHtml />
    <div v-if="!spinner">
      <div class="p-grid">
        <backBtn />
      </div>
      <ContextMenu :model="menuModel" ref="cm" />
      <div class="col-6 d-inline-block">
        <div class="card card-w-title border-none">
          <DataTable :value="p_info" class="p-datatable-responsive" :rows="20" contextMenu :contextMenuSelection.sync="cMenu" @row-contextmenu="onRowContextMenu">
            <template #header>Transaction Details</template>
            <Column field="order_number" v-model="order_number" header="Order Number"></Column>
            <Column field="order_date" v-model="order_date" header="Order Date"></Column>
            <Column field="dist_id" v-model="dist_id" header="Distributor ID"></Column>
            <Column field="dist_name_1" v-model="dist_name_1" header="Distributor’s Name"></Column>
            <Column field="country" v-model="country" header="Country"></Column>
          </DataTable>
          <!-- For UPS -->
          <DataTable :value="p_info" class="p-datatable-responsive mt-3" v-if="UPS">
            <template #header>Delivery information</template>
            <Column field="country" v-model="country" header="Country"></Column>
            <Column field="street_address" v-model="street_address" header="Street Address"></Column>
            <Column field="city" v-model="city" header="City"></Column>
            <Column field="state" v-model="state" header="State"></Column>
            <Column field="zip_code" v-model="zip_code" header="Zip Code"></Column>
          </DataTable>
        </div>
        <div class="card card-w-title border-none mt-3">
          <div>
            <h4>Exception Category</h4>
            <InputText class="p-field p-md-12 mb-3" type="text" v-model="exception_category" disabled />
          </div>
          <div>
            <h4>Exception Type</h4>
            <InputText class="p-field p-md-12 mb-3" type="text" v-model="exception_message" disabled />
          </div>
          <div v-if="edit">
            <h4 class="col-12 pl-0">Edit - {{ exc_name }}</h4>
            <!-- phone -->
            <InputMask class="col-sm-12 mb-3" mask="(999) 9999-999" v-model="editSystemExc" placeholder="(999) 9999-999" @change="checkPhone" v-if="phone"/>
            <!-- e-mail -->
            <InputText class="col-sm-12 mb-3" v-model="editSystemExc" placeholder="correct@email.com" @change="emailChecker" v-if="badEmail"/>
            <small class="row col-sm-12 text-danger d-block mb-3" v-if="error">E mail address is not correct!</small>
            <!-- free text -->
            <InputText class="col-sm-12 mb-3" type="text" v-model="editSystemExc" v-if="freeText" :disabled="delChecked" @input="validate($event)" />
            <!-- number field -->
            <InputNumber class="col-sm-12 px-0 mb-3" v-model="editSystemExc" mode="decimal" :useGrouping="false" v-if="zipField" />
          </div>
          <div v-if="hideDelete">
            <div class="p-field-checkbox mb-3">
                <Checkbox id="binary" v-model="deleteChecked" :binary="true" @input="deleteSKU"/>
                <span class="ml-1 align-middle">Delete</span>
            </div>
          </div>
          <div>
            <h4>Date Created</h4>
            <div class="p-field p-col-12 p-md-12 p-0">
              <Calendar id="icon" v-model="date_created" :showIcon="true" dateFormat="dd/mm/yy" disabled />
            </div>
          </div>
          <div>
            <h4 class="mt-3">Status</h4>
            <Dropdown class="col-12" v-model="selectedStatus" :options="statusDropdown" optionLabel="status_value" :placeholder="status_placeholder" @change="onStatusChange" />
            <small class="text-warning" v-if="this.status_id === 1">Status must be selected!</small>
          </div>
          <div>
            <div class="mt-3" v-if="reassigned">
              <h4>Assing To:</h4>
              <Dropdown class="col-12" v-model="assingToDepartment" :options="reassingMap" :placeholder="reassing" optionLabel="assing_value" @change="assingToChange"/>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6 d-inline-block align-top">
        <Accordion class="card card-w-title border-none p-field p-col-12 p-md-12 accordion-custom">
          <AccordionTab header="Add New Comment">
            <div class="p-datatable card card-w-title border-none p-field p-col-12 p-md-12 float-r" >
              <h4 class="p-datatable-header">Reason/Comment</h4>
              <Textarea id="txt" :value="value" :autoResize="true" rows="5" cols="30" class="w-inherit" />
            </div>
            <Button label="Save" class="m-2 float-right" @click="saveComment"/>
            <div class="card card-w-title border-none p-field p-col-12 p-md-12">
              <DataTable class="comment_table" :value="new_comment_info_table" selectionMode="single" sortField="row_id" :sortOrder="-1">
                <template #header>Reason/Comment Preview</template>
                <Column field="comment_date" sortable header="Date"></Column>
                <Column field="comment_user" sortable header="User"></Column>
                <Column field="comment_body" sortable header="Comment"></Column>
                <Column bodyStyle="text-align: center" >
                    <template #header></template>
                    <template #body="slotProps">
                      <Button type="button" icon="pi pi-trash" :slotProps="slotProps" @click="deleteComment(slotProps.data.row_id)" class="center"></Button>
                    </template>
                </Column>
                <template #empty>
                  No new comments
                </template>
              </DataTable>
            </div>
          </AccordionTab>
        </Accordion>
        <Accordion class="card card-w-title border-none p-field p-col-12 p-md-12 accordion-custom" v-if="comm">
          <AccordionTab header="Comment History">
              <DataTable class="comment_table" :value="comment_info" sortField="id" :sortOrder="-1">
                <Column field="comment_date" sortable header="Date"></Column>
                <Column field="comment_user" sortable header="User"></Column>
                <Column field="comment_body" sortable header="Comment"></Column>
              </DataTable>
          </AccordionTab>
        </Accordion>
        <Accordion class="card card-w-title border-none p-field p-col-12 p-md-12 accordion-custom" v-if="filesForDownLoad">
          <AccordionTab header="Uploaded Files">
              <ul class="p-0" v-for="dwn in dwn_link" :key="dwn.paths">
                <a :href="dwn.path">{{dwn.path.split("/")[5]}}</a>
              </ul>
          </AccordionTab>
        </Accordion>
        <div class="card card-w-title border-none p-field p-col-12 p-md-12">
          <FileUpload 
            name="files" 
            chooseLabel="Upload" 
            :multiple="true"
            :customUpload="true"
            @uploader="myUploader"
            accept=".csv, .xlx, .xlsx, .pdf, .doc, .txt, .jpg, .jpeg, .png, .docx, .PNG, .JPG, .JPEG" 
            :maxFileSize="10000000" >
            <template #empty>
              <p>Drag and drop files here to upload.</p>
            </template>
          </FileUpload>
        </div>
      </div>
      <div class="col-sm-12 mt-3">
        <Button class="float-right mr-2" label="Submit" @click="submitForm" :disabled="this.status_id === 1 || this.status_id === 5" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import methods from mixins
import mainMethods from '../../../store/mainMethods.js'
import getAndSet from '../../../store/getAndSet.js'
import onSubmitActions from '../../../store/onSubmitActions.js'
import spinnerJS from '../../../store/spinner.js'
import reassingMaping from '../../../store/reassingMaping.js'
import ticketFlow from '../../../store/ticketFlow.js'
// component
import spinnerHtml from '../../html/spinner.vue'
import backBtn from '../../html/back_button.vue'

export default {
  mixins: [mainMethods, onSubmitActions, getAndSet, spinnerJS, reassingMaping, ticketFlow],
  components: {
    spinnerHtml,
    backBtn
  },
  data() {
    return {
      department: null,
      role: null,
      assingTo: null,
      assingToDepartment: null,
      assing: [],
      reassigned: false,
      reassing: null,
      cMenu: {},
      p_info: [],
      comment_info: [],
      new_comment_info:[],
      new_comment_info_table: [],
      files: [],
      dwn_link: [],
      order_date: null,
      exception_category: null,
      value: null,
      sku_value: null,
      exception_message: null,
      date_created: null,
      order_number: null,
      dist_id: null,
      dist_name_1: null,
      country: null,
      street_address: "",
      city: "",
      state: "",
      zip_code: "",
      comment_body: null,
      copyText: null,
      ID_exc: null,
      path: null,
      user: null,
      usaTime: null,
      
      status_id: null,
      comm: false,
      filesForDownLoad: false,
      status_placeholder: null,
      // exception corrections
      exc_name: null,
      editSystemExc: null,
      phone: false,
      freeText: false,
      error: false,
      badEmail: false,
      zipField: false,
      edit: true,
      userID: null,
      usaTimeDate: null,
      correction_status: false,
      USA_DATE_TIME: null,
      getCorrection: [],
      valueEnter: null,
      uploads: [],
      statusDropdown: [],
      selectedStatus: null,
      deleteChecked: null,
      hideDelete: false,
      delChecked: false,
      deleteCheckbox: 0,
      // exception corrections end
      UPS: false,
      spinner: true,
      returned_from: '',
      axios_data: [],
      menuModel: [
        {
          label: 'Copy', 
          icon: 'pi pi-fw pi-copy', 
          command: () => this.copy(this.p_info)
        }
      ]
    };
  },
  mounted() {
    this.getUserName()
    this.getComments()
    this.getCorrections()
    this.getDataOnLoad()
  },
  methods: {
    getDataOnLoad() {
      axios.get(`api/v1/local/exception/${this.$route.params.id}`).then(res => {
        this.cMenu = res.data[0]
        this.p_info = res.data
        this.axios_data = res.data[0]
        const DATA = this.axios_data
        this.ID_exc = DATA.id
        this.status_id = DATA.status_id
        this.status_placeholder = DATA.status
        this.exception_category = DATA.exception_category
        this.exception_message = DATA.exception_message
        this.date_created = DATA.date_created
        this.street_address = DATA.street_address
        this.city = DATA.city
        this.state = DATA.state
        this.zip_code = DATA.zip_code
        // Show Addition information
        switch (DATA.exception_type_id) {
          case 2: case 108:
            this.exception_message = `${DATA.exception_message} - ${DATA.email}`
          break;
          case 3: case 72:
            if (DATA.sku_value.length === 1) {
              this.exception_message = `${DATA.exception_message} - ${DATA.sku_value}`
              this.hideDelete = true
            } else if (DATA.sku_value.length > 1) {
              this.exception_message = `${DATA.exception_message} - ${DATA.sku_value}`
              this.edit = false
            }
          break;
          case 4: case 105: case 106:
            this.exception_message = `${DATA.exception_message} - ${DATA.zip_code}`
          break;
          case 10:
            this.UPS = true
          break;
          case 11:
            this.exception_message = `${DATA.exception_message} - ${DATA.out_of_stock_skus}`
          break;
          case 25: case 98:
            this.exception_message = `${DATA.exception_message} - ${DATA.street_address_2}`
          case 26: case 97: case 95:
            this.exception_message = `${DATA.exception_message} - ${DATA.street_address}`
          break;
        }
        // if correction value enter show this value
        if (DATA.exception_corrections.length > 0) (this.editSystemExc = DATA.exception_corrections[0].correction_value)
        // if status closed corection is ended
        if (DATA.status_id === 5) (this.correction_status = true)
        // set canada date
        this.setUSATime()
        this.getDepartmentAll(DATA)
        this.uploadExist(DATA)
        this.getEditField(DATA)
        this.getStatusAll(DATA)
        this.removeSpinner()        
      })
    },
    validate(event) {
      switch (this.axios_data.exception_type_id) {
        case 25:
          this.checkForLenght(event, 30)
        break;
        case 26:
          this.checkForLenght(event, 35)
        break;
        case 97: case 95: case 98:
          this.checkForLenght(event, 45)
        break;
      }
    },
    checkForLenght(event, max) {
      if (event.length > max) {
        this.editSystemExc = event.substr(0, max);
        this.showToast('Characters Limit', `Characters Limit extended, max characters is ${max}.`, 'warning', 2000);
      } else {
        this.editSystemExc = event
      }
    },
    setUSATime() {
      const current_date = new Date().toISOString().slice(0,10)
      this.usaTime = current_date.toLocaleString("en-US", {timeZone: "America/Vancouver"});
      // set canada time 24hours
      let time_date = new Date()
      this.usaTimeDate = time_date.toLocaleTimeString(
        "en-US", 
          {
            timeZone: "America/Vancouver", 
            hour12: false, 
            hour: '2-digit', 
            minute: '2-digit', 
            second: '2-digit'
          }
        );
      this.USA_DATE_TIME = `${this.usaTime} ${this.usaTimeDate}`
    },
    deleteSKU(isCheck) {
      switch (isCheck) {
        case true:
          this.delChecked = true
          this.deleteCheckbox = 1
          this.editSystemExc = 'DELETED'
          break;
        case false:
          this.delChecked = false
          this.deleteCheckbox = 0
          this.editSystemExc = ''
          break;
      }
    },
    // get correction if someone correct ticket
    getCorrections() {
      axios.get(`api/v1/local/corrections/${this.$route.params.id}`).then(res => {
        this.getCorrection = res.data
      })
    },
    /**
     * Set edit field for correction
     * Base on exception type field load
    */
    getEditField(DATA) {
      switch (DATA.exception_type_id) {
        case 1:
          this.exc_name = DATA.exception_message
          this.phone = true
        break;
        case 2: case 108:
          this.exc_name = DATA.exception_message
          this.badEmail = true
        break;
        case 3: case 5: case 7: case 8: case 21: case 72:
          this.exc_name = DATA.exception_message
          this.freeText = true
        break;
        case 4: case 105: case 106:
          this.exc_name = DATA.exception_message
          this.zipField = true
        break;
        case 6:
          this.exc_name = DATA.exception_message
          this.zipField = true
        break;
        case 25: case 26: case 95: case 97: case 98:
          const EXC_NAME = DATA.exception_message
          this.exc_name = EXC_NAME
          this.freeText = true
        break;
        default:
          this.edit = false
        break;
      }
    },
    /**
     * Get comment if exist and show
    */
    getComments() {
      axios.get(`api/v1/local/comments/${this.$route.params.id}`).then(res => {
        this.comment_info = res.data
        res.data.length > 0 ? this.comm = true : this.comm = false
      });
    },
    /**
     * Get upload file(s) if exist
    */
    uploadExist(axiosData) {
      for (let index = 0; index < axiosData.em_upload.length; index++) {
        const element = axiosData.em_upload[index]
        this.download_name = element.path.split("/")[5]
        this.dwn_link.push(element)
      }
      axiosData.em_upload.length > 0 ? this.filesForDownLoad = true : this.filesForDownLoad = false
    },
    /**
     * Prepare JSON for sent to back
    */
    prepareJSON(department) {
      const DATA = this.axios_data
      const datas = [
        {
          order_number: DATA.order_number,
          order_date: DATA.order_date,
          dist_id: DATA.dist_id,
          dist_name_1: DATA.dist_name_1,
          dist_name_2: DATA.dist_name_2,
          iso2: DATA.iso2,
          iso3: DATA.iso3,
          country: DATA.country,
          exception_type: DATA.exception_type_id,
          status: this.status_id,
          created_by: DATA.created_by,
          date_created: DATA.date_created,
          department: department,
          returned_from: this.returned_from
        }
      ]
      return datas
    },
    /**
     * Prepare JSON for correction ticket
    */
    prepareCorectionsJSON() {
      const correctionArray = [
        {
          exception_id: this.ID_exc,
          correction_value: this.editSystemExc,
          correction_status: this.correction_status,
          user_id: this.userID,
          correction_date: this.USA_DATE_TIME,
          should_delete : this.deleteCheckbox
        }
      ]
      return correctionArray
    },
    saveCorrections(prepareCorectionsJSON) {
      
      if (this.axios_data.exception_corrections.length === 0) {
        axios.post(`api/v1/local/corrections/insert`, prepareCorectionsJSON).then(res => {
          const RESPONSE = res.data[0]
          this.showToast(RESPONSE.message, `Successfully`, "success", 1000)
        })
      } else {
        axios.put(`api/v1/local/corrections/update/${this.axios_data.exception_corrections[0].id}`, prepareCorectionsJSON).then(res => {
          const RESPONSE = res.data[0]
          this.showToast(RESPONSE.message, `Successfully`, "success", 1000)
        })
      }
    },
    myUploader(event){
      document.body.style.cursor = 'wait'
      let uploadedFiles = 0
      for (let i = 0; i < event.files.length; i++) {
        let element = event.files[i]
        let files = element
        let data = new FormData();
        data.append('files', files);
        this.imageUpload(data).then((res) => {
          if(res.code === 200){
            let tmp = {
              "exception_id": this.ID_exc,
              "path": res.path[0]
            }
            this.uploads.push(tmp)
            uploadedFiles++
          }
          if (event.files.length === uploadedFiles) {
            this.submiting(this.uploads)
          }
        })
      }      
    },
    /**
     * Submit all data
    */
    submiting(obj) {
      document.body.style.cursor = 'wait'
      // get department from ticket maps method
      const department = this.ticketMaps()

      // get data from prepare JSON method and pass department
      const datas = this.prepareJSON(department)

      // prepare correction json
      const prepareCorectionsJSON = this.prepareCorectionsJSON()

      // Update comments if not empty
      if (this.new_comment_info.length > 0) (this.uploadComment())

      // Update file if not empty
      if (obj.length > 0) (this.uploadFiles(obj))

      // insert corrections
      this.saveCorrections(prepareCorectionsJSON)

      // Update this exception
      axios.put(`api/v1/local/exception/update/${this.$route.params.id}`, datas).then(res => {
        const RESPONSE = res.data[0]
        if (RESPONSE.code === 400) {
          this.showToast(RESPONSE.message, `Something went wrong`, "danger", 3000)
          document.body.style.cursor = 'default'
        } else {
          this.showToast("Exception Updated", `Successfully`, "success", 1200)
          document.body.style.cursor = 'default'
          setTimeout(() => { this.back() }, 1500)
        }
      })
    }
  }
}
</script>
