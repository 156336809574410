<template>
    <div class="compliance hr">
        <h5 class="col-12 my-3">Product Compliance</h5>
        <div class="col-12 px-0">
            <div class="col-6 d-inline-block mb-2">
                <p>Nature of the Complaint</p>
                <Textarea class="col-sm-12" type="text" v-model="natureOFCompliance" :autoResize="true" rows="4" cols="30" disabled/>
            </div>
            <div class="col-6 d-inline-block mb-2">
                <p>Preliminary Cause Analysis</p>
                <Textarea class="col-sm-12" type="text" v-model="causeAnalysis" :autoResize="true" rows="4" cols="30" disabled />
            </div>
        </div>
        <div class="col-12 px-0 mb-4">
            <div class="col-6 d-inline-block">
                <p>Risks of Illness or Injury</p>
                <SelectButton v-model="illness_injury_risk" :options="yesNo" disabled/>
            </div>
        </div>
        <div class="col-12 px-0 mb-4">
            <div class="col-3 d-inline-block mb-2 align-top">
                <p>Manufacture Investigation</p>
                <SelectButton v-model="manufacture_investigation" :options="yesNo" disabled />
            </div>
            <div class="col-9 d-inline-block" v-if="manufacture_investigation == 'Yes'">
                <p>Manufacture Investigation Details</p>
                <Textarea class="col-sm-12" type="text" v-model="mnfDetails" :autoResize="true" rows="3" cols="30" disabled />
            </div>
        </div>
        <div class="col-12 px-0 mb-4">
            <div class="col-6 d-inline-block mb-2">
                <p>COA Review</p>
                <SelectButton v-model="coa_review" :options="yesNo" disabled />
            </div>
        </div>
        <div class="col-12 px-0 mb-4">
            <div class="col-3 d-inline-block align-top mb-2">
                <p>Sample Sensory</p>
                <SelectButton v-model="sample_sensory" :options="yesNo" disabled />
            </div>
            <div class="col-9 d-inline-block mb-2" v-if="sample_sensory == 'Yes'">
                <p>Sample Sensory Details</p>
                <Textarea class="col-sm-12" type="text" v-model="SSDetails" :autoResize="true" rows="3" cols="30" disabled />
            </div>
        </div>
        <div class="col-12 px-0 mb-4">
            <div class="col-3 d-inline-block align-top mb-2">
                <p>Independent Lab Test</p>
                <SelectButton v-model="lab_test" :options="yesNo" disabled />
            </div>
            <div class="col-9 d-inline-block mb-2" v-if="lab_test == 'Yes'">
                <p>Independent Lab Test Details</p>
                <Textarea class="col-sm-12" type="text" v-model="labDetails" :autoResize="true" rows="3" cols="30" disabled />
            </div>
        </div>
        <div class="col-12 px-0 mb-4">
            <div class="col-3 d-inline-block align-top mb-2">
                <p>Investigation Extended</p>
                <SelectButton v-model="investigation_extend" :options="yesNo" disabled />
            </div>
            <div class="col-9 d-inline-block mb-2" v-if="investigation_extend == 'Yes'">
                <p>Investigation Extended Details</p>
                <Textarea class="col-sm-12" type="text" v-model="invDetails" :autoResize="true" rows="3" cols="30" disabled />
            </div>
        </div>
        <div class="col-12 px-0 mb-4">
            <div class="col-6 d-inline-block">
                <p>Recall Proposed</p>
                <SelectButton v-model="recall_proposed" :options="yesNo" disabled />
            </div>
            <div class="col-6 d-inline-block mb-2">
                <p>Return of Product Required</p>
                <SelectButton v-model="product_return" :options="yesNo" disabled />
            </div>
        </div>
        <div class="col-2">
            <p>Compliance Status</p>
            <InputText class="col-12" v-model="subStatus" disabled />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        propsData: {
            type: Object
        }
    },
    data() {
        return {
            yesNo: ['Yes', 'No'],
            natureOFCompliance: this.propsData.product_compliance[0].compliant_nature,
            causeAnalysis: this.propsData.product_compliance[0].cause_analysis,
            mnfDetails: this.propsData.product_compliance[0].manufacture_investigation_details,
            SSDetails: this.propsData.product_compliance[0].sample_sensory_details,
            labDetails: this.propsData.product_compliance[0].lab_test_details,
            invDetails: this.propsData.product_compliance[0].investigation_extend_details,
            illness_injury_risk: null,
            manufacture_investigation: null,
            coa_review: null,
            sample_sensory: null,
            lab_test: null,
            investigation_extend: null,
            recall_proposed: null,
            product_return: null,
            subStatus: ''
        }
    },
    mounted() {
        const compliance = this.propsData.product_compliance[0]
        // set substatus
        switch (compliance.sub_status) {
            case 1:
                this.subStatus = "Open";
            break;
            case 2:
                this.subStatus = "In Progress";
            break;
            case 3:
                this.subStatus = "Pending";
            break;
            case 5:
                this.subStatus = "Closed";
            break;
        }
        // loop from compliance object and set yes/no for selected button 
        Object.keys(compliance).forEach((key) => {
            switch (compliance[key]) {
            case true:
                this[key] = 'Yes';
            break;
            case false:
                this[key] = 'No';
            break;
            }
        });
    },
}
</script>