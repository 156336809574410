<template>
  <div>
    <spinnerHtml />
    <div v-if="!spinner">
      <ContextMenu :model="menuModel" ref="cm" />
      <div class="p-grid">
        <backBtn />
        <div class="p-col-6 align-end" v-if="this.axios_data.returned_from !== ''">
          <router-link
            :to="{ path: '/exception/view/ticket/' + this.ID_exc, query:{exType: this.type_id} }" target="_blank">
            <Button label="View All Sections" type="button" icon="pi pi-search" iconPos="right" class="center mr-2 mt-1" style="width:170px"></Button>
          </router-link>
        </div>
      </div>
      <div class="p-grid">
        <div class="p-col-6">
          <div class="card card-w-title border-none">
            <DataTable :value="table_data" class="p-datatable-responsive" :rows="20" contextMenu :contextMenuSelection.sync="cMenu" @row-contextmenu="onRowContextMenu" :lazy="true" :loading="loading">
              <template #header>Transaction Details</template>
              <Column field="order_number" v-model="order_number" header="Order Number"></Column>
              <Column field="order_date" v-model="order_date" header="Order Date"></Column>
              <Column field="dist_id" v-model="dist_id" header="Distributor ID"></Column>
              <Column field="dist_name_1" v-model="dist_name_1" header="Distributor’s Name"></Column>
              <Column field="country" v-model="country" header="Country"></Column>
            </DataTable>
          </div>
        </div>
        <div class="p-col-6">
          <Accordion class="card card-w-title border-none p-field p-col-12 p-md-12 accordion-custom">
            <AccordionTab header="Add New Comment">
              <div class="p-datatable card card-w-title border-none p-field p-col-12 p-md-12 float-r" >
                <h4 class="p-datatable-header">Reason/Comment</h4>
                <Textarea id="txt" :value="value" :autoResize="true" rows="5" cols="30" class="w-inherit" />
              </div>
              <Button label="Save" class="m-2 float-right" @click="saveComment"/>
              <div class="card card-w-title border-none p-field p-col-12 p-md-12">
                <DataTable class="comment_table" :value="new_comment_info_table">
                  <template #header>Reason/Comment Preview</template>
                  <Column field="comment_date" header="Date"></Column>
                  <Column field="comment_user" header="User"></Column>
                  <Column field="comment_body" header="Comment"></Column>
                  <Column bodyStyle="text-align: center" >
                    <template #header></template>
                    <template #body="slotProps">
                      <Button type="button" icon="pi pi-trash" :slotProps="slotProps" @click="deleteComment(slotProps.data.row_id)" class="center"></Button>
                    </template>
                  </Column>
                  <template #empty>
                    No new comments
                  </template>
                </DataTable>
              </div>
            </AccordionTab>
          </Accordion>
          <Accordion class="card card-w-title border-none p-field p-col-12 p-md-12 accordion-custom" v-if="comm">
            <AccordionTab header="Comment History">
                <DataTable class="comment_table" :value="comment_info" sortField="id" :sortOrder="-1">
                  <Column field="comment_date" sortable header="Date"></Column>
                  <Column field="comment_user" sortable header="User"></Column>
                  <Column field="comment_body" sortable header="Comment"></Column>
                </DataTable>
            </AccordionTab>
          </Accordion>
          <Accordion class="card card-w-title border-none p-field p-col-12 p-md-12 accordion-custom" v-if="filesForDownLoad">
            <AccordionTab header="Uploaded Files">
                <ul class="p-0" v-for="dwn in dwn_link" :key="dwn.paths">
                  <a :href="dwn.path">{{dwn.path.split("/")[5]}}</a>
                </ul>
            </AccordionTab>
          </Accordion>
          <div class="card card-w-title border-none p-field p-col-12 p-md-12">
            <FileUpload 
            name="files" 
            chooseLabel="Upload" 
            :multiple="true"
            :customUpload="true"
            @uploader="myUploader"
            accept=".csv, .xlx, .xlsx, .pdf, .doc, .txt, .jpg, .jpeg, .png, .docx, .PNG, .JPG, .JPEG" 
            :maxFileSize="10000000" >
            <template #empty>
              <p>Drag and drop files here to upload.</p>
            </template>
          </FileUpload>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="row mb-1 mt-3">
          <div class="col-4 d-inline-block">
            <h6>Exception Type</h6>
            <InputText class="col-12" type="text" v-model="exc_type" disabled />
          </div>
          <div class="col-4 d-inline-block">
            <h6>Action</h6>
            <InputText class="col-12" type="text" v-model="exc_action" disabled />
            </div>
          <div class="col-4 d-inline-block">
            <h6>Date</h6>
            <Calendar id="icon" v-model="current_date" :showIcon="true" dateFormat="dd/mm/yy" disabled />
          </div>
        </div>
      </div>
      <div class="hr">
        <div class="card col-6 d-inline-block" v-if="notInSystem">
          <DataTable :value="product_infoRE" class="editable-cells-table order-details">
            <template #header>Order Details</template>
            <Column field="sku_id" header="Item ID"></Column>
            <Column field="sku_name" header="Item Name"></Column>
            <Column field="sku_quantity" header="Qty"></Column>
          </DataTable>
        </div>
      </div>
      <div class="hr" v-if="notInSystem"></div>
      <div class="col-12" v-if="sectionUpdate">
        <h5>Accounting</h5>
      </div>
      <!-- payment releated -->
      <div class="row my-4" v-if="paymentRelatedShow">
        <div class="col-12">
          <h6 class="col-12">Credit Card Details:</h6>
          <div class="col-3 d-inline-block">
            <p>Credit Card Number</p>
            <InputText class="col-12" v-model="creditCardNumber" placeholder="****-****-****-****" :disabled="this.role == 'accounting'" />
          </div>
          <div class="col-3 d-inline-block">
            <p>Cardholder Name</p>
            <InputText class="col-12" v-model="cardName" :disabled="this.role == 'accounting'" />
          </div>
          <div class="col-3 d-inline-block">
            <p>Expiry Date</p>
            <Calendar v-model="expiry_date" :showIcon="true" :manualInput="false" dateFormat="yy-mm-dd" @date-select="formatCalendarDate($event, calendar = 'expiry_date')" :disabled="this.role == 'accounting'" />
          </div>
        </div>
      </div>
      <div class="row my-4" v-if="paymentRelatedShow">
        <div class="col-12">
          <h6 class="col-12">Transaction Details:</h6>
          <div class="col-3 d-inline-block">
            <p>Transaction Amount</p>
            <InputText class="col-12" v-model="transaction_amount" :disabled="this.role == 'accounting'" />
          </div>
          <div class="col-3 d-inline-block">
            <p>Transaction Date</p>
            <Calendar v-model="transaction_date" :showIcon="true" :maxDate="maxDateValue" :manualInput="false" dateFormat="yy-mm-dd" @date-select="formatCalendarDate($event, calendar = 'transaction_date')" :disabled="this.role == 'accounting'" />
          </div>
        </div>
      </div>
      <!-- payment for acc -->
      <div class="row col-12 my-4" v-if="paymentRelatedShowACC">
        <div class="col-3">
          <p>Charge has been found</p>
          <SelectButton v-model="charge_found" :options="yesNo" @input="accSelectedBtn($event, field = 'paymentACCBool')" />
        </div>
        <div class="col-3 pr-0 pl-4">
          <p>Charge Details</p>
          <Textarea class="col-12" v-model="chargeDetails" :autoResize="true" rows="4" cols="20" @input="validateTextarea"/>
        </div>
      </div>
      <!-- status section  -->
      <div class="col-9 p-0">
        <div class="col-4">
          <p>Status</p>
          <Dropdown class="col-12" v-model="selectedStatus" :options="statusDropdown" optionLabel="status_value" :placeholder="current_status" @change="onStatusChange"/>
        </div>
        <div class="col-4 mt-3" v-if="reassigned">
          <p>Assing To:</p>
          <Dropdown class="col-12" v-model="assingToDepartment" :options="reassingMap" :placeholder="reassing" optionLabel="assing_value" @change="assingToChange"/>
        </div>
      </div>
      <div class="col-12">
        <Button class="float-right" label="Submit" @click="submitForm"/>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import methods from mixins
import getAndSet from '../../../store/getAndSet.js'
import reassingMap from '../../../store/reassingMaping.js'
import ticketFlow from '../../../store/ticketFlow.js'
import onSubmitActions from '../../../store/onSubmitActions.js'
import selectedButton from '../../../store/selectedButton.js'
import mainMethods from '../../../store/mainMethods.js'
import accounting from '../../../store/accounting.js'
import spinnerJS from '../../../store/spinner.js'
// componenet
import spinnerHtml from '../../html/spinner.vue'
import backBtn from '../../html/back_button.vue'

export default {
  mixins: [mainMethods, getAndSet, reassingMap, ticketFlow, onSubmitActions, selectedButton, accounting, spinnerJS],
  components: {
    spinnerHtml,
    backBtn
  },
  data() {
    return {
      department: null,
      role: null,
      assingTo: null,
      assingToDepartment: null,
      assing: [],
      reassigned: false,
      reassing: null,
      axios_data: [],
      table_data: [],
      exc_type: null,
      exc_action: null,
      selectedAction: null,
      loading: true,
      cMenu: {},
      product_info: [],
      comment_info: [],
      new_comment_info:[],
      new_comment_info_table: [],
      dwn_link: [],
      filesForDownLoad: false,
      files: [],
      current_status: null,
      value: null,
      date_created: null,
      order_date: null,
      order_number: null,
      dist_id: null,
      dist_name_1: null,
      country: null,
      comment_body: null,
      copyText: null,
      user: null,
      ID_exc: null,
      current_date : null,
      comm: false,
      uploads: [],
      product_infoRE: [],
      product_infoRELESE: [],
      date_req: null,
      selectedStatus: null,
      statusDropdown: [],
      status_id: null,
      usaTime: null,
      sectionUpdate: true,
      menuModel: [
        {
          label: 'Copy',
          icon: 'pi pi-fw pi-copy',
          command: () => this.copy(this.table_data)
        }
      ],
      
      chargeDetails: '',
      payment: [],
      accounting: [],
      accountingID: null,
      paymentShow: true,
      yesNo: ['Yes', 'No'],
      charge_found: null,
      charge_processing_fee: null,
      checkRefundBool: null,
      processingFeeBoll: null,
      accProcessedBoll: null,
      paymentACCBool: null,
      paymentRelatedShow: true,
      creditCardNumber: null,
      cardName: '',
      expiry_date: null,
      transaction_amount: null,
      transaction_date: null,
      type_id: null,
      paymentRelatedShowACC: true,
      notInSystem: true,
      maxDateValue: null,
      returned_from: ''
    };
  },
  mounted() {
    this.getUserName()
    this.setMaxDate()
    this.getData()
  },
  methods: {
    getData() {
      axios.get(`api/v1/local/exception/${this.$route.params.id}`).then(res => {
        this.axios_data = res.data[0]
        this.table_data = res.data
        // all data
        const DATA = this.axios_data
        this.cMenu = DATA;
        this.product_info = DATA.product_info;
        this.ID_exc = DATA.id
        this.type_id = DATA.exception_type_id
        this.comment_info = DATA.em_comments;
        this.order_number = DATA.order_number
        this.date_created = DATA.record_date;
        this.current_status = DATA.status
        this.status_id = DATA.status_id
        this.exc_type = DATA.exception_message
        this.exc_action = DATA.action_name
        this.selectedAction = DATA.action_id
        this.current_date = DATA.date_created
        this.loading = false;
        // canada date
        const now_date = new Date().toISOString().slice(0,10)
        this.usaTime = now_date.toLocaleString("en-US", {timeZone: "America/Vancouver"});
        // populate accounting data from accounting.js file
        if (DATA.accounting[0] !== undefined) (this.populateDataAccounting(DATA))

        if (this.role === "csr") (this.ifCSR())
        this.selectedBtn(DATA)
        this.removeEmpty(DATA)
        this.showUploads(DATA)
        this.notIn(DATA)
        this.getDepartmentAll(DATA)
        this.getStatusAll(DATA)
        this.removeSpinner()
      });
    },
    validateTextarea(event) {
      if (event.length > 200) {
        this.chargeDetails = event.substr(0, 200)
        this.addslashes(this.chargeDetails)
        this.showToast('Characters Limit', 'Characters Limit extended, max characters is 200.', 'warning', 2000)
      }
    },
    notIn(DATA) {
      if (DATA.order_number === 0) {
        this.sectionUpdate = false
        this.notInSystem = false
      }
    },
    ifCSR() {
      this.sectionUpdate = false
      this.paymentRelatedShowACC = false
    },
    removeEmpty(DATA) {
      for (let index = 0; index < DATA.product_info.length; index++) {
        const element = DATA.product_info[index];
        element.sku_id !== null ? this.product_infoRE.push(element) : this.product_infoRELESE.push(element)
      }
    },
    prepareJSON(department) {
      const datas = [
        {
          order_number: this.axios_data.order_number,
          order_date: this.axios_data.order_date,
          dist_id: this.axios_data.dist_id,
          dist_name_1: this.axios_data.dist_name_1,
          dist_name_2: this.axios_data.dist_name_2,
          iso2: this.axios_data.iso2,
          iso3: this.axios_data.iso3,
          exception_type: this.axios_data.exception_type_id,
          status: this.status_id,
          created_by: this.axios_data.created_by,
          date_created: this.axios_data.date_created,
          action_id: this.selectedAction,
          department: department,
          returned_from: this.returned_from
        }
      ]
      return datas
    },
    myUploader(event){
      document.body.style.cursor = 'wait'
      let uploadedFiles = 0
      for (let i = 0; i < event.files.length; i++) {
        let element = event.files[i]
        let files = element
        let data = new FormData();
        data.append('files', files);
        this.imageUpload(data).then((res) => {
          if(res.code === 200){
            let tmp = {
              "exception_id": this.ID_exc,
              "path": res.path[0]
            }
            this.uploads.push(tmp)
            uploadedFiles++
          }
          if (event.files.length === uploadedFiles) {
            this.submiting(this.uploads)
          }
        })
      }      
    },
    submiting(obj) {
      document.body.style.cursor = 'wait'
      // accounting section
      this.prepareAccJSON()
      this.getAccounting(this.accounting)

      const department = this.ticketMaps()
      const datas = this.prepareJSON(department)

      // Update comments if not empty
      if (this.new_comment_info.length > 0) (this.uploadComment())

      // Update file if not empty
      if (obj.length > 0) (this.uploadFiles(obj))
      
      // Update this exception
      axios.put(`api/v1/local/exception/update/${this.ID_exc}`, datas).then(res => {
        const RESPONSE = res.data[0]
        if (RESPONSE.code === 400) {
          this.showToast('Exception NOT Updated', RESPONSE.message, 'danger', 2500)
          document.body.style.cursor = 'default'
        } else {
          this.showToast('Successfully', 'Exception Updated', 'success', 1200)
          document.body.style.cursor = 'default'
          this.back()
        }
      })
    }
  }
}
</script>
