<template>
    <div>
        <div class="spinner" v-if="spinner">
            <ProgressSpinner strokeWidth="4" fill="#fff" animationDuration="3s" class="text-center d-block"/>
        </div>
    </div>
</template>

<script>
import EventBus from "../../event-bus";

export default {
    data() {
        return {
            spinner: true
        }
    },
    mounted() {
        this.getEvent()
    },
    methods: {
        // received event form parent
        getEvent() {
            EventBus.$on('spinnerFalse', (status) => (this.spinner = status))
        },
    },
}
</script>