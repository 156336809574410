<template>
  <div>
    <spinnerHtml />
    <div v-if="!spinner">
      <div class="p-grid">
        <backBtn />
        <div class="p-col-6 align-end" v-if="this.axios_data.returned_from !== ''">
          <router-link
            :to="{ path: '/exception/view/ticket/' + this.ID_exc, query:{exType: this.type_id} }" target="_blank">
            <Button label="View All Sections" type="button" icon="pi pi-search" iconPos="right" class="center mr-2 mt-1" style="width:170px"></Button>
          </router-link>
        </div>
      </div>
      <ContextMenu :model="menuModel" ref="cm" />
      <div class="p-grid">
        <div class="p-col-6">
          <div class="card card-w-title">
            <DataTable :value="table_data" class="p-datatable-responsive" :rows="20" contextMenu :contextMenuSelection.sync="cMenu" @row-contextmenu="onRowContextMenu" :lazy="true" :loading="loading">
              <template #header>Transaction Details</template>
              <Column field="order_number" v-model="order_number" header="Order Number"></Column>
              <Column field="order_date" v-model="order_date" header="Order Date"></Column>
              <Column field="dist_id" v-model="dist_id" header="Distributor ID"></Column>
              <Column field="dist_name_1" v-model="dist_name_1" header="Distributor’s Name"></Column>
              <Column field="country" v-model="country" header="Country"></Column>
            </DataTable>
          </div>
        </div>
        <div class="p-col-6">
          <Accordion class="card card-w-title p-field p-col-12 p-md-12 accordion-custom">
            <AccordionTab header="Add New Comment">
              <div class="p-datatable card card-w-title p-field p-col-12 p-md-12 float-r" >
                <h4 class="p-datatable-header">Reason/Comment</h4>
                <Textarea id="txt" :value="textarea" :autoResize="true" rows="5" cols="30" class="w-inherit" />
              </div>
              <Button label="Save" class="m-2 float-right" @click="saveComment"/>
              <div class="card card-w-title p-field p-col-12 p-md-12">
                <DataTable class="comment_table" :value="new_comment_info_table">
                  <template #header>Reason/Comment Preview</template>
                  <Column field="comment_date" header="Date"></Column>
                  <Column field="comment_user" header="User"></Column>
                  <Column field="comment_body" header="Comment"></Column>
                  <Column bodyStyle="text-align: center" >
                    <template #header></template>
                    <template #body="slotProps">
                      <Button type="button" icon="pi pi-trash" :slotProps="slotProps" @click="deleteComment(slotProps.data.row_id)" class="center"></Button>
                    </template>
                  </Column>
                  <template #empty>
                    No new comments
                  </template>
                </DataTable>
              </div>
            </AccordionTab>
          </Accordion>
          <Accordion class="card card-w-title p-field p-col-12 p-md-12 accordion-custom" v-if="comm">
            <AccordionTab header="Comment History">
                <DataTable class="comment_table" :value="comment_info" sortField="id" :sortOrder="-1">
                  <Column field="comment_date" sortable header="Date"></Column>
                  <Column field="comment_user" sortable header="User"></Column>
                  <Column field="comment_body" sortable header="Comment"></Column>
                </DataTable>
            </AccordionTab>
          </Accordion>
          <Accordion class="card card-w-title p-field p-col-12 p-md-12 accordion-custom" v-if="filesForDownLoad">
            <AccordionTab header="Uploaded Files">
                <ul class="p-0" v-for="dwn in dwn_link" :key="dwn.paths">
                  <a :href="dwn.path">{{dwn.path.split("/")[5]}}</a>
                </ul>
            </AccordionTab>
          </Accordion>
          <div class="card card-w-title p-field p-col-12 p-md-12">
            <FileUpload 
            name="files" 
            chooseLabel="Upload" 
            :multiple="true"
            :customUpload="true"
            @uploader="myUploader"
            accept=".csv, .xlx, .xlsx, .pdf, .doc, .txt, .jpg, .jpeg, .png, .docx, .PNG, .JPG, .JPEG" 
            :maxFileSize="10000000" >
            <template #empty>
              <p>Drag and drop files here to upload.</p>
            </template>
          </FileUpload>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="row mb-1 mt-3">
          <div class="col-4 d-inline-block">
            <h6>Exception Type</h6>
            <InputText class="col-12" type="text" v-model="exc_type" disabled />
          </div>
          <div class="col-4 d-inline-block">
            <h6>Action</h6>
            <InputText class="col-12" type="text" v-model="exc_action" disabled />
            </div>
          <div class="col-4 d-inline-block">
            <h6>Date</h6>
            <Calendar id="icon" v-model="current_date" :showIcon="true" dateFormat="dd/mm/yy" disabled />
          </div>
        </div>
      </div>
      <div class="hr">
        <div class="card col-6 d-inline-block">
          <DataTable :value="product_infoRE" class="editable-cells-table order-details">
            <template #header>{{ orderTableTitle }}</template>
            <Column field="sku_id" header="Item ID"></Column>
            <Column field="sku_name" header="Item Name"></Column>
            <Column field="sku_quantity" header="Qty"></Column>
            <Column field="sku_quantity_return" header="Qty for Return" v-if="quantyForReturn"></Column>
            <template #empty>
                No product
            </template>
          </DataTable>
        </div>
        <div class="card col-6 d-inline-block" v-if="releaseTableShow">
          <DataTable :value="product_infoRELESE" class="editable-cells-table release" ref="dt" dataKey="id">
            <template #header>
              <div class="table-header">
                <span class="m-auto">Products for Release</span>
              </div>
            </template>
            <Column field="release_sku_id" header="Item ID"></Column>
            <Column field="release_sku_name" header="Item Name"></Column>
            <Column field="release_sku_quantity" header="Qty for Release"></Column>
            <template #empty>
                No product
            </template>
          </DataTable>
        </div>
      </div>
      <div class="row col-12 mt-3">
        <div class="col-3 pr-4 d-inline-block">
          <p>Batch or Lot Number:</p>
          <InputText class="col-sm-12" v-model="batch_lot_number"/>
        </div>
        <div class="col-3 d-inline-block pl-2 pr-0">
          <p>Date Received</p>
          <Calendar id="received_date" v-model="received_date" :manualInput="false" :showIcon="true" dateFormat="dd/mm/yy"/>
        </div>
        <div class="col-3 d-inline-block pl-4">
          <p>Storage Conditions</p>
          <Textarea class="col-sm-12" v-model="storage_conditions" :autoResize="true" rows="3" cols="30"/>
          <small class="text-danger d-block position-absolute" v-if="storage_conditions.length > 100">Character limit exceeded!</small>
        </div>
      </div>
      <div class="col-sm-3 mt-3 pr-3" v-if="showPayment">
        <label for="payment_email" class="d-block">Email for Payment</label>
        <InputText class="col-sm-12 mb-2" id="payment_email" v-model="payment_email" placeholder="payment@email.com" @change="emailChecker"/>
        <small class="row col-sm-12 text-danger d-block" v-if="error">E mail address is not correct!</small>
      </div>
      <div class="shipping-box my-4 hr" v-if="shipping">
        <h6 class="col-sm-12">Shipping Address:</h6>
        <div class="col-sm-3 d-inline-block">
          <p>Country/Region:</p>
          <InputText class="col-sm-12" v-model="current_country" />
        </div>
        <div class="col-sm-3 d-inline-block">
          <p>Street Address:</p>
          <InputText class="col-sm-12" v-model="current_address" />
        </div>
        <div class="col-sm-2 d-inline-block">
          <p>City:</p>
          <InputText class="col-sm-12" v-model="current_city" />
        </div>
        <div class="col-sm-2 d-inline-block">
          <p>State/Province:</p>
          <InputText class="col-sm-12" v-model="current_state" />
        </div>
        <div class="col-sm-2 d-inline-block">
          <p>Zip/Postal Code:</p>
          <InputText class="col-sm-12" v-model="current_zip" />
        </div>
      </div>
      <div class="hr" v-if="returnItemShow">
        <div class="col-sm-3 d-inline-block">
          <h6>Return Item Options:</h6>
          <SelectButton v-model="rio" :options="rio_options" @input="rioChanged"/>
        </div>
        <div class="col-sm-3 d-inline-block align-top">
          <label for="e_mail">E-mail</label>
          <InputText class="col-sm-12" id="e_mail" v-model="e_mail" placeholder="max@max.com" />
        </div>
        <div class="col-sm-2 d-inline-block align-top">
          <label for="phone">Phone</label>
          <InputMask class="col-sm-12" mask="(999) 9999-999" v-model="phone" placeholder="(999) 9999-999" @change="checkPhone"/>
        </div>
      </div>
      <div class="my-4" v-if="pickUP">
        <h6 class="col-sm-12">Picking up Address:
          <Button icon="pi pi-copy" class="p-button" @click="copyAddress" v-tooltip.top="'Copy address from shipping!'"/>
        </h6>
        <div class="col-sm-3 d-inline-block">
          <p>Country/Region</p>
          <InputText class="col-sm-12" type="text" v-model="PUcountry" />
        </div>
        <div class="col-sm-3 d-inline-block">
          <p>Street Address</p>
          <InputText class="col-sm-12" type="text" v-model="PUaddress" />
        </div>
        <div class="col-sm-2 d-inline-block">
          <p>City</p>
          <InputText class="col-sm-12" type="text" v-model="PUcity" />
        </div>
        <div class="col-sm-2 d-inline-block">
          <p>State/Province</p>
          <InputText class="col-sm-12" type="text" v-model="PUstate" />
        </div>
        <div class="col-sm-2 d-inline-block">
          <p>Zip/Postal Code</p>
          <InputText class="col-sm-12" type="text" v-model="PUzip" />
        </div>
      </div>
      <hr v-if="CSRLead">
      <div class="compliance hr" v-if="compliance">
        <h5 class="col-12 mb-3">Product Compliance</h5>
        <div class="col-12 px-0">
          <div class="col-6 d-inline-block mb-2">
            <p>Nature of the Complaint</p>
            <Textarea class="col-sm-12" type="text" v-model="natureOFCompliance" :autoResize="true" rows="4" cols="30" @input="maxLenght($event, field = 'natureOFCompliance')" />
          </div>
          <div class="col-6 d-inline-block mb-2">
            <p>Preliminary Cause Analysis</p>
            <Textarea class="col-sm-12" type="text" v-model="causeAnalysis" :autoResize="true" rows="4" cols="30" @input="maxLenght($event, field = 'causeAnalysis')" />
          </div>
        </div>
        <div class="col-12 px-0 mb-4">
          <div class="col-6 d-inline-block">
            <p>Risks of Illness or Injury</p>
            <SelectButton v-model="illness_injury_risk" :options="yesNo" @input="complianceSelectButton($event, button = 'illness_injury_risk_bool')"/>
          </div>
        </div>
        <div class="col-12 px-0 mb-4">
          <div class="col-3 d-inline-block mb-2 align-top">
            <p>Manufacture Investigation</p>
            <SelectButton v-model="manufacture_investigation" :options="yesNo" @input="complianceSelectButton($event, button = 'manufacture_investigation_bool')"/>
          </div>
          <div class="col-9 d-inline-block" v-if="manufacture_investigation === 'Yes'">
            <p>Manufacture Investigation Details</p>
            <Textarea class="col-sm-12" type="text" v-model="mnfDetails" :autoResize="true" rows="3" cols="30" @input="maxLenght($event, field = 'mnfDetails')"/>
          </div>
        </div>
        <div class="col-12 px-0 mb-4">
          <div class="col-6 d-inline-block mb-2">
            <p>COA Review</p>
            <SelectButton v-model="coa_review" :options="yesNo" @input="complianceSelectButton($event, button = 'coa_review_bool')"/>
          </div>
        </div>
        <div class="col-12 px-0 mb-4">
          <div class="col-3 d-inline-block align-top mb-2">
            <p>Sample Sensory</p>
            <SelectButton v-model="sample_sensory" :options="yesNo" @input="complianceSelectButton($event, button = 'sample_sensory_bool')"/>
          </div>
          <div class="col-9 d-inline-block mb-2" v-if="sample_sensory == 'Yes'">
            <p>Sample Sensory Details</p>
            <Textarea class="col-sm-12" type="text" v-model="SSDetails" :autoResize="true" rows="3" cols="30" @input="maxLenght($event, field = 'SSDetails')"/>
          </div>
        </div>
        <div class="col-12 px-0 mb-4">
          <div class="col-3 d-inline-block align-top mb-2">
            <p>Independent Lab Test</p>
            <SelectButton v-model="lab_test" :options="yesNo" @input="complianceSelectButton($event, button = 'lab_test_bool')"/>
          </div>
          <div class="col-9 d-inline-block mb-2" v-if="lab_test == 'Yes'">
            <p>Independent Lab Test Details</p>
            <Textarea class="col-sm-12" type="text" v-model="labDetails" :autoResize="true" rows="3" cols="30" @input="maxLenght($event, field = 'labDetails')"/>
          </div>
        </div>
        <div class="col-12 px-0 mb-4">
          <div class="col-3 d-inline-block align-top mb-2">
            <p>Investigation Extended</p>
            <SelectButton v-model="investigation_extend" :options="yesNo" @input="complianceSelectButton($event, button = 'investigation_extend_bool')"/>
          </div>
          <div class="col-9 d-inline-block mb-2" v-if="investigation_extend == 'Yes'">
            <p>Investigation Extended Details</p>
            <Textarea class="col-sm-12" type="text" v-model="invDetails" :autoResize="true" rows="3" cols="30" @input="maxLenght($event, field = 'invDetails')"/>
          </div>
        </div>
        <div class="col-12 px-0 mb-4">
          <div class="col-6 d-inline-block">
            <p>Recall Proposed</p>
            <SelectButton v-model="recall_proposed" :options="yesNo" @input="complianceSelectButton($event, button = 'recall_proposed_bool')"/>
          </div>
          <div class="col-6 d-inline-block mb-2">
            <p>Return of Product Required</p>
            <SelectButton v-model="product_return" :options="yesNo" @input="complianceSelectButton($event, button = 'product_return_bool')"/>
          </div>
        </div>
        <div class="col-2" v-if="subStatusShow">
          <p>Compliance Status</p>
          <InputText class="col-12" v-model="subStatus" disabled />
        </div>
      </div>
      <hr v-if="this.role === 'compliance'">
      <hr v-if="sectionUpdate">
      <div class="col-12 my-4" v-if="sectionUpdate">
        <h5>Shipping And Returns</h5>
      </div>
      <div class="crud mb-4" v-if="returnLabelShow">
        <div class="col-sm-6 d-inline-block">
          <DataTable :value="return_label" class="editable-cells-table" editMode="row" dataKey="id" :editingRows.sync="editingRows"
          @row-edit-init="onRowEditInit" @row-edit-cancel="onRowEditCancel">
            <template #header>
              <div class="table-header justify-content-between">
                <span>Return Label Table</span>
                <Button icon="pi pi-plus" @click="addNew" :disabled="isApprovedSelected"/>
              </div>
            </template>
            <Column field="rl_tracking_number" header="RL Tracking number">
              <template #editor="slotProps">
                <InputText v-model="slotProps.data[slotProps.column.field]" />
              </template>
            </Column>
            <Column field="rl_cost" header="RL Cost">
              <template #editor="slotProps">
                <InputText v-model="slotProps.data[slotProps.column.field]" />
              </template>
            </Column>
            <Column field="rl_weight" header="Weight">
              <template #editor="slotProps">
                <InputText v-model="slotProps.data[slotProps.column.field]" />
              </template>
            </Column>
            <template #empty>
                No records found.
            </template>
            <Column :rowEditor="true" headerStyle="width:7rem" bodyStyle="text-align:center"></Column>
          </DataTable>
          <!-- POP UP -->
          <Dialog :visible.sync="addNewReturnItem" :style="{width: '450px'}" header="Product Details" :modal="true" class="p-fluid">
            <div class="p-field">
              <label for="name">RL Tracking number</label>
              <InputText v-model="rl_number" />
            </div>
            <div class="p-field">
              <label for="description">RL Cost</label>
              <InputText v-model="rl_cost" @change="replaceCommaToDot($event, field = 'rl_cost')" />
            </div>
            <div class="p-field">
              <label for="description">Weight</label>
              <InputText v-model="rl_weight" @change="replaceCommaToDot($event, field = 'rl_weight')" />
            </div>
            <template #footer>
              <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
              <Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveProduct" />
            </template>
          </Dialog>
        </div>
        <div class="col-sm-2 d-inline-block v-top">
          <p>Is Approved:</p>
          <SelectButton v-model="approved" :options="yesNo" @input="approvedChanged"/>
        </div>
        <div class="col-sm-2 d-inline-block v-top">
          <p>Date Processed:</p>
          <Calendar id="icons" v-model="date_req" :showIcon="true" dateFormat="yyyy-mm-dd" @date-select="formatDate" :disabled="isApprovedSelected" :maxDate="maxDateValue" :manualInput="false"/>
        </div>
      </div>
      <!-- For Accounting  -->
      <div class="mt-4" v-if="forACCSection">
        <div class="col-12">
          <h6>For Accounting</h6>
          <div class="row col-12 mb-3">
            <div class="col-3 px-0">
              <p>Check Refund</p>
              <SelectButton v-model="check_refund" :options="yesNo" @input="accSelectedBtn($event, field = 'checkRefundBool')" />
            </div>
            <div class="col-3 pr-0" v-if="check_refund == 'Yes'">
              <p>Amount</p>
              <InputText class="col-12" v-model="checkAmount" @change="replaceCommaToDot($event, field = 'checkAmount')"/>
            </div>
          </div>
          <div class="row col-12 mb-3" v-if="check_refund == 'Yes'">
            <div class="col-3 px-0">
              <p>Charge Processing Fee</p>
              <SelectButton v-model="charge_processing_fee" :options="yesNo" @input="accSelectedBtn($event, field = 'processingFeeBoll')" />
            </div>
            <div class="col-3 pr-0">
              <p>Check Payee Name</p>
              <InputText class="col-12" v-model="dist_name_1" />
            </div>
          </div>
          <div class="row my-4" v-if="check_refund == 'Yes'">
            <h6 class="col-sm-12">Mailing Address:
              <Button icon="pi pi-copy" class="p-button" @click="copyToMailAddress" v-tooltip.top="'Copy address from shipping!'"/>
            </h6>
            <div class="col-sm-3 d-inline-block">
              <p>Country/Region:</p>
              <InputText class="col-sm-12" v-model="check_country" />
            </div>
            <div class="col-sm-3 d-inline-block">
              <p>Street Address:</p>
              <InputText class="col-sm-12" v-model="check_address" />
            </div>
            <div class="col-sm-2 d-inline-block">
              <p>City:</p>
              <InputText class="col-sm-12" v-model="check_city" />
            </div>
            <div class="col-sm-2 d-inline-block">
              <p>State/Province:</p>
              <InputText class="col-sm-12" v-model="check_state" />
            </div>
            <div class="col-sm-2 d-inline-block">
              <p>Zip/Postal Code:</p>
              <InputText class="col-sm-12" v-model="check_zip" />
            </div>
          </div>
        </div>
      </div>

      <!-- For Acc Section  -->
      <hr v-if="accountingSection">
      <div class="row" v-if="accountingSection">
        <div class="col-12">
          <h6 class="col-12">Accounting</h6>
          <div class="mb-3">
            <div class="col-3 d-inline-block">
              <p>Check Amount</p>
              <InputText class="col-12" v-model="checkAmountACC" @change="replaceCommaToDot($event, field = 'checkAmountACC')" />
            </div>
            <div class="col-3 d-inline-block">
              <p>Check Number</p>
              <InputText class="col-12" v-model="checkNumberACC" @input="subString($event, field = 'checkNumberACC')" />
            </div>
            <div class="col-3 d-inline-block">
              <p>Check Date</p>
              <Calendar id="checkDate" v-model="checkDate" :showIcon="true" :manualInput="false" dateFormat="yy-mm-dd" @date-select="formatCalendarDate($event, calendar = 'checkDate')"/>
            </div>
          </div>
          <div class="col-12 my-4">
            <div class="col-3 d-inline-block pl-0">
              <p>Processed by Accounting</p>
              <SelectButton v-model="acc_processed" :options="yesNo" @input="accSelectedBtn($event, field = 'accProcessedBoll')" />
            </div>
            <div class="col-3 d-inline-block align-top pl-1">
              <p>Accounting Date Processed</p>
              <Calendar id="processedDate" v-model="processedDate" :showIcon="true" :maxDate="maxDateValue" :manualInput="false" dateFormat="yy-mm-dd" @date-select="formatCalendarDate($event, calendar = 'processedDate')"/>
            </div>
          </div>
        </div>
      </div>
      <!-- status section  -->
      <div class="col-9 mt-3 p-0">
        <div class="col-4 mb-3" v-if="charge_amount_show">
          <p>Charge Amount</p>
          <InputText class="col-12" v-model="charge_amount" @change="replaceCommaToDot($event, field = 'charge_amount')"/>
        </div>
        <div class="col-4">
          <p>Status</p>
          <Dropdown class="col-12" v-model="selectedStatus" :options="statusDropdown" optionLabel="status_value" :placeholder="current_status" @change="onStatusChange"/>
          <small class="text-warning" v-if="this.status_id === null">Status must be selected!</small>
        </div>
        <div class="col-4 mt-3" v-if="reassigned">
          <p>Assing To:</p>
          <Dropdown class="col-12" v-model="assingToDepartment" :options="reassingMap" :placeholder="reassing" optionLabel="assing_value" @change="assingToChange"/>
        </div>
      </div>
      <div class="col-12">
        <Button class="float-right" label="Submit" @click="submitForm" :disabled="this.error || this.status_id === null"/>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// need for cancel editing in table
import Vue from 'vue';
// import methods from mixins
import getAndSet from '../../../store/getAndSet.js'
import reassingMap from '../../../store/reassingMaping.js'
import ticketFlow from '../../../store/ticketFlow.js'
import onSubmitActions from '../../../store/onSubmitActions.js'
import selectedButton from '../../../store/selectedButton.js'
import mainMethods from '../../../store/mainMethods.js'
import accounting from '../../../store/accounting.js'
import compliance from '../../../store/compliance.js'
import spinnerJS from '../../../store/spinner.js'
// componenet
import spinnerHtml from '../../html/spinner.vue'
import backBtn from '../../html/back_button.vue'

export default {
  mixins: [mainMethods, getAndSet, reassingMap, ticketFlow, onSubmitActions, selectedButton, accounting, compliance, spinnerJS],
  components: {
    spinnerHtml,
    backBtn
  },
  data() {
    return {
      department: null,
      role: null,
      assingTo: null,
      assingToDepartment: null,
      assing: [],
      reassigned: false,
      reassing: null,
      axios_data: [],
      table_data: [],
      exc_type: null,
      exc_action: null,
      selectedAction: null,
      loading: true,
      cMenu: {},
      product_info: [],
      comment_info: [],
      new_comment_info:[],
      new_comment_info_table: [],
      dwn_link: [],
      filesForDownLoad: false,
      files: [],
      current_status: null,
      textarea: null,
      date_created: null,
      order_date: null,
      order_number: null,
      dist_id: null,
      dist_name_1: null,
      country: null,
      comment_body: null,
      copyText: null,
      user: null,
      ID_exc: null,
      quantyForReturn: true,
      releaseTableShow: false,
      current_date : null,
      selectedStatus: null,
      statusDropdown: [],
      status_id: null,
      uploads: [],
      comm: false,
      storage_conditions: '',
      menuModel: [
        {
          label: 'Copy', 
          icon: 'pi pi-fw pi-copy', 
          command: () => this.copy(this.table_data)
        }
      ],
      product_infoRE: [],
      product_infoRELESE: [],
      returnItemShow: true,
      returnLabelShow: true,
      phone: null,
      e_mail: null,
      PUcountry: null,
      PUaddress: null,
      PUcity: null,
      PUstate: null,
      PUzip: null,
      return_item_option: null,
      return_label: [],
      rl: [],
      rl2: [],
      rl_all: [],
      addNewReturnItem: false,
      submitted: false,
      rl_number: null,
      rl_cost: null,
      rl_weight: null,
      approved: null,
      yesNo: ['Yes', 'No'],
      rio_options: ['E-Label', '3 Pickup'],
      date_req: null,
      rio: null,
      approvedCH: null,
      editingCellRows: {},
      pickUP: false,
      label_info_id: null,
      // shipping box
      current_country: null,
      current_address: null,
      current_city: null,
      current_state: null,
      current_zip: null,
      shipping: false,
      
      // return label table
      isApprovedSelected: false,
      LABEL_DATA: null,
      editingRows: [],
      columns: null,
      forUpdate: [],
      bulkArray: [],
      error: false,
      payment_email: null,
      showPayment: false,
      orderTableTitle: 'Order Details/Products for Return',
      usaTime: null,
      sectionUpdate: true,
      charge_amount: null,
      charge_amount_show: true,
      batch_lot_number: '',
      received_date: '',
      product_compliance: [],
      product_complianceID: null,
      type_id: null,
      sub_status: null,
      sub_department: null,
      // compliance
      natureOFCompliance: null,
      causeAnalysis: null,
      mnfDetails: null,
      SSDetails: null,
      labDetails: null,
      invDetails: null,
      illness_injury_risk_bool: null,
      manufacture_investigation_bool: null,
      coa_review_bool: null,
      sample_sensory_bool: null,
      lab_test_bool: null,
      investigation_extend_bool: null,
      recall_proposed_bool: null,
      product_return_bool: null,
      illness_injury_risk: null,
      manufacture_investigation: null,
      coa_review: null,
      sample_sensory: null,
      lab_test: null,
      investigation_extend: null,
      recall_proposed: null,
      product_return: null,
      compliance: false,
      // last section
      check_refund: 'No',
      checkAmount: null,
      charge_processing_fee: null,
      accounting: [],
      checkRefundBool: null,
      processingFeeBoll: null,
      checkAmountACC: null,
      checkNumberACC: '',
      checkDate: '',
      acc_processed: null,
      processedDate: '',
      accProcessedBoll: null,
      forACCSection: false,
      accountingSection: false,
      accountingID: null,
      check_country: '',
      check_address: '',
      check_city: '',
      check_state: '',
      check_zip: '',
      subStatus: '',
      CSRLead: false,
      maxDateValue: null,
      subStatusShow: false,
      returned_from: ''
    };
  },
  mounted() {
    this.getUserName()
    this.getLabelInfoID()
    this.getCompilance()
    this.setMaxDate()
    this.getData()
  },
  methods: {
    getData() {
      axios.get(`api/v1/local/exception/${this.$route.params.id}`).then(res => {
        this.axios_data = res.data[0]
        this.table_data = res.data
        const DATA = this.axios_data
        this.type_id = DATA.exception_type_id
        this.cMenu = DATA;
        this.product_info = DATA.product_info;
        this.ID_exc = DATA.id
        this.dist_name_1 = DATA.dist_name_1
        this.comment_info = DATA.em_comments;
        this.order_number = DATA.order_number
        this.date_created = DATA.record_date;
        this.current_status = DATA.status
        this.status_id = DATA.status_id
        this.payment_email = DATA.payment_email
        this.originalRows = {};
        // shipping address
        this.current_country = DATA.country
        this.current_address = DATA.street_address
        this.current_city = DATA.city
        this.current_state = DATA.state
        this.current_zip = DATA.zip_code
        // shipping box end
        this.exc_type = DATA.exception_message
        this.exc_action = DATA.action_name
        this.selectedAction = DATA.action_id
        this.current_date = DATA.date_created
        // pickup box
        this.return_label = DATA.return_label_info
        this.phone = DATA.phone_number
        this.e_mail = DATA.email
        this.PUcountry = DATA.pickup_country
        this.PUaddress = DATA.pickup_street_address
        this.PUcity = DATA.pickup_city
        this.PUstate = DATA.pickup_state
        this.PUzip = DATA.pickup_zip_code
        this.loading = false
        this.charge_amount = DATA.charge_amount
        this.storage_conditions = DATA.storage_conditions || ''
        // canada date
        const now_date = new Date().toISOString().slice(0,10)
        this.usaTime = now_date.toLocaleString("en-US", {timeZone: "America/Vancouver"});

        if (DATA.accounting[0] !== undefined) (this.populateDataAccounting(DATA))
        if (DATA.product_compliance[0] !== undefined) (this.populateComplianceData(DATA))

        this.getDepartmentAll(DATA)
        this.removeEmpty(DATA)
        this.selectedBtn(DATA)
        this.showUploads(DATA)
        this.render(DATA)
        this.getStatusAll(DATA)
        this.removeSpinner()
      })
    },
    getCompilance() {
      axios.get(`api/v1/local/product_compliance/${this.$route.params.id}`).then(res => {
        this.product_complianceID = res.data[0].id
      });
    },
    returnLabelInfoHide(DATA) {
      if (DATA.return_label_info.length === 0) {
        this.sectionUpdate = false
        this.returnLabelShow = false
      }
    },
    checkAmountShow() {
      if(this.charge_amount !== null) (this.charge_amount_show = true)
    },
    render(DATA) {
      const role = this.role
      switch (role) {
        case 'csr':
          this.renderForCSR(DATA)
          break;
        case 'csrlead':
          this.renderForCSRLead(DATA)
          this.complianceGetTicket(DATA)
          this.visibilityAccSection(DATA)
          this.returnLabelInfoHide(DATA)
          this.checkAmountShow()
          this.removeStatusOpen(DATA)
          break;
        case 'logistics':
          this.renderForLog(DATA)
          break;
        case 'accounting':
          this.renderForAcc(DATA)
          break;
        case 'compliance':
          this.renderForCompliance(DATA)
          this.setStatusCompilance(DATA)
          break;
      }
    },
    renderForCSR(DATA) {
      const actionID = DATA.action_id
      switch(actionID) {
        case 1:
          this.charge_amount_show = false
          this.returnLabelShow = false
          this.sectionUpdate = false
          break;
        case 2:
          this.quantyForReturn = false
          this.returnItemShow = false
          this.returnLabelShow = false
          this.showPayment = true
          this.orderTableTitle = 'Order Details'
          this.charge_amount_show = false
          this.sectionUpdate = false
        break;
        case 3:
          this.quantyForReturn = false
          this.releaseTableShow = true
          this.returnItemShow = false
          this.returnLabelShow = false
          this.shipping = true
          this.showPayment = true
          this.orderTableTitle = 'Order Details'
          this.charge_amount_show = false
          this.sectionUpdate = false
        break;
        case 4:
          this.releaseTableShow = true
          this.shipping = true
          this.returnLabelShow = false
          this.charge_amount_show = false
          this.sectionUpdate = false
        break;
      }
    },
    renderForCSRLead(DATA) {
      const actionID = DATA.action_id
      switch(actionID) {
        case 1:
          this.charge_amount_show = false
          this.sectionUpdate = true
          this.compliance = true
          this.CSRLead = true
          this.forACCSection = true
          this.accountingSection = true
          this.subStatusShow = true
          break;
        case 2:
          this.quantyForReturn = false
          this.returnItemShow = false
          this.returnLabelShow = false
          this.showPayment = true
          this.orderTableTitle = 'Order Details'
          this.sectionUpdate = false
          this.compliance = true
          this.CSRLead = true
          this.subStatusShow = true
          this.charge_amount_show = false
        break;
        case 3:
          this.quantyForReturn = false
          this.releaseTableShow = true
          this.returnItemShow = false
          this.returnLabelShow = false
          this.shipping = true
          this.showPayment = true
          this.orderTableTitle = 'Order Details'
          this.sectionUpdate = false
          this.compliance = true
          this.CSRLead = true
          this.subStatusShow = true
          this.charge_amount_show = false
        break;
        case 4:
          this.releaseTableShow = true
          this.shipping = true
          this.charge_amount_show = false
          this.compliance = true
          this.CSRLead = true
          this.subStatusShow = true
        break;
      }
    },
    renderForAcc(DATA) {
      const actionID = DATA.action_id
      switch(actionID) {
        case 1:
          this.forACCSection = true
          this.accountingSection = true
          this.charge_amount_show = false
          break;
        case 2:
          this.quantyForReturn = false
          this.returnItemShow = false
          this.returnLabelShow = false
          this.showPayment = true
          this.orderTableTitle = 'Order Details'
          this.sectionUpdate = false
        break;
        case 3:
          this.quantyForReturn = false
          this.releaseTableShow = true
          this.returnItemShow = false
          this.returnLabelShow = false
          this.shipping = true
          this.showPayment = true
          this.orderTableTitle = 'Order Details'
          this.sectionUpdate = false
        break;
        case 4:
          this.releaseTableShow = true
          this.shipping = true
          this.charge_amount_show = false
        break;
      }
    },
    renderForLog(DATA) {
      const actionID = DATA.action_id
      switch(actionID) {
        case 1:
          this.forACCSection = true
          this.charge_amount_show = false
          break;
        case 2:
          this.quantyForReturn = false
          this.returnItemShow = false
          this.returnLabelShow = false
          this.showPayment = true
          this.orderTableTitle = 'Order Details'
        break;
        case 3:
          this.quantyForReturn = false
          this.releaseTableShow = true
          this.returnItemShow = false
          this.returnLabelShow = false
          this.shipping = true
          this.showPayment = true
          this.orderTableTitle = 'Order Details'
        break;
        case 4:
          this.releaseTableShow = true
          this.shipping = true
          this.charge_amount_show = false
        break;
      }
    },
    renderForCompliance(DATA) {
      const actionID = DATA.action_id
      switch(actionID) {
        case 1:
          this.compliance = true
          this.charge_amount_show = false
          this.returnLabelShow = false
          this.sectionUpdate = false
          break;
        case 2:
          this.quantyForReturn = false
          this.returnItemShow = false
          this.returnLabelShow = false
          this.showPayment = false
          this.orderTableTitle = 'Order Details'
          this.sectionUpdate = false
          this.compliance = true
          this.charge_amount_show = false
        break;
        case 3:
          this.quantyForReturn = false
          this.releaseTableShow = true
          this.returnItemShow = false
          this.returnLabelShow = false
          this.shipping = false
          this.showPayment = false
          this.orderTableTitle = 'Order Details'
          this.sectionUpdate = false
          this.compliance = true
          this.charge_amount_show = false
        break;
        case 4:
          this.releaseTableShow = true
          this.shipping = false
          this.charge_amount_show = false
          this.compliance = true
          this.returnLabelShow = false
          this.sectionUpdate = false
        break;
      }
    },
    setMainStatus() {
      if (this.role === 'compliance') {
        this.status_id = this.axios_data.status_id
      } else {
        this.status_id = this.status_id
      }
      return this.status_id
    },
    removeEmpty(DATA) {
      for (let index = 0; index < DATA.product_info.length; index++) {
        const element = DATA.product_info[index];
        element.sku_id !== null ? this.product_infoRE.push(element) : this.product_infoRELESE.push(element)
      }
    },
    // split ticket for compliance
    splitTicket() {
      if (this.role === 'csrlead' && this.status_id === 1 && (this.type_id === 19 || this.type_id === 20)) {
        this.sub_status = this.status_id
        this.sub_department = "compliance"
      } else if (this.role === 'compliance') {
        this.sub_status = this.status_id
        this.sub_department = "compliance"
      }
    },
    prepareComplianceJSON() {
      let prod_com = {
        exception_id: this.ID_exc,
        batch_lot_number: this.batch_lot_number,
        received_date: this.received_date,
        compliant_nature: this.natureOFCompliance,
        cause_analysis: this.causeAnalysis,
        illness_injury_risk: this.illness_injury_risk_bool,
        manufacture_investigation: this.manufacture_investigation_bool,
        manufacture_investigation_details: this.mnfDetails,
        coa_review: this.coa_review_bool,
        sample_sensory: this.sample_sensory_bool,
        sample_sensory_details: this.SSDetails,
        lab_test: this.lab_test_bool,
        lab_test_details: this.labDetails,
        investigation_extend: this.investigation_extend_bool,
        investigation_extend_details: this.invDetails,
        recall_proposed: this.recall_proposed_bool,
        product_return: this.product_return_bool,
        sub_status: this.sub_status,
        sub_department: this.sub_department,
        sub_returned_from: ""
      }
      this.product_compliance.push(prod_com);
    },
    submitCompliance(json) {
      axios.put(`api/v1/local/product_compliance/update/${this.product_complianceID}`, json)
    },
    prepareJSON(department, statusID) {
      const datas = [
        {
          order_number: this.axios_data.order_number,
          order_date: this.axios_data.order_date,
          dist_id: this.axios_data.dist_id,
          dist_name_1: this.axios_data.dist_name_1,
          dist_name_2: this.axios_data.dist_name_2,
          iso2: this.axios_data.iso2,
          iso3: this.axios_data.iso3,
          exception_type: this.axios_data.exception_type_id,
          status: statusID,
          created_by: this.axios_data.created_by,
          date_created: this.axios_data.date_created,
          action_id: this.selectedAction,
          return_item_option: this.return_item_option,
          email: this.e_mail,
          phone_number: this.phone,
          pickup_country: this.PUcountry,    
          pickup_street_address: this.PUaddress,    
          pickup_city: this.PUcity,  
          pickup_state: this.PUstate,  
          pickup_zip_code: this.PUzip,
          country: this.current_country,
          street_address: this.current_address,
          city: this.current_city,
          state: this.current_state,
          zip_code: this.current_zip,
          payment_email: this.payment_email,
          department: department,
          charge_amount: parseFloat(this.charge_amount),
          returned_from: this.returned_from
        }
      ];
      return datas
    },
    myUploader(event){
      document.body.style.cursor = 'wait'
      let uploadedFiles = 0
      for (let i = 0; i < event.files.length; i++) {
        let element = event.files[i]
        let files = element
        let data = new FormData();
        data.append('files', files);
        this.imageUpload(data).then((res) => {
          if(res.code === 200){
            let tmp = {
              "exception_id": this.ID_exc,
              "path": res.path[0]
            }
            this.uploads.push(tmp)
            uploadedFiles++
          }
          if (event.files.length === uploadedFiles) {
            this.submiting(this.uploads)
          }
        })
      }      
    },
    submiting(obj) {
      document.body.style.cursor = 'wait'
      // accounting section
      this.prepareAccJSON()
      this.getAccounting(this.accounting)

      this.prepareData()
      const department = this.ticketMaps()
      // split ticket
      this.splitTicket()
      // get main status ID
      const statusID = this.setMainStatus()

      const datas = this.prepareJSON(department, statusID)

      // Update comments if not empty
      if (this.new_comment_info.length > 0) (this.uploadComment())

      // Update file if not empty
      if (obj.length > 0) (this.uploadFiles(obj))

      // Update label info
      this.updateLabelInfo()
      
      // prepare JSON for compliance
      this.prepareComplianceJSON()
      // update compilance 
      if (this.role === 'compliance' || this.role === 'csrlead') (this.submitCompliance(this.product_compliance))
      
      // Update this exception
      axios.put(`api/v1/local/exception/update/${this.ID_exc}`, datas).then(res => {
        const RESPONSE = res.data[0]
        if (RESPONSE.code === 400) {
          this.showToast('Exception NOT Updated', RESPONSE.message, 'danger', 2500)  
          document.body.style.cursor = 'default'
        } else {
          this.showToast('Successfully', 'Exception Updated', 'success', 1200)  
          document.body.style.cursor = 'default'
          this.back()
        }
      })
    }
  }
}
</script>
