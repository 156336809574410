<template>
    <div>
        <spinnerHtml />
        <div v-if="!spinner">
            <div class="p-grid">
                <backBtn />
                <div class="col-6 mt-1">
                    <Button class="mt-2 float-right w-20" label="Download as PDF" @click="downloadPDF()" />
                </div>
            </div>
            <div id="content" ref="printMe">
                <mainSection :propsData="this.axios_data" :propsMainData="this.table_data" />
                <exceptionType :propsData="this.axios_data" />
                <productTables :propsData="this.axios_data" />
                <customerRedirect :propsData="this.axios_data" />
                <div class="col-9 mt-4 p-0">
                    <status :propsData="this.axios_data" disabled />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import jsPDF from 'jspdf';

// mixins
import spinnerJS from '../../../store/spinner.js';
import downloadPDF from '../../../store/downloadPDF.js';

// HTML component
import spinnerHtml from '../../html/spinner.vue';
import backBtn from '../../html/back_button.vue';

// HTML section
import mainSection from '../html_part/main_section.vue';
import exceptionType from '../html_part/exception_type.vue';
import productTables from '../html_part/product_tables.vue';
import shipping from '../html_part/shipping.vue';
import paymentEmail from '../html_part/payment_email.vue';
import accounting from '../html_part/accounting.vue';
import returnItemOptions from '../html_part/return_item_options.vue';
import returnLableTable from '../html_part/return_label_table.vue';
import chargeAmount from '../html_part/charge_amount.vue';
import customerRedirect from '../html_part/customer_redirect.vue';
import status from '../html_part/status.vue';

export default {
    mixins: [spinnerJS, downloadPDF],
    components: {
      spinnerHtml,
      backBtn,
      mainSection,
      exceptionType,
      productTables,
      shipping,
      paymentEmail,
      accounting,
      returnItemOptions,
      returnLableTable,
      chargeAmount,
      customerRedirect,
      status
    },
    data() {
      return {
        axios_data: [],
        table_data: [],
        output: null,
        pdf_name: ''
      };
    },
    mounted() {
      this.getData();
    },
    methods: {
        getData() {
          axios.get(`api/v1/local/exception/${this.$route.params.id}`).then(res => {
            this.table_data = res.data;
            this.axios_data = res.data[0];
            this.pdf_name = 
              `${this.axios_data.exception_message} - ${this.axios_data.action_name}-${new Date().getTime()}`;
            this.removeSpinner();
          });
        }
    }
};
</script>
