<template>
    <div>
        <div class="row">
            <div class="col-12 pb-3">
                <router-link to="/crud/type/create">
                    <Button label="Add new Type" class="mr-3 custom-btn" />
                </router-link>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <DataTable
                        :value="type"
                        class="p-datatable-responsive" 
                        dataKey="id"
                        :paginator="true" 
                        :rows="25"
                        :loading="spinner"
                        :autoLayout="true"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" 
                        :rowsPerPageOptions="[10,25,50,75]"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" >
                    <template #header>Type List</template>
                    <Column field="id" header="Type ID" />
                    <Column field="inquiry_type" header="Title" />
                    <Column field="inquiry_status" header="Status">
                        <template #body="slotProps">{{ slotProps.data.inquiry_status === true ? 'Active' : 'Inactive' }}</template>
                    </Column>
                    <Column headerStyle="width: 8em">
                        <template #header>#</template>
                        <template #body="slotProps">
                            <router-link :to="'/crud/type/edit/' + slotProps.data.id">
                                <Button 
                                    type="button" 
                                    icon="pi pi-pencil" 
                                    class="center-icon p-1 mr-1" />
                            </router-link>
                            <Button 
                                type="button" 
                                icon="pi pi-trash" 
                                class="center-icon p-1" 
                                @click="deleteMethod(slotProps.data.id)" />
                        </template>
                    </Column>
                    <template #empty>
                        No records found.
                    </template>
                </DataTable>
                <ConfirmDialog></ConfirmDialog>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            type: [],
            spinner: true
        }
    },
    mounted() {
        this.populateMenuTable()
    },
    methods: {
        populateMenuTable() {
            axios.get(`api/v1/inquiry-type/read`).then(res => {
                this.type = res.data
                this.spinner = false
            })
        },
        deleteMethod(id) {
            this.$confirm.require({
                message: 'Are you sure you want to proceed?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Delete',
                rejectLabel: 'Cancel',
                accept: () => {
                    axios.delete(`api/v1/inquiry-type/delete-by-id`, {
                        params: {
                            id: id
                        }
                    }).then(res => {
                        if (res.status === 200) {
                            this.populateMenuTable()
                        }
                    })
                }
            });
        }
    }
}
</script>

<style>
    .custom-btn {
        width: 150px !important;
    }
    .p-dialog-footer {
        padding: 0 !important;
        margin-top: 10px !important;
    }
</style>