<template>
  <div>
    <spinnerHtml />
    <div v-if="!spinner">
      <div class="p-grid">
        <backBtn />
        <div class="p-col-6 align-end" v-if="this.axios_data.returned_from !== ''">
          <router-link
            :to="{ path: '/exception/view/ticket/' + this.ID_exc, query:{exType: this.exception_type_id} }" target="_blank">
            <Button label="View All Sections" type="button" icon="pi pi-search" iconPos="right" class="center mr-2 mt-1" style="width:170px"></Button>
          </router-link>
        </div>
      </div>
      <ContextMenu :model="menuModel" ref="cm" />
      <div class="p-grid">
        <div class="p-col-6">
          <div class="card card-w-title border-none">
            <DataTable :value="table_data" class="p-datatable-responsive" :rows="20" contextMenu :contextMenuSelection.sync="cMenu" @row-contextmenu="onRowContextMenu" :lazy="true" :loading="loading">
              <template #header>Transaction Details</template>
              <Column field="order_number" v-model="order_number" header="Order Number"></Column>
              <Column field="order_date" v-model="order_date" header="Order Date"></Column>
              <Column field="dist_id" v-model="dist_id" header="Distributor ID"></Column>
              <Column field="dist_name_1" v-model="dist_name_1" header="Distributor’s Name"></Column>
              <Column field="country" v-model="country" header="Country"></Column>
            </DataTable>
          </div>
        </div>
        <div class="p-col-6">
          <Accordion class="card card-w-title border-none p-field p-col-12 p-md-12 accordion-custom">
            <AccordionTab header="Add New Comment">
              <div class="p-datatable card card-w-title border-none p-field p-col-12 p-md-12 float-r" >
                <h4 class="p-datatable-header">Reason/Comment</h4>
                <Textarea id="txt" :value="value" :autoResize="true" rows="5" cols="30" class="w-inherit" />
              </div>
              <Button label="Save" class="m-2 float-right" @click="saveComment"/>
              <div class="card card-w-title border-none p-field p-col-12 p-md-12">
                <DataTable class="comment_table" :value="new_comment_info_table">
                  <template #header>Reason/Comment Preview</template>
                  <Column field="comment_date" header="Date"></Column>
                  <Column field="comment_user" header="User"></Column>
                  <Column field="comment_body" header="Comment"></Column>
                  <Column bodyStyle="text-align: center" >
                    <template #header></template>
                    <template #body="slotProps">
                      <Button type="button" icon="pi pi-trash" :slotProps="slotProps" @click="deleteComment(slotProps.data.row_id)" class="center"></Button>
                    </template>
                  </Column>
                  <template #empty>
                    No new comments
                  </template>
                </DataTable>
              </div>
            </AccordionTab>
          </Accordion>
          <Accordion class="card card-w-title border-none p-field p-col-12 p-md-12 accordion-custom" v-if="comm">
            <AccordionTab header="Comment History">
                <DataTable class="comment_table" :value="comment_info" sortField="id" :sortOrder="-1">
                  <Column field="comment_date" sortable header="Date"></Column>
                  <Column field="comment_user" sortable header="User"></Column>
                  <Column field="comment_body" sortable header="Comment"></Column>
                </DataTable>
            </AccordionTab>
          </Accordion>
          <Accordion class="card card-w-title border-none p-field p-col-12 p-md-12 accordion-custom" v-if="filesForDownLoad">
            <AccordionTab header="Uploaded Files">
                <ul class="p-0" v-for="dwn in dwn_link" :key="dwn.paths">
                  <a :href="dwn.path">{{dwn.path.split("/")[5]}}</a>
                </ul>
            </AccordionTab>
          </Accordion>
          <div class="card card-w-title border-none p-field p-col-12 p-md-12">
            <FileUpload 
            name="files" 
            chooseLabel="Upload" 
            :multiple="true"
            :customUpload="true"
            @uploader="myUploader"
            accept=".csv, .xlx, .xlsx, .pdf, .doc, .txt, .jpg, .jpeg, .png, .docx, .PNG, .JPG, .JPEG" 
            :maxFileSize="10000000" >
            <template #empty>
              <p>Drag and drop files here to upload.</p>
            </template>
          </FileUpload>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="row mb-1 mt-3">
          <div class="col-4 d-inline-block">
            <h6>Exception Type</h6>
            <InputText class="col-12" type="text" v-model="exc_type" disabled />
          </div>
          <div class="col-4 d-inline-block">
            <h6>Action</h6>
            <InputText class="col-12" type="text" v-model="exc_action" disabled />
            </div>
          <div class="col-4 d-inline-block">
            <h6>Date</h6>
            <Calendar id="icon" v-model="current_date" :showIcon="true" dateFormat="dd/mm/yy" disabled />
          </div>
        </div>
      </div>
      <hr>
      <div>
        <div class="card col-6 d-inline-block">
          <DataTable :value="product_info" class="editable-cells-table order-details">
            <template #header>Order Details</template>
            <Column field="sku_id" header="Item ID"></Column>
            <Column field="sku_name" header="Item Name"></Column>
            <Column field="sku_quantity" header="Qty"></Column>
          </DataTable>
        </div>
      </div>
      <div class="col-sm-3 mt-3" v-if="this.selectedAction === 12">
        <label for="e_mail" class="d-block">Email for Payment</label>
        <InputText class="col-sm-12 mb-2" id="e_mail" v-model="e_mail" placeholder="payment@email.com" @change="emailChecker"/>
        <small class="row col-sm-12 text-danger d-block" v-if="error">E mail address is not correct!</small>
      </div>
      <!-- shipping  -->
      <div>
        <div class="shipping-box hr" v-if="this.selectedAction === 13">
          <h6 class="col-sm-12">Shipping Address:</h6>
          <div class="col-sm-3 d-inline-block">
            <p>Country/Region:</p>
            <InputText class="col-sm-12" v-model="current_country"/>
          </div>
          <div class="col-sm-3 d-inline-block">
            <p>Street Address:</p>
            <InputText class="col-sm-12" v-model="current_address"/>
          </div>
          <div class="col-sm-2 d-inline-block">
            <p>City:</p>
            <InputText class="col-sm-12" v-model="current_city"/>
          </div>
          <div class="col-sm-2 d-inline-block">
            <p>State/Province:</p>
            <InputText class="col-sm-12" v-model="current_state"/>
          </div>
          <div class="col-sm-2 d-inline-block">
            <p>Zip/Postal Code:</p>
            <InputText class="col-sm-12" v-model="current_zip"/>
          </div>
        </div>
      </div>
      <!-- new shipping section  -->
      <div class="shipping-box my-4 hr" v-if="this.selectedAction === 12">
        <h6 class="col-sm-12">New Shipping Address:</h6>
        <div class="col-sm-3 d-inline-block mb-2">
          <p>Country/Region:</p>
          <InputText class="col-sm-12" v-model="new_country" />
        </div>
        <div class="col-sm-3 d-inline-block">
          <p>Street Address:</p>
          <InputText class="col-sm-12" v-model="new_address" />
        </div>
        <div class="col-sm-2 d-inline-block">
          <p>City:</p>
          <InputText class="col-sm-12" v-model="new_city" />
        </div>
        <div class="col-sm-2 d-inline-block">
          <p>State/Province:</p>
          <InputText class="col-sm-12" v-model="new_state" />
        </div>
        <div class="col-sm-2 d-inline-block">
          <p>Zip/Postal Code:</p>
          <InputText class="col-sm-12" v-model="new_zip" />
        </div>
      </div>
      <div class="hr" v-if="this.selectedAction === 13">
        <h6 class="col-sm-12">Customer's Information</h6>
        <div class="col-sm-2 d-inline-block align-bottom mb-2">
          <label for="phone" class="d-block">Phone</label>
          <InputMask class="col-sm-12" mask="(999) 9999-999" v-model="phone" placeholder="(999) 9999-999" @change="checkPhone"/>
        </div>
        <div class="col-sm-2 d-inline-block align-bottom mb-2">
          <label for="e_mail" class="d-block">E-mail</label>
          <InputText class="col-sm-12" id="e_mail" v-model="e_mail" placeholder="max@max.com" @change="emailChecker"/>
          <small class="text-danger d-block position-absolute" v-if="error">E mail address is not correct!</small>
        </div>
      </div>
      <hr v-if="this.role === 'csr'">
      <div class="col-12 my-4 hr" v-if="this.role !== 'csr'">
        <h5>Shipping And Returns</h5>
      </div>

      <div v-if="this.selectedAction === 12 && this.role !== 'csr'">
        <div class="col-sm-3 d-inline-block mb-4">
          <p>Waive intercept fee</p>
          <SelectButton v-model="intercept" :options="yesNo" @input="onInterceptChanged"/>
        </div>
        <div class="col-sm-3 d-inline-block v-top" v-if="this.intercept === 'No'">
          <p>Intercept Amount</p>
          <InputText class="col-sm-12" v-model="intercept_amt" @input="checkAmounts($event)"/>
        </div>
      </div>

      <div v-if="this.selectedAction === 13 && this.role !== 'csr'">
        <div class="shipping-box my-4">
          <h6 class="col-sm-12 mb-3">Pick up Location Information</h6>
          <div class="col-sm-3 d-inline-block mb-2">
            <p>UPS facility name</p>
            <InputText class="col-sm-12" v-model="ups_name" />
          </div>
          <div class="col-sm-3 d-inline-block">
            <p>Street Address:</p>
            <InputText class="col-sm-12" v-model="ups_address" />
          </div>
          <div class="col-sm-2 d-inline-block">
            <p>City:</p>
            <InputText class="col-sm-12" v-model="ups_city" />
          </div>
          <div class="col-sm-2 d-inline-block">
            <p>State/Province:</p>
            <InputText class="col-sm-12" v-model="ups_state" />
          </div>
          <div class="col-2 d-inline-block">
            <p>Package Available Date</p>
            <Calendar v-model="available" :showIcon="true" :manualInput="false" dateFormat="yy-mm-dd" @date-select="formatCalendarDate($event, calendar = 'available')"/>
          </div>

          <div class="col-sm-3 d-inline-block mt-3">
            <label for="phone" class="d-block">Phone</label>
            <InputMask class="col-sm-12" mask="(999) 9999-999" v-model="ups_phone" placeholder="(999) 9999-999" @change="checkPhone"/>
          </div>
          <div class="col-sm-6 d-inline-block">
            <p>Hours of operation</p>
            <InputText class="col-sm-12" v-model="ups_hours" />
          </div>
          <div class="col-2 offset-1 d-inline-block">
            <p>Pickup by Date</p>
            <Calendar v-model="pickup_date" :showIcon="true" :manualInput="false" :minDate="maxDateValue" dateFormat="yy-mm-dd" @date-select="formatCalendarDate($event, calendar = 'pickup_date')" :disabled="availableDatePickup"/>
          </div>
        </div>
      </div>

      <div class="col-9 p-0">
        <div class="col-4">
          <p>Status</p>
          <Dropdown class="col-12" v-model="selectedStatus" :options="statusDropdown" optionLabel="status_value" :placeholder="current_status" @change="onStatusChange"/>
        </div>
        <div class="col-4 mt-3" v-if="reassigned">
          <p>Assing To:</p>
          <Dropdown class="col-12" v-model="assingToDepartment" :options="reassingMap" :placeholder="reassing" optionLabel="assing_value" @change="assingToChange"/>
        </div>
      </div>
      <div class="col-12">
        <Button class="float-right" label="Submit" @click="submitForm"/>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Vue from 'vue';
// import methods from mixins
import getAndSet from '../../../store/getAndSet.js'
import reassingMap from '../../../store/reassingMaping.js'
import ticketFlow from '../../../store/ticketFlow.js'
import onSubmitActions from '../../../store/onSubmitActions.js'
import selectedButton from '../../../store/selectedButton.js'
import mainMethods from '../../../store/mainMethods.js'
import customer from '../../../store/customer.js'
import spinnerJS from '../../../store/spinner.js'
// componenet
import spinnerHtml from '../../html/spinner.vue'
import backBtn from '../../html/back_button.vue'

export default {
  mixins: [mainMethods, getAndSet, reassingMap, ticketFlow, onSubmitActions, selectedButton, customer, spinnerJS],
  components: {
    spinnerHtml,
    backBtn
  },
  data() {
    return {
      department: null,
      role: null,
      assingTo: null,
      assingToDepartment: null,
      assing: [],
      reassigned: false,
      reassing: null,
      axios_data: [],
      table_data: [],
      exc_type: null,
      exc_action: null,
      selectedAction: null,
      loading: true,
      cMenu: {},
      product_info: [],
      comment_info: [],
      new_comment_info:[],
      new_comment_info_table: [],
      dwn_link: [],
      filesForDownLoad: false,
      files: [],
      current_status: null,
      value: null,
      date_created: null,
      order_date: null,
      order_number: null,
      dist_id: null,
      dist_name_1: null,
      country: null,
      comment_body: null,
      copyText: null,
      ticket: null,
      user: null,
      ID_exc: null,
      current_date : null,
      selectedStatus: null,
      statusDropdown: [],
      status_id: null,
      uploads: [],
      comm: false,
      menuModel: [
        {
          label: 'Copy',
          icon: 'pi pi-fw pi-copy',
          command: () => this.copy(this.table_data)
        }
      ],
      
      usaTime: null,
      sectionUpdate: true,
      current_country: '',
      current_address: '',
      current_city: '',
      current_state: '',
      current_zip: '',
      exception_type_id: 0,
      returned_from: '',
      newShipping: true,
      new_country: '',
      new_address: '',
      new_city: '',
      new_state: '',
      new_zip: '',
      customerJSON: [],
      intercept: 'Yes',
      intercept_amt: null,
      yesNo: ['Yes', 'No'],
      customerID: null,
      redirect_intercept_fee: null,
      phone: null,
      e_mail: null,
      error: false,
      ups_name: '',
      ups_address: '',
      ups_city: '',
      ups_state: '',
      available: null,
      pickup_date:null,
      ups_phone: '',
      ups_hours: '',
      maxDateValue: null,
      availableDatePickup: true
    };
  },
  mounted() {
    this.getUserName()
    this.setMaxDate()
    this.getData()
    this.getCustomerRedirectID(this.$route.params.id)
  },
  methods: {
    getData() {
      axios.get(`api/v1/local/exception/${this.$route.params.id}`).then(res => {
        this.axios_data = res.data[0]
        this.table_data = res.data
        const DATA = this.axios_data
        this.cMenu = DATA;
        this.product_info = DATA.product_info;
        this.ID_exc = DATA.id
        this.e_mail = DATA.email
        this.phone = DATA.phone_number
        this.exception_type_id = DATA.exception_type_id
        this.dist_name_1 = DATA.dist_name_1
        this.comment_info = DATA.em_comments;
        this.order_number = DATA.order_number
        this.date_created = DATA.record_date;
        this.current_status = DATA.status
        this.status_id = DATA.status_id
        this.intercept = DATA.intercept_fee
        this.exc_type = DATA.exception_message
        this.exc_action = DATA.action_name
        this.selectedAction = DATA.action_id
        this.current_date = DATA.date_created
        this.ticket = DATA.claim_ticket_number
        this.current_country = DATA.country
        this.current_address = DATA.street_address
        this.current_city = DATA.city
        this.current_state = DATA.state
        this.current_zip = DATA.zip_code
        this.originalRows = {}
        this.loading = false;
        // new shipping address
        if (DATA.customer_redirect[0] !== undefined) (this.populateDataCustomer(DATA))
        // canada date
        const now_date = new Date().toISOString().slice(0,10)
        this.usaTime = now_date.toLocaleString("en-US", {timeZone: "America/Vancouver"});
        this.selectedBtn(DATA)
        this.showUploads(DATA)
        this.renderElByRole(DATA)
        this.getDepartmentAll(DATA)
        this.getStatusAll(DATA)
        this.removeSpinner()
      });
    },
    renderElByRole(DATA) {
      switch (this.role) {
        case "csr":
          this.sectionUpdate = false
          break;
      }
    },
    onInterceptChanged(event) {
      event == "Yes" ? this.redirect_intercept_fee = true : this.redirect_intercept_fee = false
    },
    prepareJSON(department) {
      const datas = [
        {
          order_number: this.axios_data.order_number,
          order_date: this.axios_data.order_date,
          dist_id: this.axios_data.dist_id,
          dist_name_1: this.axios_data.dist_name_1,
          dist_name_2: this.axios_data.dist_name_2,
          iso2: this.axios_data.iso2,
          iso3: this.axios_data.iso3,
          exception_type: this.axios_data.exception_type_id,
          status: this.status_id,
          created_by: this.axios_data.created_by,
          date_created: this.axios_data.date_created,
          claim_ticket_number: this.ticket,
          action_id: this.selectedAction,
          return_item_option: this.return_item_option,
          email: this.e_mail,
          phone_number: this.phone,
          department: department,
          returned_from: this.returned_from,
          country: this.current_country,
          street_address: this.current_address,
          city: this.current_city,
          state: this.current_state,
          zip_code: this.current_zip
        }
      ];
      return datas
    },
    prepareCustomerRedirectJSON() {
      let customerJSON = {
        exception_id: this.ID_exc,
        redirect_country: this.new_country,
        redirect_street_address: this.new_address,
        redirect_city: this.new_city,
        redirect_state: this.new_state,
        redirect_zip_code: this.new_zip,
        redirect_intercept_fee: this.redirect_intercept_fee,
        redirect_intercept_amt: +this.intercept_amt,
        ups_name: this.ups_name,
        ups_street_address: this.ups_address,
        ups_city: this.ups_city,
        ups_state: this.ups_state,
        ups_phone_number: this.ups_phone,
        ups_operation_hours: this.ups_hours,
        package_available_date: this.available,
        pickup_by_date: this.pickup_date
      };
      this.customerJSON.push(customerJSON);

      return this.customerJSON
    },
    myUploader(event){
      document.body.style.cursor = 'wait'
      let uploadedFiles = 0
      for (let i = 0; i < event.files.length; i++) {
        let element = event.files[i]
        let files = element
        let data = new FormData();
        data.append('files', files);
        this.imageUpload(data).then((res) => {
          if(res.code === 200){
            let tmp = {
              "exception_id": this.ID_exc,
              "path": res.path[0]
            }
            this.uploads.push(tmp)
            uploadedFiles++
          }
          if (event.files.length === uploadedFiles) {
            this.submiting(this.uploads)
          }
        })
      }      
    },
    submiting(obj) {
      document.body.style.cursor = 'wait'
      // this.prepareData()
      const department = this.ticketMaps()
      const datas = this.prepareJSON(department)
      const customer = this.prepareCustomerRedirectJSON()
      // Update comments if not empty
      if (this.new_comment_info.length > 0) (this.uploadComment())

      // Update file if not empty
      if (obj.length > 0) (this.uploadFiles(obj))
      this.updateCustomerRedirect(this.customerID, customer)

      // Update this exception
      axios.put(`api/v1/local/exception/update/${this.ID_exc}`, datas).then(res => {
        const RESPONSE = res.data[0]
        if (RESPONSE.code === 400) {
          this.showToast('Exception NOT Updated', RESPONSE.message, 'danger', 2500)
          document.body.style.cursor = 'default'
        } else {
          this.showToast('Successfully', 'Exception Updated', 'success', 1200)
          document.body.style.cursor = 'default'
          this.back()
        }
      })
    }
  }
}

</script>
