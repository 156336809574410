<template>
  <div>
    <spinnerHtml />
    <div v-if="!spinner">
      <div class="p-grid">
        <backBtn />
        <div class="p-col-6 align-end" v-if="this.axios_data.returned_from !== ''">
          <router-link
            :to="{ path: '/exception/view/ticket/' + this.ID_exc, query:{exType: this.exception_type_id} }" target="_blank">
            <Button label="View All Sections" type="button" icon="pi pi-search" iconPos="right" class="center mr-2 mt-1" style="width:170px"></Button>
          </router-link>
        </div>
      </div>
      <ContextMenu :model="menuModel" ref="cm" />
      <div class="p-grid">
        <div class="p-col-6">
          <div class="card card-w-title border-none">
            <DataTable :value="table_data" class="p-datatable-responsive" :rows="20" contextMenu :contextMenuSelection.sync="cMenu" @row-contextmenu="onRowContextMenu" :lazy="true" :loading="loading">
              <template #header>Transaction Details</template>
              <Column field="order_number" v-model="order_number" header="Order Number"></Column>
              <Column field="order_date" v-model="order_date" header="Order Date"></Column>
              <Column field="dist_id" v-model="dist_id" header="Distributor ID"></Column>
              <Column field="dist_name_1" v-model="dist_name_1" header="Distributor’s Name"></Column>
              <Column field="country" v-model="country" header="Country"></Column>
            </DataTable>
          </div>
        </div>
        <div class="p-col-6">
          <Accordion class="card card-w-title border-none p-field p-col-12 p-md-12 accordion-custom">
            <AccordionTab header="Add New Comment">
              <div class="p-datatable card card-w-title border-none p-field p-col-12 p-md-12 float-r" >
                <h4 class="p-datatable-header">Reason/Comment</h4>
                <Textarea id="txt" :value="value" :autoResize="true" rows="5" cols="30" class="w-inherit" />
              </div>
              <Button label="Save" class="m-2 float-right" @click="saveComment"/>
              <div class="card card-w-title border-none p-field p-col-12 p-md-12">
                <DataTable class="comment_table" :value="new_comment_info_table">
                  <template #header>Reason/Comment Preview</template>
                  <Column field="comment_date" header="Date"></Column>
                  <Column field="comment_user" header="User"></Column>
                  <Column field="comment_body" header="Comment"></Column>
                  <Column bodyStyle="text-align: center" >
                    <template #header></template>
                    <template #body="slotProps">
                      <Button type="button" icon="pi pi-trash" :slotProps="slotProps" @click="deleteComment(slotProps.data.row_id)" class="center"></Button>
                    </template>
                  </Column>
                  <template #empty>
                    No new comments
                  </template>
                </DataTable>
              </div>
            </AccordionTab>
          </Accordion>
          <Accordion class="card card-w-title border-none p-field p-col-12 p-md-12 accordion-custom" v-if="comm">
            <AccordionTab header="Comment History">
                <DataTable class="comment_table" :value="comment_info" sortField="id" :sortOrder="-1">
                  <Column field="comment_date" sortable header="Date"></Column>
                  <Column field="comment_user" sortable header="User"></Column>
                  <Column field="comment_body" sortable header="Comment"></Column>
                </DataTable>
            </AccordionTab>
          </Accordion>
          <Accordion class="card card-w-title border-none p-field p-col-12 p-md-12 accordion-custom" v-if="filesForDownLoad">
            <AccordionTab header="Uploaded Files">
                <ul class="p-0" v-for="dwn in dwn_link" :key="dwn.paths">
                  <a :href="dwn.path">{{dwn.path.split("/")[5]}}</a>
                </ul>
            </AccordionTab>
          </Accordion>
          <div class="card card-w-title border-none p-field p-col-12 p-md-12">
            <FileUpload 
            name="files" 
            chooseLabel="Upload" 
            :multiple="true"
            :customUpload="true"
            @uploader="myUploader"
            accept=".csv, .xlx, .xlsx, .pdf, .doc, .txt, .jpg, .jpeg, .png, .docx, .PNG, .JPG, .JPEG" 
            :maxFileSize="10000000" >
            <template #empty>
              <p>Drag and drop files here to upload.</p>
            </template>
          </FileUpload>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="row mb-1 mt-3">
          <div class="col-4 d-inline-block">
            <h6>Exception Type</h6>
            <InputText class="col-12" type="text" v-model="exc_type" disabled />
          </div>
          <div class="col-4 d-inline-block">
            <h6>Action</h6>
            <InputText class="col-12" type="text" v-model="exc_action" disabled />
            </div>
          <div class="col-4 d-inline-block">
            <h6>Date</h6>
            <Calendar id="icon" v-model="current_date" :showIcon="true" dateFormat="dd/mm/yy" disabled />
          </div>
        </div>
      </div>
      <hr>
      <div>
        <div class="card col-6 d-inline-block">
          <DataTable :value="product_info" class="editable-cells-table order-details">
            <template #header>Order Details/Products for Return</template>
            <Column field="sku_id" header="Item ID"></Column>
            <Column field="sku_name" header="Item Name"></Column>
            <Column field="sku_quantity" header="Qty"></Column>
            <Column field="sku_quantity_return" header="Qty for Return"></Column>
          </DataTable>
        </div>
      </div>
      <div class="my-4">
        <p class="col-sm-12">Reason for cancellation</p>
        <div class="col-sm-4 mb-2">
          <InputText class="col-12" v-model="reason" disabled />
        </div>
        <div class="col-sm-4" v-if="otherReasonInput">
          <Textarea 
            class="col-12" 
            type="text" 
            v-model="otherReason" 
            :disabled="this.role !== 'csr'" 
            :autoResize="true" 
            rows="5" 
            cols="30" />
        </div>
      </div>
      <hr v-if="reasonForRio">
      <div v-if="reasonForRio">
        <div class="col-sm-3 d-inline-block">
          <h6>Return Item Options:</h6>
          <SelectButton v-model="rio" :options="rio_options" @input="rioChanged"/>
        </div>
        <div class="col-sm-3 d-inline-block align-top">
          <label for="e_mail">E-mail</label>
          <InputText class="col-sm-12" id="e_mail" v-model="e_mail" placeholder="max@max.com" />
        </div>
        <div class="col-sm-2 d-inline-block align-top">
          <label for="phone">Phone</label>
          <InputMask class="col-sm-12" mask="(999) 9999-999" v-model="phone" placeholder="(999) 9999-999" @change="checkPhone"/>
        </div>
      </div>
      <div class="my-3" v-if="pickUP">
        <h6 class="col-sm-12">Picking up Address:</h6>
        <div class="col-sm-3 d-inline-block mb-2">
          <p>Country/Region</p>
          <InputText class="col-sm-12" type="text" v-model="PUcountry" />
        </div>
        <div class="col-sm-3 d-inline-block">
          <p>Street Address</p>
          <InputText class="col-sm-12" type="text" v-model="PUaddress" />
        </div>
        <div class="col-sm-2 d-inline-block">
          <p>City</p>
          <InputText class="col-sm-12" type="text" v-model="PUcity" />
        </div>
        <div class="col-sm-2 d-inline-block">
          <p>State/Province</p>
          <InputText class="col-sm-12" type="text" v-model="PUstate" />
        </div>
        <div class="col-sm-2 d-inline-block">
          <p>Zip/Postal Code</p>
          <InputText class="col-sm-12" type="text" v-model="PUzip" />
        </div>
      </div>
      <hr>
      <div class="col-12 my-4" v-if="this.role !== 'csr'">
        <h5>Shipping And Returns</h5>
      </div>
      <div v-if="sectionUpdate">
        <div class="col-sm-3 d-inline-block mb-2">
          <p>Package has been shipped?</p>
          <SelectButton v-model="shipped" :options="yesNo" @input="onShippedChanged"/>
        </div>
        <div class="col-sm-3 d-inline-block v-top" v-if="shippeded">
          <p>Intercept Fee</p>
          <InputText class="col-sm-12" type="text" v-model="intercept" @change="replaceCommaToDot($event, field = 'intercept')"/>
        </div>
      </div>
      <div class="crud mb-4 mt-2" v-if="sectionUpdateTable">
        <div class="col-sm-6 d-inline-block">
          <DataTable :value="return_label" class="editable-cells-table" editMode="row" dataKey="id" :editingRows.sync="editingRows"
          @row-edit-init="onRowEditInit" @row-edit-cancel="onRowEditCancel">
            <template #header>
              <div class="table-header justify-content-between">
                <span>Return Label Table</span>
                <Button icon="pi pi-plus" @click="addNew" :disabled="isApprovedSelected"/>
              </div>
            </template>
            <Column field="rl_tracking_number" header="RL Tracking number">
              <template #editor="slotProps">
                <InputText v-model="slotProps.data[slotProps.column.field]" />
              </template>
            </Column>
            <Column field="rl_cost" header="RL Cost">
              <template #editor="slotProps">
                <InputText v-model="slotProps.data[slotProps.column.field]" />
              </template>
            </Column>
            <Column field="rl_weight" header="Weight">
              <template #editor="slotProps">
                <InputText v-model="slotProps.data[slotProps.column.field]" />
              </template>
            </Column>
            <template #empty>
                No records found.
            </template>
            <Column :rowEditor="true" headerStyle="width:7rem" bodyStyle="text-align:center"></Column>
          </DataTable>
          <!-- POP UP -->
          <Dialog :visible.sync="addNewReturnItem" :style="{width: '450px'}" header="Product Details" :modal="true" class="p-fluid">
            <div class="p-field">
              <label for="name">RL Tracking number</label>
              <InputText v-model="rl_number" />
            </div>
            <div class="p-field">
              <label for="description">RL Cost</label>
              <InputText v-model="rl_cost" @change="replaceCommaToDot($event, field = 'rl_cost')" />
            </div>
            <div class="p-field">
              <label for="description">Weight</label>
              <InputText v-model="rl_weight" @change="replaceCommaToDot($event, field = 'rl_weight')" />
            </div>
            <template #footer>
              <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
              <Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveProduct" />
            </template>
          </Dialog>
        </div>
        <div class="col-sm-2 d-inline-block v-top">
          <p>Is Approved:</p>
          <SelectButton v-model="approved" :options="yesNo" @input="approvedChanged" :disabled="isApprovedSelected"/>
        </div>
        <div class="col-sm-2 d-inline-block v-top">
          <p>Date Processed:</p>
          <Calendar id="icons" v-model="date_req" :showIcon="true" dateFormat="yyyy-mm-dd" @date-select="formatDate" :disabled="isApprovedSelected" :maxDate="maxDateValue" :manualInput="false"/>
        </div>
      </div>
      <!-- For Accounting  -->
      <div class="mt-4" v-if="forACCSection">
        <div class="col-12">
          <h6>For Accounting</h6>
          <div class="row col-12 mb-3">
            <div class="col-3 px-0">
              <p>Check Refund</p>
              <SelectButton v-model="check_refund" :options="yesNo" @input="accSelectedBtn($event, field = 'checkRefundBool')" />
            </div>
            <div class="col-3 pr-0" v-if="check_refund == 'Yes'">
              <p>Amount</p>
              <InputText class="col-12" v-model="checkAmount" @change="replaceCommaToDot($event, field = 'checkAmount')"/>
            </div>
          </div>
          <div class="row col-12 mb-3" v-if="check_refund == 'Yes'">
            <div class="col-3 px-0">
              <p>Charge Processing Fee</p>
              <SelectButton v-model="charge_processing_fee" :options="yesNo" @input="accSelectedBtn($event, field = 'processingFeeBoll')" />
            </div>
            <div class="col-3 pr-0">
              <p>Check Payee Name</p>
              <InputText class="col-12" v-model="dist_name_1" />
            </div>
          </div>
          <div class="row my-4" v-if="check_refund == 'Yes'">
            <h6 class="col-sm-12">Mailing Address:
              <Button icon="pi pi-copy" class="p-button" @click="copyToMailAddress" v-tooltip.top="'Copy address from shipping!'"/>
            </h6>
            <div class="col-sm-3 d-inline-block">
              <p>Country/Region:</p>
              <InputText class="col-sm-12" v-model="check_country" />
            </div>
            <div class="col-sm-3 d-inline-block">
              <p>Street Address:</p>
              <InputText class="col-sm-12" v-model="check_address" />
            </div>
            <div class="col-sm-2 d-inline-block">
              <p>City:</p>
              <InputText class="col-sm-12" v-model="check_city" />
            </div>
            <div class="col-sm-2 d-inline-block">
              <p>State/Province:</p>
              <InputText class="col-sm-12" v-model="check_state" />
            </div>
            <div class="col-sm-2 d-inline-block">
              <p>Zip/Postal Code:</p>
              <InputText class="col-sm-12" v-model="check_zip" />
            </div>
          </div>
        </div>
      </div>

      <!-- For Acc Section  -->
      <hr v-if="accountingSection">
      <div class="row" v-if="accountingSection">
        <div class="col-12">
          <h6 class="col-12">Accounting</h6>
          <div class="mb-3">
            <div class="col-3 d-inline-block">
              <p>Check Amount</p>
              <InputText class="col-12" v-model="checkAmountACC" @change="replaceCommaToDot($event, field = 'checkAmountACC')" />
            </div>
            <div class="col-3 d-inline-block">
              <p>Check Number</p>
              <InputText class="col-12" v-model="checkNumberACC" @input="subString($event, field = 'checkNumberACC')" />
            </div>
            <div class="col-3 d-inline-block">
              <p>Check Date</p>
              <Calendar id="checkDate" v-model="checkDate" :showIcon="true" :manualInput="false" dateFormat="yy-mm-dd" @date-select="formatCalendarDate($event, calendar = 'checkDate')"/>
            </div>
          </div>
          <div class="col-12 my-4">
            <div class="col-3 d-inline-block pl-0">
              <p>Processed by Accounting</p>
              <SelectButton v-model="acc_processed" :options="yesNo" @input="accSelectedBtn($event, field = 'accProcessedBoll')" />
            </div>
            <div class="col-3 d-inline-block align-top pl-1">
              <p>Accounting Date Processed</p>
              <Calendar id="processedDate" v-model="processedDate" :showIcon="true" :maxDate="maxDateValue" :manualInput="false" dateFormat="yy-mm-dd" @date-select="formatCalendarDate($event, calendar = 'processedDate')"/>
            </div>
          </div>
        </div>
      </div>
      <div class="col-9 p-0">
        <div class="col-4">
          <p>Status</p>
          <Dropdown class="col-12" v-model="selectedStatus" :options="statusDropdown" optionLabel="status_value" :placeholder="current_status" @change="onStatusChange"/>
        </div>
        <div class="col-4 mt-3" v-if="reassigned">
          <p>Assing To:</p>
          <Dropdown class="col-12" v-model="assingToDepartment" :options="reassingMap" :placeholder="reassing" optionLabel="assing_value" @change="assingToChange"/>
        </div>
      </div>
      <div class="col-12">
        <Button class="float-right" label="Submit" @click="submitForm"/>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Vue from 'vue';
// import methods from mixins
import getAndSet from '../../../store/getAndSet.js'
import reassingMap from '../../../store/reassingMaping.js'
import ticketFlow from '../../../store/ticketFlow.js'
import onSubmitActions from '../../../store/onSubmitActions.js'
import selectedButton from '../../../store/selectedButton.js'
import mainMethods from '../../../store/mainMethods.js'
import accounting from '../../../store/accounting.js'
import spinnerJS from '../../../store/spinner.js'
// componenet
import spinnerHtml from '../../html/spinner.vue'
import backBtn from '../../html/back_button.vue'

export default {
  mixins: [mainMethods, getAndSet, reassingMap, ticketFlow, onSubmitActions, selectedButton, accounting, spinnerJS],
  components: {
    spinnerHtml,
    backBtn
  },
  data() {
    return {
      department: null,
      role: null,
      assingTo: null,
      assingToDepartment: null,
      assing: [],
      reassigned: false,
      reassing: null,
      axios_data: [],
      table_data: [],
      exc_type: null,
      exc_action: null,
      selectedAction: null,
      loading: true,
      cMenu: {},
      product_info: [],
      comment_info: [],
      new_comment_info:[],
      new_comment_info_table: [],
      dwn_link: [],
      filesForDownLoad: false,
      files: [],
      current_status: null,
      value: null,
      date_created: null,
      order_date: null,
      order_number: null,
      dist_id: null,
      dist_name_1: null,
      country: null,
      comment_body: null,
      copyText: null,
      ticket: null,
      user: null,
      ID_exc: null,
      current_date : null,
      selectedStatus: null,
      statusDropdown: [],
      status_id: null,
      uploads: [],
      comm: false,
      menuModel: [
        {
          label: 'Copy',
          icon: 'pi pi-fw pi-copy',
          command: () => this.copy(this.table_data)
        }
      ],
      product_info: [],
      product_infoRE: [],
      phone: null,
      e_mail: null,
      PUcountry: null,
      PUaddress: null,
      PUcity: null,
      PUstate: null,
      PUzip: null,
      return_item_option: null,
      return_label: [],
      rl: [],
      rl2: [],
      rl_all: [],
      addNewReturnItem: false,
      submitted: false,
      rl_number: null,
      rl_cost: null,
      rl_weight: null,
      approved: null,
      shipped: '',
      yesNo: ['Yes', 'No'],
      rio_options: ['E-Label', '3 Pickup'],
      date_req: null,
      rio: null,
      approvedCH: null,
      pickUP: false,
      label_info_id:null,
      shippeded: null,
      intercept: null,
      reason: null,
      otherReasonInput: false,
      otherReason: null,
      
      // return label table
      isApprovedSelected: false,
      LABEL_DATA: null,
      editingRows: [],
      columns: null,
      forUpdate: [],
      bulkArray: [],
      reasonForRio: false,
      usaTime: null,
      sectionUpdate: true,
      sectionUpdateTable: false,
      // last section
      check_refund: 'No',
      checkAmount: null,
      charge_processing_fee: null,
      accounting: [],
      checkRefundBool: null,
      processingFeeBoll: null,
      checkAmountACC: null,
      checkNumberACC: '',
      checkDate: '',
      acc_processed: null,
      processedDate: '',
      accProcessedBoll: null,
      forACCSection: false,
      accountingSection: false,
      accountingID: null,
      check_country: '',
      check_address: '',
      check_city: '',
      check_state: '',
      check_zip: '',
      maxDateValue: null,
      exception_type_id: 0,
      returned_from: '',
      cancelationID: null,
      reasonID: null,
    };
  },
  mounted() {
    this.getUserName()
    this.getLabelInfoID()
    this.setMaxDate()
    this.getData()
    this.getReason()
  },
  methods: {
    getData() {
      axios.get(`api/v1/local/exception/${this.$route.params.id}`).then(res => {
        this.axios_data = res.data[0]
        this.table_data = res.data
        const DATA = this.axios_data
        this.cMenu = DATA;
        this.product_info = DATA.product_info;
        this.ID_exc = DATA.id
        this.exception_type_id = DATA.exception_type_id
        this.dist_name_1 = DATA.dist_name_1
        this.comment_info = DATA.em_comments;
        this.order_number = DATA.order_number
        this.date_created = DATA.record_date;
        this.current_status = DATA.status
        this.status_id = DATA.status_id
        this.intercept = DATA.intercept_fee
        this.exc_type = DATA.exception_message
        this.exc_action = DATA.action_name
        this.selectedAction = DATA.action_id
        this.current_date = DATA.date_created
        this.ticket = DATA.claim_ticket_number
        this.originalRows = {}
        // shipping address
        this.current_country = DATA.country
        this.current_address = DATA.street_address
        this.current_city = DATA.city
        this.current_state = DATA.state
        this.current_zip = DATA.zip_code
        // reasons
        if (DATA.reason_for_cancellation[0] !== undefined) (this.reason = DATA.reason_for_cancellation[0].reason_value)
        this.otherReason = DATA.reason_for_cancellation[0].value_other
        this.otherReasonInput = true
        this.reason === "Other" ? this.otherReasonInput = true : this.otherReasonInput = false
        const REASON = DATA.reason_for_cancellation[0].reason_id
        if (REASON === 2 || REASON === 4 || REASON === 6 || REASON === 8) {
          this.reasonForRio = true
        }
        // pickup box
        this.return_label = DATA.return_label_info
        this.phone = DATA.phone_number
        this.e_mail = DATA.email
        this.PUcountry = DATA.pickup_country
        this.PUaddress = DATA.pickup_street_address
        this.PUcity = DATA.pickup_city
        this.PUstate = DATA.pickup_state
        this.PUzip = DATA.pickup_zip_code
        this.loading = false;
        // canada date
        const now_date = new Date().toISOString().slice(0,10)
        this.usaTime = now_date.toLocaleString("en-US", {timeZone: "America/Vancouver"});
        // populate accounting data from accounting.js file
        if (DATA.accounting[0] !== undefined) (this.populateDataAccounting(DATA))
        this.selectedBtn(DATA)
        this.showUploads(DATA)
        this.renderElByRole(DATA)
        this.getDepartmentAll(DATA)
        this.getStatusAll(DATA)
        this.removeSpinner()
      });
    },
    getReason() {
      if (this.role === 'csr') {
        axios.get(`api/v1/local/cancellation/by/exceptions/${this.$route.params.id}`).then(res => {
          this.cancelationID = res.data[0].id
          this.reasonID = res.data[0].reason_id
        })
      }
    },
    renderElByRole(DATA) {
      switch (this.role) {
        case "csr":
          this.sectionUpdate = false
          break;
        case "csrlead":
          this.forACCSection = true
          this.accountingSection = true
          this.sectionUpdateTable = true
          break;
        case "logistics":
          this.forACCSection = true
          const REASON = DATA.reason_for_cancellation[0].reason_id
          if (REASON === 2 || REASON === 4 || REASON === 6 || REASON === 8) {
            this.sectionUpdateTable = true
          }
          break;
        case "accounting":
          this.forACCSection = true
          this.accountingSection = true
          break;
      }
    },
    onShippedChanged(event) {
      event == "Yes" ? this.shippeded = true : this.shippeded = false
    },
    prepareJSON(department) {
      const datas = [
        {
          order_number: this.axios_data.order_number,
          order_date: this.axios_data.order_date,
          dist_id: this.axios_data.dist_id,
          dist_name_1: this.axios_data.dist_name_1,
          dist_name_2: this.axios_data.dist_name_2,
          iso2: this.axios_data.iso2,
          iso3: this.axios_data.iso3,
          exception_type: this.axios_data.exception_type_id,
          status: this.status_id,
          created_by: this.axios_data.created_by,
          date_created: this.axios_data.date_created,
          claim_ticket_number: this.ticket,
          action_id: this.selectedAction,
          return_item_option: this.return_item_option,
          email: this.e_mail,
          phone_number: this.phone,
          pickup_country: this.PUcountry,
          pickup_street_address: this.PUaddress,
          pickup_city: this.PUcity,
          pickup_state: this.PUstate,
          pickup_zip_code: this.PUzip,
          package_shipped: this.shippeded,
          intercept_fee: parseFloat(this.intercept),
          country: this.current_country,
          street_address: this.current_address,
          city: this.current_city,
          state: this.current_state,
          zip_code: this.current_zip,
          department: department,
          returned_from: this.returned_from
        }
      ];
      return datas
    },
    myUploader(event){
      document.body.style.cursor = 'wait'
      let uploadedFiles = 0
      for (let i = 0; i < event.files.length; i++) {
        let element = event.files[i]
        let files = element
        let data = new FormData();
        data.append('files', files);
        this.imageUpload(data).then((res) => {
          if(res.code === 200){
            let tmp = {
              "exception_id": this.ID_exc,
              "path": res.path[0]
            }
            this.uploads.push(tmp)
            uploadedFiles++
          }
          if (event.files.length === uploadedFiles) {
            this.submiting(this.uploads)
          }
        })
      }      
    },
    updateReasons() {
      let json = [
        {
          "exception_id": this.ID_exc,
          "reason_id": this.reasonID,
          "value_other": this.otherReason
        }
      ]
      axios.put(`api/v1/local/cancellation/update/${this.cancelationID}`, json)
    },
    submiting(obj) {
      document.body.style.cursor = 'wait'
      // accounting section
      this.prepareAccJSON()
      this.getAccounting(this.accounting)

      this.prepareData()
      const department = this.ticketMaps()
      const datas = this.prepareJSON(department)

      // Update comments if not empty
      if (this.new_comment_info.length > 0) (this.uploadComment())

      // Update file if not empty
      if (obj.length > 0) (this.uploadFiles(obj))

      // Update label info
      this.updateLabelInfo()

      if (this.role === 'csr') {
        this.updateReasons()
      }
      
      // Update this exception
      axios.put(`api/v1/local/exception/update/${this.ID_exc}`, datas).then(res => {
        const RESPONSE = res.data[0]
        if (RESPONSE.code === 400) {
          this.showToast('Exception NOT Updated', RESPONSE.message, 'danger', 2500)
          document.body.style.cursor = 'default'
        } else {
          this.showToast('Successfully', 'Exception Updated', 'success', 1200)
          document.body.style.cursor = 'default'
          this.back()
        }
      })
    }
  }
}

</script>
