<template>
  <div class="layout-dashboard">
    <div class="p-grid">
      <tableApp></tableApp>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import tableApp from "../../components/transaction/Transaction_Dashboard.vue";

export default {
  components: {
    tableApp: tableApp
  }
};
</script>

<style>
.sizes span.p-calendar {
  width: 98% !important;
}
</style>
