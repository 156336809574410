<template>
    <div class="hr">
        <div class="card col-6 d-inline-block">
                <DataTable :value="product_infoRE" class="editable-cells-table order-details">
                    <template #header>{{ headerTitle }}</template>
                    <Column field="sku_id" header="Item ID"></Column>
                    <Column field="sku_name" header="Item Name"></Column>
                    <Column field="sku_quantity" header="Qty"></Column>
                    <Column field="sku_quantity_return" header="Qty for Return" v-if="showQTYReturn"></Column>
                </DataTable>
            </div>
            <div class="card col-6 d-inline-block" v-if="showTableRelease">
                <DataTable :value="product_infoRELESE" class="editable-cells-table release">
                    <template #header>
                    <div class="table-header">
                        <span class="m-auto">Products for Release</span>
                    </div>
                    </template>
                    <Column field="release_sku_id" header="Item ID"></Column>
                    <Column field="release_sku_name" header="Item Name"></Column>
                    <Column field="release_sku_quantity" header="Qty for Release"></Column>
                    <template #empty>
                        No product
                    </template>
                </DataTable>
            </div>
            <div class="card col-6 d-inline-block" v-if="wrongTableShow">
                <DataTable :value="product_infoWRONG" editMode="cell" class="editable-cells-table wrong" ref="dt" dataKey="id">
                    <template #header>
                    <div class="table-header">
                        <span class="m-auto">{{ wrongHeader }}</span>
                    </div>
                    </template>
                    <Column field="wrong_sku_id" header="Item ID"></Column>
                    <Column field="wrong_sku_name" header="Item Name"></Column>
                    <Column field="wrong_sku_quantity" header="Qty"></Column>
                    <template #empty>
                        No product
                    </template>
                </DataTable>
            </div>
            <div class="card col-6 d-inline-block" v-if="lostTableShow">
                <DataTable :value="product_info_lost" editMode="cell" class="editable-cells-table wrong" ref="dt" dataKey="id">
                    <template #header>
                    <div class="table-header">
                        <span class="m-auto">Lost Products</span>
                    </div>
                    </template>
                    <Column field="lost_sku_id" header="Item ID"></Column>
                    <Column field="lost_sku_name" header="Item Name"></Column>
                    <Column field="lost_sku_quantity" header="Qty"></Column>
                    <template #empty>
                        No product
                    </template>
                </DataTable>
            </div>
        </div>
</template>

<script>
export default {
    props: {
        propsData: {
            type: Object
        }
    },
    data() {
        return {
            showTableRelease: false,
            headerTitle: 'Order Details',
            wrongHeader: 'Products for Return',
            wrongTableShow: false,
            showQTYReturn: false,
            lostTableShow: false,
            product_infoRE: [],
            product_infoRELESE: [],
            product_infoWRONG: [],
            product_info_lost: []
        }
    },
    mounted() {
        this.removeEmpty()
        this.propsData.exception_type_id === 13 ? this.wrongProductTable() : this.render()
    },
    methods: {
        removeEmpty() {
            for (let index = 0; index < this.propsData.product_info.length; index++) {
                const element = this.propsData.product_info[index];
                if (element.sku_id !== null) {
                    this.product_infoRE.push(element)
                } else if (element.release_sku_id !== null) {
                    this.product_infoRELESE.push(element)
                } else if (element.wrong_sku_id !== null) {
                    this.product_infoWRONG.push(element)
                } else {
                    this.product_info_lost.push(element)
                }
            }
        },
        wrongProductTable() {
            const action = this.propsData.action_id
            switch(action) {
                case 1:
                    this.wrongTableShow = true;
                break;
                case 2:
                    this.wrongTableShow = true;
                    this.wrongHeader = "Products to Keep";
                break;
                case 3:
                    this.showTableRelease = true;
                    this.wrongTableShow = true;
                    this.wrongHeader = "Products to Keep";
                break;
                case 4:
                    this.showTableRelease = true;
                    this.wrongTableShow = true;
                break;
            }
        },
        render() {
            const action = this.propsData.action_id
            switch(action) {
                case 1:
                    this.showQTYReturn = true;
                    this.headerTitle = "Order Details/Products for Return";
                break;
                case 3: case 6:
                    this.showTableRelease = true;
                break;
                case 4:
                    this.showQTYReturn = true;
                    this.showTableRelease = true;
                    this.headerTitle = "Order Details/Products for Return";
                break;
                case 5:
                    this.lostTableShow = true
                break;
            }
        },
    },
}
</script>