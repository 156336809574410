<template>
    <div class="col-4 my-2">
        <p>Status</p>
        <InputText class="col-12" v-model="current_status" disabled />
    </div>
</template>

<script>
export default {
    props: {
        propsData: {
            type: Object
        }
    },
    data() {
        return {
            current_status: this.propsData.status
        }
    },
}
</script>