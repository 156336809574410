/**
 * Helper class for common methods used for working with Emlive
 */
const SocketHelper = (function() {

    /**
     * Get current page uri without domain and arguments
     * Works only with input similar to this:
     * /worklist/update/1373?userType=User&exType=13
     */
    const getCurrentPageId = () => {
        let page = window.location.href.split("?")[0];

        return page.substring(page.indexOf("/worklist/update/") + "/worklist/update/".length);
    }

    /**
     * Create headers object for api request
     *
     * @return {Headers} headers
     */
    const getHeadersForApiRequest = () => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', 'Bearer ' + $cookies.get("token"));

        return headers;
    }

    /**
     * Get endpoint for users api of Emlive
     *
     * @param {int} pageId - same as exception id
     * @return {string} apiEndpoint
     */
    const getApiEndpoint = (pageId) => {
        const baseUrl = process.env.VUE_APP_SOCKET_URL;
        const apiEndpoint = baseUrl + '/api/users/' + pageId;

        return apiEndpoint;
    }

    /**
     * Get username from jwt
     *
     * @return {String} username
     */
    const getUsername = () => {
        let token = $cookies.get("token")
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        const dataFromToken = JSON.parse(jsonPayload);

        return dataFromToken.sub.replace(/(^\w|\s\w)/g, m => m.toUpperCase());
    }

    return {
        getCurrentPageId,
        getHeadersForApiRequest,
        getApiEndpoint,
        getUsername
    }
})();

export default SocketHelper;
