export default {
    methods: {
        /**
         * Set department base on user role and exc type + status
         * If adverse event exc type or defective product - all action
         * Only if adverse event/defective product choosen + all action this ticket go to csrlead department
        */
        checkRole() {
            if (this.role === 'csr') {
                switch (this.type_id) {
                    case 19: case 20:
                        this.department = 'csrlead';
                    break;
                    case 23:
                        this.department = 'accounting';
                    break;
                    default:
                        this.department = 'logistics';
                    break;
                }
            } else {
                this.department = 'logistics';
            }
            return this.department;
        },
        complianceRole() {
            if (this.role === 'compliance') {
                switch (this.type_id) {
                    case 19: case 20:
                        // set department on current dep.
                        this.department = this.axios_data.department;
                    break;
                }
            } else {
                this.department = this.role;
            }
            return this.department;
        },
        /**
         * ticketMaps
         * Set department base on user role
         * Base on status, role, exc type and action save ticket for spec. department
         * Only CSR can create ticket
        */
        ticketMaps() {
            switch (this.status_id) {
                case 1:
                    this.department = this.checkRole();
                break;
                case 2: case 3:
                    this.department = this.complianceRole();
                break;
                case 4:
                    this.department = this.assingTo;
                    this.status_id = 1;
                break;
                case 5:
                    this.department = null;
                break;
            }
            return this.department;
        }
    }
};