export default {
    methods: {
        /**
         * selected button
         * base od DATA set selected button and variables
        */
        selectedBtn(DATA) {
            // set claim for front and back
            switch (DATA.claim_approved) {
                case true:
                    this.courierCH = 'Yes';
                    this.checked_bool = true;
                break;
                case false:
                    this.courierCH = 'No';
                    this.checked_bool = false;
                break;
            }
            // set return item option button
            switch (DATA.return_item_option) {
                case true:
                    this.rio = "E-Label";
                    this.pickUP = false;
                    this.return_item_option = true;
                break;
                case false:
                    this.rio = "3 Pickup";
                    this.pickUP = true;
                    this.return_item_option = false;
                break;
            }
            // set shipped button
            switch (DATA.package_shipped) {
                case true:
                    this.shipped = 'Yes';
                    this.shippeded = true;
                break;
                case false:
                    this.shipped = 'No';
                    this.shippeded = false;
                break;
            }
            // set shipping approved
            if (DATA.return_label_info.length > 0) {
                const LAST = DATA.return_label_info.length - 1;
                if (DATA.return_label_info[LAST].shipping_approved) {
                    this.approved = "Yes";
                    this.isApprovedSelected = true;
                    this.canEdit = false;
                } else if (DATA.return_label_info[LAST].shipping_approved === false) {
                    this.approved = "No";
                    this.isApprovedSelected = true;
                    this.canEdit = false;
                } else if (DATA.return_label_info[LAST].shipping_approved === null) {
                    return;
                }
                this.APP = DATA.return_label_info[LAST].shipping_approved;
                this.date_req = DATA.return_label_info[LAST].date_requested;
            }
        },
    },
};
