<template>
  <div>
    <!-- spinner component -->
    <spinnerHtml />
    <!-- html parts component -->
    <div v-if="!spinner">
      <div class="p-grid">
        <backBtn />
        <div class="col-6 mt-1">
          <Button class="mt-2 float-right w-20" label="Download as PDF" @click="downloadPDF()" />
        </div>
      </div>
      <div id="content" ref="printMe">
        <mainSection :propsData="this.axios_data" :propsMainData="this.table_data" />
        <exceptionType :propsData="this.axios_data"/>
        <productTables :propsData="this.axios_data"/>
        <shipping :propsData="this.axios_data"/>
        <paymentEmail :propsData="this.axios_data"/>
        <div class="col-12 my-4 hr">
            <h5>Shipping And Returns</h5>
        </div>
        <reshipment :propsData="this.axios_data" :propsMainData="this.table_data"/>
        <reshipAmount :propsData="this.axios_data"/>
        <accounting :propsData="this.axios_data"/>
        <div class="col-9 p-0">
          <!-- <courierSection :propsData="this.axios_data"/> -->
          <status :propsData="this.axios_data"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import jsPDF from "jspdf"

// import methods from mixins
import spinnerJS from '../../../store/spinner.js'
import downloadPDF from '../../../store/downloadPDF.js'

// componenet
import spinnerHtml from '../../html/spinner.vue'
import backBtn from '../../html/back_button.vue'

// HTML section
import mainSection from '../html_part/main_section.vue'
import exceptionType from '../html_part/exception_type.vue'
import productTables from '../html_part/product_tables.vue'
import shipping from '../html_part/shipping.vue'
import reshipment from '../html_part/reshipment.vue'
import accounting from '../html_part/accounting.vue'
import courierSection from '../html_part/courier_section.vue'
import status from '../html_part/status.vue'
import paymentEmail from '../html_part/payment_email.vue'
import reshipAmount from '../html_part/reshipAmount.vue'

export default {
  mixins: [ spinnerJS, downloadPDF ],
  components: {
    spinnerHtml,
    backBtn,
    mainSection,
    exceptionType,
    productTables,
    shipping,
    reshipment,
    accounting,
    courierSection,
    paymentEmail,
    reshipAmount,
    status
  },
  data() {
    return {
      axios_data: [],
      table_data: [],
      output: null,
      pdf_name: ''
    };
  },
  mounted() {
    this.getData()    
  },
  methods: {
    getData() {
      axios.get(`api/v1/local/exception/${this.$route.params.id}`).then(res => {
        this.axios_data = res.data[0]
        this.table_data = res.data
        this.pdf_name = `${this.axios_data.exception_message} - ${this.axios_data.action_name}-${new Date().getTime()}`
        this.removeSpinner()
      })
    }
  }
}
</script>
