<template>
    <div>
        <div class="shipping-box hr" v-if="showShipping">
            <h6 class="col-sm-12">Shipping Address:</h6>
            <div class="col-sm-3 d-inline-block">
                <p>Country/Region:</p>
                <InputText class="col-sm-12" v-model="current_country" disabled/>
            </div>
            <div class="col-sm-3 d-inline-block">
                <p>Street Address:</p>
                <InputText class="col-sm-12" v-model="current_address" disabled/>
            </div>
            <div class="col-sm-2 d-inline-block">
                <p>City:</p>
                <InputText class="col-sm-12" v-model="current_city" disabled/>
            </div>
            <div class="col-sm-2 d-inline-block">
                <p>State/Province:</p>
                <InputText class="col-sm-12" v-model="current_state" disabled/>
            </div>
            <div class="col-sm-2 d-inline-block">
                <p>Zip/Postal Code:</p>
                <InputText class="col-sm-12" v-model="current_zip" disabled/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        propsData: {
            type: Object
        }
    },
    data() {
        return {
            current_country: this.propsData.country,
            current_address: this.propsData.street_address,
            current_city: this.propsData.city,
            current_state: this.propsData.state,
            current_zip: this.propsData.zip_code,
            showShipping: false
        }
    },
    mounted() {
        switch (this.propsData.action_id) {
            case 3: case 4: case 5: case 6:
                this.showShipping = true
            break;
        }
    },
}
</script>