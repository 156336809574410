import axios from "axios";
import Vue from 'vue';

export default {
    methods: {
        /**
         * Get all status from axios
         * Map data and push to array
         * Data for dropdown must be in array 
        */
        getStatusAll(DATA) {
            axios.get(`api/v1/local/exceptions/status/all`).then(res => {
                res.data.map(val => {
                    let statusAll = {
                        "id": val.id,
                        "status_value": val.status_value
                    };
                    this.statusDropdown.push(statusAll);
                });
                // remove status open for system exceptions
                switch (DATA.exception_type_id) {
                    case 1: case 2: case 3: case 4: case 5: case 6: case 7: case 8: case 9: case 10: case 11: case 12: case 21: case 22:
                        this.statusDropdown.splice(0,1);
                        this.statusDropdown.splice(2,1);
                        break;
                }
                // product compliance dont have reassing options
                if (this.role === 'compliance') {
                    this.statusDropdown.splice(3,1);
                }
                this.removeCloseOption(DATA);
            });
        },
        removeCloseOption(DATA) {
            switch (this.type_id) {
                case 19: case 20:
                    if (this.role !== 'csrlead' && this.role !== 'compliance') {
                        this.statusDropdown.splice(4,1);
                    } else if(this.role === 'csrlead') {
                        // disable split ticket if already splited
                        if (DATA.product_compliance[0].sub_status !== null) {
                            this.statusDropdown.shift();
                        }
                    }
                break;
                // remove closed status for accounting
                default:
                    if (this.role === 'accounting') {
                        this.statusDropdown.pop();
                    }
                    // remove reassing status if ticket in not returned form another department
                    if (this.role === 'csr' && DATA.returned_from === '') {
                        this.statusDropdown.splice(3,1);
                    }
                break;
            }
        },
        /**
         * Show comment and/or upload box if someone add/upload comment/file 
        */
        showUploads(DATA) {
            // show comment box
            DATA.em_comments.length > 0 ? this.comm = true : this.comm = false; // jshint ignore:line
            // show upload box
            if (DATA.em_upload.length > 0) {
                this.filesForDownLoad = true;
            // show all uploaded files
                for (let index = 0; index < DATA.em_upload.length; index++) {
                    const element = DATA.em_upload[index];
                    this.dwn_link.push(element);
                }
            }
        },
        /**
         * Get data from return label info table
         * ${this.$route.params.id} this is ID from URL for that exception
        */
        getLabelInfoID() {
            axios.get(`api/v1/local/label/info/${this.$route.params.id}`).then(res => {
                this.LABEL_DATA = res.data;
                if (this.LABEL_DATA.length > 0) {
                    this.label_info_id = this.LABEL_DATA[0].id;
                }
            });
        },
        /**
         * Check phone input is valid phone number and only number 
        */
        checkPhone(event) {
            this.phone = event.target.value.replace(/[^a-zA-Z0-9]/g, "");
        },
        /**
         * Format calendar date to USA 
        */
        formatDate(event) {
            const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(event);
            const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(event);
            const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(event);

            this.date_req = `${ye}-${mo}-${da}`;
            // after erase date from input set to null or its will set to "1970-01-01"
            if (this.date_req === "1970-01-01") {
                this.date_req = null;
            }
        },
        /**
         * Edit all row in table
         * on edit get index of edited field and push to array for update
        */
        onRowEditInit(event) {
            this.originalRows[event.index] = {...this.return_label[event.index]}; // jshint ignore:line
            if (this.LABEL_DATA.length > 0) {
                this.forUpdate.push(event.data);
            } else {
                this.rl = [];
                this.rl.push(event.data);
            }
        },
        /**
         * On row edit cancel return old value
        */
        onRowEditCancel(event) {
            Vue.set(this.return_label, event.index, this.originalRows[event.index]);
        },
        /**
         * Return Item Option
         * Check what is click and render adition component
         * returnItemCheck - whick button was clicked
        */
        rioChanged(returnItemCheck) {
            switch (returnItemCheck) {
                case "E-Label":
                    this.pickUP = false;
                    this.return_item_option = true;
                break;
                case "3 Pickup":
                    this.pickUP = true;
                    this.return_item_option = false;
                break;
            }
        },
        /**
         * Open modal on plus icon in table 
        */
        addNew() {
            this.submitted = false;
            this.addNewReturnItem = true;
        },
        /**
         * Close modal after open on plus icon in table
        */
        hideDialog() {
            this.addNewReturnItem = false;
            this.submitted = false;
        },
        /**
         * Save product set data for return label info table
        */
        saveProduct() {
            this.submitted = true;
            this.rl.push({
                rl_tracking_number: this.rl_number,
                rl_cost: parseFloat(this.rl_cost),
                rl_weight: parseFloat(this.rl_weight)
            });

            this.return_label.push({
                rl_tracking_number: this.rl_number,
                rl_cost: parseFloat(this.rl_cost),
                rl_weight: parseFloat(this.rl_weight)
            });

            this.rl_number = null;
            this.rl_cost = null;
            this.rl_weight = null;
            this.addNewReturnItem = false;
        },
        /**
         * Check is approved button yes or no and save to variables
        */
        approvedChanged(ev) {
            ev === "Yes" ? this.approvedCH = true : this.approvedCH = false; // jshint ignore:line
        },
        /**
         * Check if status change
         * Save new status id
        */
        onStatusChange(event) {
            // Save new status id
            this.status_id = event.value.id;
            this.current_status = event.value.status_value;
            // If status id 4 show reasing dropdown and set returned from
            if (this.status_id === 4) {
                this.reassigned = true;
                this.returned_from = this.role;
            } else {
                this.reassigned = false;
                this.returned_from = this.axios_data.returned_from;
            }
        },
        /**
         * If status ID is 4 show reasing dropdown
         * this.assingTo - Reasigned ticket to another department
        */
        assingToChange(event) {
            this.assingTo = event.value.assing_value;
        },
        /**
         * prepare data for send to back
         * This data for return label table
         * Map data base on action 
        */
        prepareData() {
            // set only date or shipping for return label table
            this.rl2.push({
                shipping_approved: this.approvedCH,
                date_requested: this.date_req
            });
            // map and set all date for create
            const index = this.rl2.length - 1;
            this.rl.map((val, i) => {
                let rlArray = {
                    "exception_id": this.ID_exc,
                    "rl_tracking_number": val.rl_tracking_number,
                    "rl_cost": val.rl_cost,
                    "rl_weight": val.rl_weight,
                    "shipping_approved": this.rl2[index].shipping_approved,
                    "date_requested": this.rl2[index].date_requested
                };
                this.rl_all.push(rlArray);
            });
            // map and set all date for bulk update
            this.forUpdate.map((val, i) => {
                let bulk = {
                    "exception_id": this.ID_exc,
                    "id": val.id,
                    "rl_tracking_number": val.rl_tracking_number,
                    "rl_cost": val.rl_cost,
                    "rl_weight": val.rl_weight,
                    "shipping_approved": val.shipping_approved,
                    "date_requested": val.date_requested
                };
                this.bulkArray.push(bulk);
            });
        },
    },
};
