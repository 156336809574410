<template>
  <div>
    <div class="row">
      <div class=" global">
        <span class="p-input-icon-right">
          <i class="pi pi-search mr-2" />
          <InputText v-model="global" class="w-3" placeholder="Global Search" @input="globalSearch"/>
        </span>
      </div>
    </div>
    <ContextMenu :model="menuModel" ref="cm" />
    <div class="p-grid">
      <div class="p-col-12 px-0">
        <div class="card card-w-title border-0">
          <p class="m-0 p-0 counter" v-if="!loading">Showing {{ fr }}-{{ to }} of {{ totalRecords }} results.</p>
          <DataTable
            :value="transaction"
            class="p-datatable-responsive"
            :lazy="true"
            :totalRecords="totalRecords"
            :loading="loading"
            contextMenu
            :contextMenuSelection.sync="cMenu"
            @row-contextmenu="onRowContextMenu">
            <template #header>Transaction View</template>
            <Column field="id" header="Exception ID"></Column>
            <Column field="exception_message" header="Exception Type"></Column>
            <Column field="created_by" header="Created by"></Column>
            <Column field="order_number" header="Order number"></Column>
            <Column field="dist_id" header="Distributor ID"></Column>
            <Column field="dist_name_1" header="Distributor’s Name"></Column>
            <Column field="country" header="Country"></Column>
            <Column field="date_created" header="Date Created"></Column>
            <Column headerStyle="width: 8em; text-align: center" bodyStyle="text-align: center">
              <template #header></template>
              <template #body="slotProps">
                <router-link
                  :to="{ path: '/worklist/update/' + id, query:{exType: type} }"
                  event=""
                  @click.native.prevent = "handleAllowedUpdates(id, type)"
                  :slotProps="slotProps" >
                  <Button type="button" icon="pi pi-pencil" @click="updateEx(slotProps.data.id, slotProps.data.exception_type_id)" @click.middle="updateEx(slotProps.data.id, slotProps.data.exception_type_id)" class="center"></Button>
                </router-link>
              </template>
            </Column>
            <template #empty>
              No records found.
            </template>
          </DataTable>
          <Paginator :rows="15" :totalRecords="totalRecords" @page="onPage($event)"></Paginator>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import EventBus from "../../event-bus";
import Emlive from "../../emlive";
import SocketHelper from "../../SocketHelper";

export default {
  data() {
    return {
      cMenu: {},
      transaction: null,
      loading: false,
      totalRecords: 0,
      status: "open",
      from: 0,
      limit: 15,
      menuModel: [
        {
          label: 'Copy',
          icon: 'pi pi-fw pi-copy',
          command: () => this.copy(this.transaction)
        }
      ],
      copyText: "",
      id: 0,
      type: null,
      sort: "DESC",
      fr: null,
      to: null,
      global: null,
      department: null,
      roleDepartment: null,
      dataAPI: "api/v1/local/exception/order/view/status/id/sort",
      countAPI: "api/v1/local/exception/order/view/status/count",
      dataSubAPI: "api/v1/local/exception/order/view/sub/status/id/sort",
      countSubAPI: "api/v1/local/exception/order/view/sub/status/count"
    };
  },
  mounted() {
    this.loading = true;
    this.getUserName()
    this.checkLoginUser()
    this.getData()
    // on tab changed received new id(status) from eventbus
    EventBus.$on('select', (status) => {
      this.loading = true;
      this.status = status
      this.global = null
      this.checkLoginUser()
      this.getData()
    })
  },
  methods: {
    checkLoginUser() {
      switch (this.roleDepartment) {
        case "compliance":
          this.dataAPI = this.dataSubAPI
          this.countAPI = this.countSubAPI
          if (this.status === "closed") (this.department = this.roleDepartment)
          break;
        case "operations":
          this.department = "operations,system"
          if (this.status === "closed") (this.department = "all")
          break;
        default:
          this.status === "closed" ? this.department = "all" : this.department = this.roleDepartment
          break;
      }
    },
    // Get data for spec.status and department
    getData() {
      axios.get(`${this.dataAPI}/${this.status}/${this.from}/${this.limit}/${this.sort}/${this.department}`).then(res => {
        this.transaction = res.data;
        this.cMenu = this.transaction[0];
        this.loading = false;
        this.calculateCount()
        this.dataArived()
      })
      this.getCountData()
    },
    dataArived() {
      let pending = false
      EventBus.$emit('status_changed', pending)
    },
    // Get count for this data for spec.department by status and department
    getCountData() {
      axios.get(`${this.countAPI}/${this.status}/${this.department}`).then(res => (this.totalRecords = res.data));
    },
    // calculate how much row in table
    calculateCount() {
      this.fr = this.from
      this.to = this.limit
      this.limit > this.totalRecords ? this.to = this.totalRecords : this.to = this.limit
    },
    // calculate pagination for loaded data
    calculateCountOnPage(first, rows) {
      this.fr = first
      this.to = rows
      this.to > this.totalRecords ? this.to = this.totalRecords : this.to = this.to
    },
    // Get all data for user from token
    getUserName() {
      let token = $cookies.get("token")
      let base64Url = token.split('.')[1];
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      let dataFromToken = JSON.parse(jsonPayload);

      this.roleDepartment = dataFromToken.roles[0]
      this.department = dataFromToken.roles[0]
    },
    // set id and type for load ticket 
    updateEx(id, type) {
      this.id = id
      this.type = type
    },
    // show context menu on right click
    onRowContextMenu(event) {
      this.copyText = event.originalEvent.target.innerText
      this.$refs.cm.show(event.originalEvent);
      this.$refs.cm.pageX = event.originalEvent.clientX -265
      this.$refs.cm.pageY = event.originalEvent.clientY -110
    },
    // copy text to clipboard
    copy(data) {
      const copyText = this.copyText
      navigator.clipboard.writeText(copyText).then((ev) => {
        this.$bvToast.toast(copyText, {
        title: "Text Copy",
        autoHideDelay: 3000,
        variant: 'info'
        })
      }, (err) => {
        this.$bvToast.toast('Could not copy text', {
        title: "Text NOT Copy",
        autoHideDelay: 3000,
        variant: 'warning'
        })
      });
    },
    // on page changed load new data
    onPage($event) {
      this.loading = true;
      if (this.global) {
        axios.get(`${this.dataAPI}/${this.status}/${$event.first}/${$event.rows}/${this.sort}/${this.global}/${this.department}`).then(res => {
          this.cMenu = res.data[0];
          this.transaction = res.data;
          this.loading = false;
          this.calculateCountOnPage($event.first, $event.rows)
        });
      } else {
        axios.get(`${this.dataAPI}/${this.status}/${$event.first}/${$event.rows}/${this.sort}/${this.department}`).then(res => {
          this.transaction = res.data;
          this.loading = false;
          this.calculateCountOnPage($event.first, $event.rows)
        });
      }
    },
    // search by status
    globalSearch() {
      if (this.global) {
        this.getDataSearchCount()
        this.getDataSearch()
      } else {
        this.getData()
      }
    },
    getDataSearch() {
      axios.get(`${this.dataAPI}/${this.status}/${this.from}/${this.limit}/${this.sort}/${this.global}/${this.department}`).then(res => {
        this.cMenu = res.data[0];
        this.transaction = res.data;
        this.loading = false;
        this.calculateCount()
      });
    },
    getDataSearchCount() {
      axios.get(`${this.countAPI}/${this.status}/${this.global}/${this.department}`).then(res => {
        this.totalRecords = res.data;
      });
    },

    /**
     * Check to see if someone is already on exception page, alert if it is, redirect if not
     *
     * @param {int|string} pageId - page ID, same as exception id
     * @param {string} type
     */
    handleAllowedUpdates(pageId, type) {
      //hit user api of Emlive and see if someone is already on this page
      fetch(SocketHelper.getApiEndpoint(pageId), {
        method: 'GET',
        headers: SocketHelper.getHeadersForApiRequest()
      })
        .then(response => response.json())
        .then(json => {
            if (json.length) {
              //if someone is already on this page, only show info
              let message = "This exception is currently being updated by " + json[0];
              this.$bvToast.toast(message, {
                title: "Access denied",
                autoHideDelay: 3000
              });
            } else {
              //allow user to access exception - push him to page
              this.$router.push({path: '/worklist/update/' + pageId, query: {exType: type}});
            }
      });
    },
  },
};
</script>

<style lang="scss">
.center {
  justify-content: end !important;
  padding: 6px !important;
}
.card {
  border:none !important;
}
.counter {
  text-align: end;
  font-size: small;
  font-style: italic;
}
.global {
  position: absolute !important;
  top: 8px;
  right: 0%;
  text-align: end;
  padding: 6px !important;
}
.w-3 {
  width: 300px;
}
</style>
