<template>
    <div>
        <div class="row">
            <div class="col-3 mb-3">
                <Button 
                    label="Back" 
                    class="custom-btn" 
                    @click="$router.go(-1)" />
            </div>
            <h4 class="col-12 mb-3">Update Type</h4>
            <div class="p-field col-3">
                <label for="title">Title</label>
                <InputText class="col-12" id="title" type="text" v-model="title" />
            </div>
            <div class="p-field col-2">
                <label for="title">Status</label>
                <SelectButton v-model="statusText" :options="options" @input="setStatus"/>
            </div>
        </div>
        <div class="row col-3 mt-4">
            <Button label="Update" class="custom-btn" @click="update"/>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            title: '',
            statusText: '',
            status: null,
            options: ['On', 'Off'],
        }
    },
    mounted() {
        this.getMenuItemData()
    },
    methods: {
        getMenuItemData() {
            axios.get(`api/v1/inquiry-type/read/by-id`, {
                params: {
                    id: this.$route.params.id
                }
            }).then(res => this.setData(res.data))
        },
        setData(data) {
            this.title = data.inquiry_type
            this.status = data.inquiry_status
            if (data.inquiry_status) {
                this.statusText = 'On'
            } else {
                this.statusText = 'Off'
            }
        },
        setStatus(ev) {
            if (ev === 'On') {
                this.status = true
            } else {
                this.status = false
            }
        },
        update() {
            let json = {
                "id": +this.$route.params.id,
                "inquiry_type": this.title,
                "inquiry_status": this.status
            }

            axios.put(`api/v1/inquiry-type/update`, json).then(res => {
                if (res.status === 200) {
                    this.refresh()
                }
            })
        },
        refresh() {
            setTimeout(() => {
                this.$router.go()
            }, 500)
        }
    }
}
</script>