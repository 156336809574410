import axios from "axios";
export default {
    methods: {
        /**
         * api for insert comment(s)
        */
        uploadComment() {
            axios.post(`api/v1/local/comments/insert`, this.new_comment_info).then(res => {
                const RESPONSE = res.data[0];
                this.showToast(RESPONSE.message, "Comment Created", 'success', 1200);
            });
        },
        /**
         * api for insert file(s)
        */
        uploadFiles(obj) {
            axios.post(`api/v1/local/uploads/insert`, obj).then(res => {
                const RESPONSE = res.data[0];
                this.showToast(RESPONSE.message, "File Uploaded", 'success', 1200);
            });
        },

        getCustomerRedirectID(id) {
            axios.get(`api/v1/local/customer-redirect/${id}`).then(res => {
                this.customerID = res.data[0].id
            })
        },

        updateCustomerRedirect(id, obj) {
            axios.put(`api/v1/local/customer-redirect/update/${id}`, obj)
        },

        /**
         * api for return label table
        */
        updateLabelInfo() {
            // CREATE ALL
            if (this.rl_all.length > 0) {
                axios.post(`api/v1/local/label/info/insert`, this.rl_all).then(res => {});
            }
            // update approved and date
            if (this.LABEL_DATA.length > 0 && this.rl_all.length === 0) {
                const lastIDForUpdate = this.LABEL_DATA.length - 1;
                const ID = this.LABEL_DATA[lastIDForUpdate].id;
                if (ID !== undefined) {
                    axios.put(`api/v1/local/label/info/update/${ID}`, this.rl2).then(res => {});
                }
            }
            // if something changed in return label table
            if (this.bulkArray.length > 0) {
                axios.put(`api/v1/local/label/info/update/bulk`, this.bulkArray).then(res => {});
            }
        },
    },
};
