<template>
  <div class="exception-body notfound">
    <div class="exception-panel"></div>
    <div class="exception-content">
      <img src="@/assets/logo.png" alt="Organo Gold" />
      <h1><span class="exception-name">PAGE</span> NOT FOUND</h1>
      <p>Requested resource is not available.</p>
      <a class="pointer" @click="goDashboard">Back to Dashboard</a>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goDashboard() {
      window.location = "/";
    }
  }
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
