<template>
    <div>
        <div class="p-grid">
            <backBtn />
        </div>
        <div class="p-grid">
            <div class="p-col-6">
                <div class="card card-w-title pt-2 ">
                <DataTable :value="table_data" class="p-datatable-responsive" :rows="20" :lazy="true" :loading="loading">
                    <template #header>Distributor Details</template>
                    <Column field="dist_id" header="Distributor ID"></Column>
                    <Column field="dist_name_1" header="Distributor’s Name"></Column>
                    <Column field="country" header="Country"></Column>
                </DataTable>
                </div>
            </div>
            <div class="p-col-6">
                <Accordion class="card card-w-title p-field p-col-12 p-md-12 accordion-custom">
                    <AccordionTab header="Add New Comment">
                        <div class="p-datatable card card-w-title p-field p-col-12 p-md-12 float-r" >
                        <h4 class="p-datatable-header">Reason/Comment</h4>
                        <Textarea id="txt" :value="value" :autoResize="true" rows="5" cols="30" class="w-inherit" />
                        </div>
                        <Button label="Save" class="m-2 float-right" @click="saveComment"/>
                        <div class="card card-w-title p-field p-col-12 p-md-12">
                        <DataTable class="comment_table" :value="new_comment_info_table">
                            <template #header>Reason/Comment Preview</template>
                            <Column field="comment_date" header="Date"></Column>
                            <Column field="comment_user" header="User"></Column>
                            <Column field="comment" header="Comment"></Column>
                            <Column bodyStyle="text-align: center" >
                                <template #header></template>
                                <template #body="slotProps">
                                <Button type="button" icon="pi pi-trash" :slotProps="slotProps" @click="deleteComment(slotProps.data.row_id)" class="center"></Button>
                                </template>
                            </Column>
                            <template #empty>
                            No new comments
                            </template>
                        </DataTable>
                        </div>
                    </AccordionTab>
                </Accordion>
                <Accordion class="card card-w-title p-field p-col-12 p-md-12 accordion-custom" v-if="this.comment_info.length > 0">
                    <AccordionTab header="Comment History">
                        <DataTable class="comment_table" :value="comment_info" sortField="id" :sortOrder="-1">
                        <Column field="comment_date" sortable header="Date"></Column>
                        <Column field="comment_user" sortable header="User"></Column>
                        <Column field="comment" sortable header="Comment"></Column>
                        </DataTable>
                    </AccordionTab>
                </Accordion>
                <Accordion class="card card-w-title border-none p-field p-col-12 p-md-12 accordion-custom" v-if="filesForDownLoad">
                    <AccordionTab header="Uploaded Files">
                        <ul class="p-0" v-for="dwn in dwn_link" :key="dwn.path">
                        <a :href="dwn.path">{{dwn.path.split("/")[4]}}</a>
                        </ul>
                    </AccordionTab>
                </Accordion>
                <div class="card card-w-title p-field p-col-12 p-md-12">
                    <FileUpload 
                        name="files" 
                        chooseLabel="Upload" 
                        :multiple="true"
                        :customUpload="true"
                        @uploader="myUploader"
                        accept=".csv, .xlx, .xlsx, .pdf, .doc, .txt, .jpg, .jpeg, .png, .docx, .PNG, .JPG, .JPEG" 
                        :maxFileSize="10000000" >
                        <template #empty>
                        <p>Drag and drop files here to upload.</p>
                        </template>
                    </FileUpload>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="col-3">
                    <label>Exception Category</label>
                    <InputText class="p-field p-md-12 mb-3" type="text" v-model="exception_category" disabled />
                </div>
                <div class="col-3 align-top">
                    <p>Exception Type</p>
                    <InputText class="p-field p-md-12 mb-3" type="text" v-model="exception_type" disabled />
                </div>
                <div class="col-3">
                    <label for="icon">Date Created</label>
                    <Calendar 
                        id="icon" 
                        v-model="dateCreated" 
                        :showIcon="true" 
                        dateFormat="yy/mm/dd" 
                        disabled
                        @date-select="formatCalendar($event)"/>
                </div>
            </div>
        </div>
        <div class="row col-12 status mt-3">
            <div class="col-sm-3">
                <label>Status</label>
                <Dropdown 
                    class="col-12" 
                    v-model="selectedStatus" 
                    :options="statusDropdown" 
                    optionLabel="status_value" 
                    :placeholder="selectedStatusPlaceholder" 
                    @change="onStatusChange" />
            </div>
        </div>
        <div class="row">
            <div class="col-12 my-2">
                <Button class="float-right mr-2" label="Submit" @click="submitForm" />
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import mainMethods from '../../store/mainMethods.js'
import backBtn from '../html/back_button.vue'

export default {
    mixins: [mainMethods],
    components: { backBtn },
    data() {
        return {
            exception_category: null,
            exception_type: null,
            table_data: [],
            new_comment_info:[],
            new_comment_info_table: [],
            comment_info: [],
            files: [],
            value: null,
            user: null,
            comm: false,
            uploads: [],
            selectedStatus: null,
            statusDropdown: [],
            status_id: 1,
            usaTime: null,
            country: null,
            dist_id: null,
            dist_name: null,
            dist_name_2: null,
            iso2: null,
            iso3: null,
            loading: false,
            selectedStatusPlaceholder: '',
            minDate: null,
            dateCreated: null,
            filesForDownLoad: false,
            dwn_link: [],
            created_by: ''
        }
    },
    mounted() {
        this.getUserName()
        this.getStatusAll()
        this.getUSATime()
        this.getData()
    },
    methods: {
		getData() {
			this.loading = true
            axios.get(`api/v1/nai-exceptions/read-by-id`, {
                params: {
                    page: +this.$route.params.id
                }
            }).then(res => {
                this.loading = false
                this.table_data.push(res.data)
                this.created_by = res.data.created_by
                this.country = res.data.country
                this.dist_id = res.data.dist_id
                this.exception_category = res.data.created_by
                this.exception_type = res.data.exception_type_name.exception_type
                this.dist_name_1 = res.data.dist_name_1
                this.iso2 = res.data.iso2
                this.iso3 = res.data.iso3
                this.status_id = res.data.status
                this.comment_info = res.data.comments
                this.dateCreated = res.data.date_created
                this.showUploads(res.data)

                switch (res.data.status) {
                    case 1:
                        this.selectedStatusPlaceholder = "Open"
                    break;
                    case 2:
                        this.selectedStatusPlaceholder = "In Progress"
                    break;
                    case 3:
                        this.selectedStatusPlaceholder = "Closed"
                    break;
                }

            })
        },
        showUploads(DATA) {
            if (DATA.uploads.length > 0) {
                this.filesForDownLoad = true
                for (let index = 0; index < DATA.uploads.length; index++) {
                    const element = DATA.uploads[index];
                    this.dwn_link.push(element);
                }
            }
        },
        getUSATime() {
            const current_date = new Date().toISOString().slice(0,10)
            this.usaTime = current_date.toLocaleString("en-US", {timeZone: "America/Vancouver"});
        },
		saveComment() {
			const txt = document.getElementById('txt');
			// get value from textarea
			if (txt.value) {
                txt.value.replace(/'/g, "\\'");
                // data for send
                this.new_comment_info = 
                    {
                        "nai_exceptions_id": +this.$route.params.id,
                        "comment": txt.value,
                        "comment_user": this.user,
                        "comment_date": this.usaTime
                    }
                // data for show in table
                this.new_comment_info_table.push(
                    {
                        "row_id": this.new_comment_info_table.length,
                        "nai_exceptions_id": this.$route.params.id,
                        "comment": txt.value,
                        "comment_user": this.user,
                        "comment_date": this.usaTime
                    }
                );
                // clear textarea after save comment
                txt.value = "";
			}
        },
        getStatusAll() {
            axios.get(`api/v1/nai-status/read`).then(res => {
                res.data.map(val => {
                    let statusAll = {
                        "id": val.id,
                        "status_value": val.status_value
                        };
                    this.statusDropdown.push(statusAll);
                });
            });
        },
        onStatusChange(event) {
            this.status_id = event.value.id
        },
        formatCalendar(event) {
            const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(event);
            const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(event);
            const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(event);
            
            this.dateCreated = `${ye}-${mo}-${da}`;
        },
        uploadComment() {
            if (this.new_comment_info.length !== 0) {
                axios.post(`api/v1/nai-comments/create`, this.new_comment_info).then(res => {
                    if (res.status === 201) {
                        this.showToast('Comment Created', 'Successfully added new comment', 'success', 1000)
                    }
                })
            }
        },
        myUploader(event){
            document.body.style.cursor = 'wait'
            let uploadedFiles = 0
            for (let i = 0; i < event.files.length; i++) {
                let element = event.files[i]
                let files = element
                let data = new FormData();
                data.append('files', files);
                this.imageUpload(data).then((res) => {
                    if(res.code === 200){
                        let tmp = {
                            "nai_exceptions_id": +this.$route.params.id,
                            "path": res.path[0]
                            }
                        this.uploads.push(tmp)
                        uploadedFiles++
                    }
                    
                    if (event.files.length === uploadedFiles) {
                        this.submiting(this.uploads)
                    }
                })
            }      
        },
        uploadFile(data) {
            if (data.length > 0) {
                axios.post(`api/v1/nai-upload/bulk/create`, data)
            }
        },
        submiting(data) {
            document.body.style.cursor = 'wait'
            
            this.uploadFile(data)
            this.uploadComment()

            axios.put(`api/v1/nai-exceptions/update-status?exception_id=${+this.$route.params.id}&status=${this.status_id}`)
            .then(res => {
                if (res.status === 200) {
                    document.body.style.cursor = 'default'
                    this.showToast('Exception Updated', 'Successfully', 'success', 1000)
                    this.goBack()
                }
            })
        },
        goBack() {
            setTimeout(() => {
                this.back()
            }, 800);
        }
    }
}
</script>
