<template>
    <div>
        <div class="returnLabelShow " v-if="this.propsData.action_id === 1 || this.propsData.action_id === 4">
            <div class="col-sm-6 d-inline-block">
                <DataTable :value="return_label" class="editable-cells-table" disabled>
                    <template #header>
                        <div class="table-header justify-content-between">
                            <span>Return Label Table</span>
                        </div>
                    </template>
                    <Column field="rl_tracking_number" header="RL Tracking number"></Column>
                    <Column field="rl_cost" header="RL Cost"></Column>
                    <Column field="rl_weight" header="Weight"></Column>
                    <template #empty>
                        No records found.
                    </template>
                </DataTable>
            </div>
            <div class="col-sm-2 d-inline-block v-top">
                <p>Is Approved:</p>
                <SelectButton v-model="approved" :options="yesNo" disabled/>
            </div>
            <div class="col-sm-2 d-inline-block v-top">
                <p>Date Processed:</p>
                <Calendar id="icons" v-model="date_req" :showIcon="true" dateFormat="yy-mm-dd" disabled/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        propsData: {
            type: Object
        }
    },
    data() {
        return {
            yesNo: ['Yes', 'No'],
            return_label: this.propsData.return_label_info,
            approved: null,
            date_req: null
        }
    },
    mounted() {
        if (this.propsData.return_label_info.length > 0) {
            const LAST = this.propsData.return_label_info.length - 1
            this.date_req = this.propsData.return_label_info[LAST].date_requested
            switch (this.propsData.return_label_info[LAST].shipping_approved) {
                case true:
                    this.approved = "Yes"
                    break;
                case false:
                    this.approved = "No"
                    break;
                default:
                    return;
                    break;
            }
        }
    },
}
</script>