<template>
  <div :class="['layout-profile', { 'layout-profile-active': this.expanded }]">
    <button class="layout-profile-button p-link" @click="onClick">
      <div class="layout-profile-userinfo">
        <span class="layout-profile-name">{{ user }}</span>
        <span class="layout-profile-role">{{ role }}</span>
      </div>
      <i class="layout-profile-icon pi pi-angle-down"></i>
    </button>
    <transition name="layout-profile-menu">
      <ul class="layout-profile-menu" v-show="!expanded">
        <li role="menuitem">
          <button class="p-link" @click="modalPartsShow">
            <i class="pi pi-pencil"></i>
            <span>Change Password</span>
          </button>
        </li>
        <li role="menuitem">
          <router-link to="/login">
            <button class="p-link" :tabIndex="expanded ? null : '-1'">
              <i class="pi pi-fw pi-ban"></i>
              <span>Logout</span>
            </button>
          </router-link>
        </li>
      </ul>
    </transition>
    <!-- POP UP -->
    <Dialog :visible.sync="changePass" :style="{width: '450px'}" header="Change password" :modal="true" class="p-fluid">
      <div class="p-field " v-if="form_show">
        <label>Enter new password</label>
        <InputText class="w-90" v-model="new_password" :type="inputPass ? 'password' : 'text'" />
        <i class="pi pi-eye bd-eye"  @click="inputPass = !inputPass"></i>
      </div>
      <div v-if="message_show" class="p-field">
        <p class="text-center message-pass">{{message}}</p>
      </div>
      <template v-if="form_show" #footer>
        <Button label="Cancel" icon="pi pi-times" @click="modalPartsShow"/>
        <Button label="Change" icon="pi pi-check" @click="changePassword" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      user: null,
      role: null,
      changePass: false,
      new_password: '',
      message: '',
      message_show: false,
      form_show: true,
      inputPass: true
    }
  },
  props: {
    expanded: {
      type: Boolean,
      default: null
    }
  },
  mounted() {
    this.getUserData()
  },
  methods: {
    // get user data from token
    getUserData() {
      let token = $cookies.get("token")
      let base64Url = token.split('.')[1];
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      const dataFromToken = JSON.parse(jsonPayload);

      this.user = dataFromToken.first_name
      this.role = dataFromToken.roles[0]
    },
    onClick(event) {
      this.$emit("profile-click", event);
      event.preventDefault();
    },
    changePassword(){
      let json = {
        password: this.new_password
      };
      // set authorization token
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + $cookies.get("token");

      axios.post(process.env.VUE_APP_EDIT_PASS , json).then(res => {
        if (res.status === 200) {
          this.message = "Password Changed!";
          this.message_show = true;
          this.form_show = false;
          this.new_password = '';
        }
      })
      .catch ((err) => {
        if (err.response.status === 400){
          this.message = err.response.data.error;
          this.message_show = true;
          this.form_show = true;
          this.new_password = '';
        }
      })
    },
    modalPartsShow(){
      this.changePass = !this.changePass;
      this.message_show = false;
      this.form_show = true;
      this.inputPass = true;
      this.new_password = '';
    },
  },
  name: "AppInlineProfile"
};
</script>
<style lang="scss">
  body .p-dialog .p-dialog-content {
    padding: 0 !important;
  }
  .p-dialog-title {
    font-size: 16px;
    margin-bottom: 10px;
  }
</style>