import axios from 'axios';

const authorization = {
	username: 'g6SvwQMQ3ww86ATdm8aQEnHm',
	password: 'S54TyrrpPmgmLpeH3WdemxEQ'
};

export default {
    methods: {
        /**
         * Add sleshes in string 
         */
        addslashes(string) {
            return string.replace(/\\/g, '\\\\').
            replace(/\u0008/g, '\\b').
            replace(/\t/g, '\\t').
            replace(/\n/g, '\\n').
            replace(/\f/g, '\\f').
            replace(/\r/g, '\\r').
            replace(/'/g, '\\\'').
            replace(/'/g, "\\'").
            replace(/"/g, '\\"');
        },
        /**
         * Replace comma to dot
         */
        replaceCommaToDot(event, fields) {
            switch (fields) {
                case 'rl_cost':
                    this.rl_cost = event.target.value.replace(/,/g, '.');
                    break;
                case 'rl_weight':
                    this.rl_weight = event.target.value.replace(/,/g, '.');
                    break;
                case 'checkAmount':
                    this.checkAmount = event.target.value.replace(/,/g, '.');
                    break;
                case 'checkAmountACC':
                    this.checkAmountACC = event.target.value.replace(/,/g, '.');
                    break;
                case 'intercept':
                    this.intercept = event.target.value.replace(/,/g, '.');
                    break;
                case 'charge_amount':
                    this.charge_amount = event.target.value.replace(/,/g, '.');
                break;
            }
        },
        /**
         * Set max selected date
         */
        setMaxDate() {
            let today = new Date();
            this.maxDateValue = today;
        },
        /**
         * Check phone input is valid phone number and only number 
        */
        checkPhone(event) {
            this.phone = event.target.value.replace(/[^a-zA-Z0-9]/g, "");
        },
        checkAmounts(event) {
            this.intercept_amt = event.replace(/[^0-9\.]/g,'')
        },
        /**
         * Copy address from shipping to pickup address 
        */
        copyAddress() {
            this.PUcountry = this.current_country;
            this.PUaddress = this.current_address;
            this.PUcity = this.current_city;
            this.PUstate = this.current_state;
            this.PUzip = this.current_zip;
        },
        /**
         * Check if mail address is corect use regex
         * show error msg if email is incorrect
        */
        emailChecker(event) {
            const mailValue = event.target.value;
            const mailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!mailRegex.test(mailValue)) {
                this.valueEnter = false;
            } else {
                this.valueEnter = true;
            }
        },
        /**
         * Show context menu with copy button on right click
        */
        onRowContextMenu(event) {
            this.copyText = event.originalEvent.target.innerText;
            this.$refs.cm.show(event.originalEvent);
        },
        /**
         * Show toast message
        */
        showToast(title, body, style, duration) {
            this.$bvToast.toast(body, {
                title: title,
                autoHideDelay: duration,
                variant: style
            });
        },
        /**
         * After submit clicked back to prevision page
         * get methods from another componenet
         */
        back() {
            setTimeout(() => { this.$root.$refs.back.backToPrevisionPage(); }, 1500);
            // this.$root.$refs.back.backToPrevisionPage();
        },
        /**
         * Copy text to clipboard and show copied text in toast msg
        */
        copy() {
            const copyText = this.copyText;
            navigator.clipboard.writeText(copyText).then((ev) => {
                // show copied text in toast msg
                this.showToast("Text Copy", copyText, "info", 1500);
            }, (err) => {
                // show error msg
                this.showToast("Text NOT Copy", "Could not copy text", "warning",3000);
            });
        },
        /**
         * Trigger modal for delete comment
        */
        deleteComment(rowID) {
            this.$bvModal.msgBoxConfirm('Please confirm that you want to delete this comment.', {
                title: 'Delete Comment',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
                if (value) {
                    this.new_comment_info_table.splice(rowID, 1);
                    this.new_comment_info.splice(rowID, 1);
                }
            });
        },
        /**
         * Get data from token and extract
        */
        getUserName() {
            let token = $cookies.get("token");
            let base64Url = token.split('.')[1];
            let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            // all data from token
            let dataFromToken = JSON.parse(jsonPayload);
            this.userID = dataFromToken.id;
            this.user = dataFromToken.full_name;
            this.role = dataFromToken.roles[0];
        },
        /**
         * Save text from textarea as comment and push in array
        */
        saveComment() {
            const txt = document.getElementById('txt');
            // get value from textarea
            if (txt.value) {
                txt.value.replace(/'/g, "\\'");
                // data for send
                this.new_comment_info.push(
                    {
                        "exception_id": this.ID_exc,
                        "comment_body": txt.value,
                        "comment_user": this.role.slice(0, 3).toUpperCase() + " " + this.user,
                        "comment_date": this.usaTime
                    }
                );
                // data for show in table
                this.new_comment_info_table.push(
                    {
                        "row_id": this.new_comment_info_table.length,
                        "exception_id": this.ID_exc,
                        "comment_body": txt.value,
                        "comment_user": this.role.slice(0, 3).toUpperCase() + " " + this.user,
                        "comment_date": this.usaTime
                    }
                );
                // clear textarea after save comment
                txt.value = "";
            }
        },
        /**
         * Upload file on submit button
        */
        async imageUpload(file) {
            delete axios.defaults.headers.common["sessionid"];
            const instance = axios.create();
            instance.interceptors.request.use((config) => {
                config.headers['Authorization'] = 'Basic ZzZTdndRTVEzd3c4NkFUZG04YVFFbkhtOlM1NFR5cnJwUG1nbUxwZUgzV2RlbXhFUQ=='
                return config
            });
            const res = await instance.post(process.env.VUE_APP_UPLOAD_URL,
            file,
            {
                headers:{
                    'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
                }
            } 
        )
            return res.data;
        },
        beforeUpload() {
            const instance = axios.create();
            instance.interceptors.request.use((config) => {
                config.headers['Authorization'] = 'Basic ZzZTdndRTVEzd3c4NkFUZG04YVFFbkhtOlM1NFR5cnJwUG1nbUxwZUgzV2RlbXhFUQ=='
                return config
            })
        },
        /**
         * Submit method
         * pbar click on upload button of that component
        */
        submitForm() {
            const pbar = document.getElementsByClassName("p-progressbar-determinate");
            const JWTToken = $cookies.get("token");
            // File ready for uplaods
            if (pbar.length !== 0) {
                document.getElementsByClassName("p-fileupload-buttonbar")[0].children[1].click();
            } else if (JWTToken) {
                let uploads = [];
                // submiting is collection of all methods
                this.submiting(uploads);
            }
        },
    },
};
