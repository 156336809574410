import jsPDF from "jspdf";

export default {
    methods: {
        async downloadPDF() {
            // get element by refs tag
            const el = this.$refs.printMe;
            const w = document.getElementById("content").offsetWidth -200;
            const h = document.getElementById("content").offsetHeight;
            const options = {
                type: 'dataURL'
            };
            // this.output === html image
            this.output = await this.$html2canvas(el, options)
            // convert to pdf
            const doc = new jsPDF('l', 'pt', [w, h]);
            doc.addImage(this.output, 'jpeg', 50, 25);
            doc.save(`${this.pdf_name}.pdf`);
        }
    }
};