<template>
  <div>
    <!-- spinner component -->
    <spinnerHtml />
    <!-- html parts component -->
    <div v-if="!spinner">
      <div class="p-grid">
        <backBtn />
        <div class="col-6 mt-1">
          <Button class="mt-2 float-right w-20" label="Download as PDF" @click="downloadPDF()" />
        </div>
      </div>
      <div id="content" ref="printMe">
        <mainSection :propsData="this.axios_data" :propsMainData="this.table_data" />
        <exceptionType :propsData="this.axios_data"/>
        <productTables :propsData="this.axios_data"/>
        <div class="col-12 my-4 hr">
          <h5>Shipping And Returns</h5>
        </div>
        <!-- miscellaneous  -->
        <div class="row" >
          <div class="col-3">
            <p class="pl-3">Amount Paid</p>
            <InputNumber class="col-12 pr-0" v-model="amount_paid" :useGrouping="false" disabled/>
          </div>
          <div class="col-3">
            <p>Amount Deducted</p>
            <InputNumber class="col-12 px-0" v-model="amount_deducted" :useGrouping="false" disabled/>
          </div>
          <div class="col-3">
            <p>Amount Refunded</p>
            <InputNumber class="col-12 px-0" v-model="amount_refunded" :useGrouping="false" disabled/>
          </div>
        </div>
        <div class="row col-12 my-4">
          <div class="col-3">
            <p>Is Approved</p>
            <SelectButton v-model="log_approved" :options="yesNo" disabled/>
          </div>
          <div class="col-3 pr-0">
            <p>Date Processed</p>
            <Calendar v-model="app_date" :showIcon="true" disabled/>
          </div>
        </div>
        <!-- miscellaneous for acc -->
        <div class="row col-12 my-4">
          <div class="col-3">
            <p>Processed by Accounting</p>
            <SelectButton v-model="acc_processed" :options="yesNo" disabled />
          </div>
          <div class="col-3 pr-0">
            <p>Date Processed</p>
            <Calendar id="processedDate" v-model="processedDate" :showIcon="true" disabled/>
          </div>
        </div>
        <div class="col-9 p-0">
          <status :propsData="this.axios_data"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import jsPDF from "jspdf"

// import methods from mixins
import accounting from '../../../store/accounting.js'
import spinnerJS from '../../../store/spinner.js'
import downloadPDF from '../../../store/downloadPDF.js'

// componenet
import spinnerHtml from '../../html/spinner.vue'
import backBtn from '../../html/back_button.vue'

// HTML section
import mainSection from '../html_part/main_section.vue'
import exceptionType from '../html_part/exception_type.vue'
import productTables from '../html_part/product_tables.vue'
import status from '../html_part/status.vue'

export default {
  mixins: [ accounting, spinnerJS, downloadPDF ],
  components: {
    spinnerHtml,
    backBtn,
    mainSection,
    exceptionType,
    productTables,
    status
  },
  data() {
    return {
      axios_data: [],
      table_data: [],
      amount_paid: null,
      amount_deducted: null,
      amount_refunded: null,
      log_approved: null,
      yesNo: ['Yes', 'No'],
      app_date: null,
      isAPPBool: null,
      log_date_processed: null,
      payment: [],
      accounting: [],
      accountingID: null,
      miscellaneousShow: true,
      accMiscellaneous: null,
      acc_processed: null,
      processedDate: '',
      charge_processing_fee: null,
      checkRefundBool: null,
      processingFeeBoll: null,
      accProcessedBoll: null,
      output: null,
      pdf_name: ''
    };
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      axios.get(`api/v1/local/exception/${this.$route.params.id}`).then(res => {
        this.axios_data = res.data[0]
        this.table_data = res.data
        const DATA = this.axios_data
        if (DATA.accounting[0] !== undefined) (this.populateDataAccounting(DATA))
        this.pdf_name = `${this.axios_data.exception_message} - ${this.axios_data.action_name}-${new Date().getTime()}`
        this.removeSpinner()
      });
    }
  }
}
</script>