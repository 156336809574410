<template>
    <div class="my-3">
        <p class="col-sm-12" v-if="this.propsData.exception_type_id === 17 ? title = 'Reason for cancellation' : 'Reason for exchange'">{{ title }}</p>
        <div class="col-sm-4 mb-2">
            <InputText class="col-12" v-model="reason" disabled/>
        </div>
        <div class="col-sm-4" v-if="this.propsData.reason_for_cancellation[0].reason_value === 'Other'">
            <Textarea class="col-12" type="text" v-model="otherReason" disabled :autoResize="true" rows="5" cols="30" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        propsData: {
            type: Object
        }
    },
    data() {
        return {
            reason: this.propsData.reason_for_cancellation[0].reason_value,
            otherReason: this.propsData.reason_for_cancellation[0].value_other
        }
    }
}
</script>