<template>
  <div>
    <div class="p-grid">
      <backBtn />
    </div>
    <ContextMenu :model="menuModel" ref="cm" />
    <div class="p-grid">
      <div class="p-col-6">
        <div class="card card-w-title pt-2 ">
          <DataTable :value="table_data" class="p-datatable-responsive" :rows="20" contextMenu :contextMenuSelection.sync="cMenu" @row-contextmenu="onRowContextMenu">
            <template #header>Transaction Details</template>
            <Column field="order_number" v-model="order_number" header="Order Number"></Column>
            <Column field="record_date" v-model="order_date" header="Order Date"></Column>
            <Column field="dist_id" v-model="dist_id" header="Distributor ID"></Column>
            <Column field="dist_name_1" v-model="dist_name_1" header="Distributor’s Name"></Column>
            <Column field="country" v-model="country" header="Country"></Column>
          </DataTable>
        </div>
      </div>
      <div class="p-col-6">
        <Accordion class="card card-w-title p-field p-col-12 p-md-12 accordion-custom">
          <AccordionTab header="Add New Comment">
            <div class="p-datatable card card-w-title p-field p-col-12 p-md-12 float-r" >
              <h4 class="p-datatable-header">Reason/Comment</h4>
              <Textarea id="txt" :value="value" :autoResize="true" rows="5" cols="30" class="w-inherit" />
            </div>
            <Button label="Save" class="m-2 float-right" @click="saveComment"/>
            <div class="card card-w-title p-field p-col-12 p-md-12">
              <DataTable class="comment_table" :value="new_comment_info_table">
                <template #header>Reason/Comment Preview</template>
                <Column field="comment_date" header="Date"></Column>
                <Column field="comment_user" header="User"></Column>
                <Column field="comment_body" header="Comment"></Column>
                <Column bodyStyle="text-align: center" >
                    <template #header></template>
                    <template #body="slotProps">
                      <Button type="button" icon="pi pi-trash" :slotProps="slotProps" @click="deleteComment(slotProps.data.row_id)" class="center"></Button>
                    </template>
                </Column>
                <template #empty>
                  No new comments
                </template>
              </DataTable>
            </div>
          </AccordionTab>
        </Accordion>
        <div class="card card-w-title p-field p-col-12 p-md-12">
          <FileUpload 
            name="files" 
            chooseLabel="Upload" 
            :multiple="true"
            :customUpload="true"
            @uploader="myUploader"
            accept=".csv, .xlx, .xlsx, .pdf, .doc, .txt, .jpg, .jpeg, .png, .docx, .PNG, .JPG, .JPEG" 
            :maxFileSize="10000000" >
            <template #empty>
              <p>Drag and drop files here to upload.</p>
            </template>
          </FileUpload>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mb-1 mt-3">
        <div class="col-4 d-inline-block">
          <h6>Exception Type</h6>
          <Dropdown class="col-12" v-model="selectedType" :options="exc_type" optionLabel="exception_type" @change="onDropdownChange" />
        </div>
        <div class="col-4 d-inline-block">
          <h6>Action</h6>
          <Dropdown class="col-12" v-model="selectedAction" :options="exc_action" optionLabel="action_name" :disabled="!selectedType" @change="onAction"/>
        </div>
        <div class="col-4 d-inline-block">
          <h6>Date</h6>
          <Calendar id="icon" v-model="usaTime" :showIcon="true" dateFormat="dd/mm/yy" disabled />
        </div>
      </div>
    </div>
    <hr>
    <!-- payment releated -->
    <div class="row my-4">
      <div class="col-12">
        <h6 class="col-12">Credit Card Details:</h6>
        <div class="col-3 d-inline-block">
          <p>Credit Card Number</p>
          <InputMask class="col-12" mask="9999-9999-9999-9999" v-model="creditCardNumber" placeholder="****-****-****-****" />
        </div>
        <div class="col-3 d-inline-block">
          <p>Cardholder Name</p>
          <InputText class="col-12" v-model="cardName" />
        </div>
        <div class="col-3 d-inline-block">
          <p>Expiry Date</p>
          <Calendar v-model="expiry_date" :showIcon="true" :manualInput="false" dateFormat="yy-mm-dd" @date-select="formatCalendarDate($event, calendar = 'expiry_date')"/>
        </div>
      </div>
    </div>
    <div class="row my-4">
      <div class="col-12">
        <h6 class="col-12">Transaction Details:</h6>
        <div class="col-3 d-inline-block">
          <p>Transaction Amount</p>
          <InputText class="col-12" v-model="transaction_amount" />
        </div>
        <div class="col-3 d-inline-block">
          <p>Transaction Date</p>
          <Calendar v-model="transaction_date" :showIcon="true" :maxDate="maxDateValue" :manualInput="false" dateFormat="yy-mm-dd" @date-select="formatCalendarDate($event, calendar = 'transaction_date')"/>
        </div>
      </div>
    </div>
    <div class="row col-12 status mt-3" v-if="selectedAction">
      <div class="col-sm-3">
        <h6>Status</h6>
        <Dropdown class="col-12" v-model="selectedStatus" :options="statusDropdown" optionLabel="status_value" placeholder="Open" @change="onStatusChange" />
      </div>
    </div>
    <div class="row">
      <div class="col-12 my-2">
        <Button class="float-right mr-2" label="Submit" @click.once="submitForm" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import methods from mixins
import onActionRender from '../../../store/onActionRender.js'
import mainMethods from '../../../store/mainMethods.js'
// component
import backBtn from '../../html/back_button.vue'

export default {
  mixins: [onActionRender, mainMethods],
  components: {
    backBtn
  },
  data() {
    return {
      table_data: [ 
        {
          "order_number" : "0",
          "record_date" : new Date().toISOString().slice(0,10),
          "dist_id" : "0000000000",
          "dist_name_1" : "N/A",
          "country" : "N/A",
        }
      ],
      exc_type: [],
      exc_action: [],
      selectedType: null,
      selectedAction: null,
      type_id: null,
      cMenu: {},
      new_comment_info:[],
      new_comment_info_table: [],
      action_name: null,
      files: [],
      value: null,
      date_created: null,
      order_date: null,
      order_number: null,
      dist_id: null,
      dist_name_1: null,
      country: null,
      comment_body: null,
      copyText: null,
      action: null,
      exp_ic: null,
      user: null,
      comm: false,
      menuModel: [
        {
          label: 'Copy',
          icon: 'pi pi-fw pi-copy',
          command: () => this.copy(this.table_data)
        }
      ],
      uploads: [],
      
      selectedStatus: null,
      statusDropdown: [],
      status_id: 1,
      usaTime: null,
      department: null,
      role: null,
      miscellaneousRefund: [],
      creditCardNumber: null,
      cardName: '',
      expiry_date: null,
      transaction_amount: null,
      transaction_date: null,
      expiry_dateFormat: null,
      transaction_dateFormat: null,
      maxDateValue: null
    };
  },
  mounted() {
    this.getUserName()
    this.getException()
    this.getStatusAll()
    this.getUSATime()
    this.setMaxDate()
  },
  methods: {
    getUSATime() {
      const current_date = new Date().toISOString().slice(0,10)
      this.usaTime = current_date.toLocaleString("en-US", {timeZone: "America/Vancouver"});
    },
    formatCalendarDate(event, id) {
      const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(event);
      const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(event);
      const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(event);
      switch (id) {
        case "transaction_date":
          this.transaction_dateFormat = `${ye}-${mo}-${da}`;
        break;
        case "expiry_date":
          this.expiry_dateFormat = `${ye}-${mo}-${da}`;
        break;
      }
    },
    getStatusAll() {
      axios.get(`api/v1/local/exceptions/status/all`).then(res => {
        res.data.map(val => {
          let statusAll = {
            "id": val.id,
            "status_value": val.status_value
            };
          this.statusDropdown.push(statusAll);
        });
        // remove reassigned and closed status on create ticket
        this.statusDropdown.splice(3, 2);
      });
    },
    onStatusChange(event) {
      this.status_id = event.value.id
    },
    // get all exception type
    getException() {
      axios.get(`api/v1/exception/type/view/category_id/=/5`).then(res => {
        res.data.map(val => {
          let dropdownData = {
            "id": val.id,
            "exception_type": val.exception_message
            };
          this.exc_type.push(dropdownData);
        });
      });
    },
    /**
     * Exception type dropdown
    */
    onDropdownChange(event) {
      this.exc_action = [];
      this.type_id = event.value.id
      axios.get(`api/v1/local/exception/action/type/${this.type_id}`).then(res => {
        res.data.map(val => {
          let dropdownData = {
            "id": val.id,
            "action_name": val.action_name
            };
          this.exc_action.push(dropdownData);
        });
        this.exc_action.splice(1,1)
      })
    },
    // map ticket flow
    ticketMaps() {
      switch (this.status_id) {
        case 1:
          this.department = 'accounting'
        break;
        case 2: case 3:
          this.department = this.role
        break;
      }
      return this.department
    },
    prepareAccJSON() {
      let accJSON = {
        credit_card_number: this.creditCardNumber,
        cardholder_name: this.cardName,
        expiry_date: this.expiry_dateFormat,
        trx_amount: parseFloat(this.transaction_amount),
        trx_date: this.transaction_dateFormat
      };
      this.miscellaneousRefund.push(accJSON);

      return this.miscellaneousRefund
    },
    prepareJSON(obj, department, accExplanation) {
      const datas = [
        {
          order_number: this.table_data[0].order_number,
          order_date: this.table_data[0].record_date,
          dist_id: this.table_data[0].dist_id,
          dist_name_1: this.table_data[0].dist_name_1,
          country: this.table_data[0].country,
          exception_type: this.type_id,
          action_id: this.action_name,
          status: this.status_id,
          created_by: this.user,
          date_created: this.usaTime,
          returned_from: '',
          em_uploads: obj,
          em_comments: this.new_comment_info,
          department: department,
          accounting: accExplanation,
          product_info: [],
          return_label_info: [],
          exception_corrections: [],
          reason_for_cancellation: [],
          product_compliance: [],
          customer_redirect: [],
          customer_reship: []
        }
      ];
      return datas
    },
    myUploader(event){
      document.body.style.cursor = 'wait'
      let uploadedFiles = 0
      for (let i = 0; i < event.files.length; i++) {
        let element = event.files[i]
        let files = element
        let data = new FormData();
        data.append('files', files);
        this.imageUpload(data).then((res) => {
          if(res.code === 200){
            let tmp = {
              "exception_id": this.ID_exc,
              "path": res.path[0]
            }
            this.uploads.push(tmp)
            uploadedFiles++
          }
          if (event.files.length === uploadedFiles) {
            this.submiting(this.uploads)
          }
        })
      }      
    },
    submiting(obj) {
      document.body.style.cursor = 'wait'
      // get department
      const department = this.ticketMaps()

      const accExplanation = this.prepareAccJSON()

      // prepare main JSON
      const DATAS = this.prepareJSON(obj, department, accExplanation)
      
      // Insert this exception
      axios.post(`api/v1/local/exception/insert`, DATAS).then(res => {
        const RESPONSE = res.data[0]
        this.showToast('Exception Created', RESPONSE.message, 'success', 1000)
        document.body.style.cursor = 'default'
        this.back()
      })
    }
  }
}
</script>
