<template>
    <div>
        <div class="mb-3" v-if="this.propsData.action_id === 6">
            <div class="col-3 d-inline-block align-bottom">
                <p>Charge Reshipment Fee</p>
                <SelectButton v-model="reshiprCH" :options="yesNo" disabled/>
            </div>
            <div class="col-3 d-inline-block pr-1" v-if="this.reshiprCH === 'Yes'">
                <p>Reshipment Fee Amount</p>
                <InputText class="col-12" type="text" v-model="reshipTicket" disabled/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        propsData: {
            type: Object
        }
    },
    data() {
        return {
			showTableRelease: false,
            product_infoRELESE: [],
			ticket: this.propsData.claim_ticket_number,
			action: '',
			courierCH: '',
            yesNo: ['Yes', 'No'],
            reshiprCH: '',
            reshipTicket: null
        }
	},
	mounted() {
        this.removeEmpty()
        if (this.propsData.customer_reship[0] !== undefined) {
            this.action = this.propsData.customer_reship[0].claim_action_name
            this.reshipTicket = this.propsData.customer_reship[0].reship_fee_amt
            switch (this.propsData.customer_reship[0].reship_fee) {
                case true:
                    this.reshiprCH = "Yes";
                break;
                case false:
                    this.reshiprCH = "No";
                break;
            }
        }
		switch (this.propsData.claim_approved) {
            case true:
                this.courierCH = "Yes";
            break;
            case false:
                this.courierCH = "No";
            break;
        }		
	},
	methods: {
		removeEmpty() {
            for (let index = 0; index < this.propsData.product_info.length; index++) {
                const element = this.propsData.product_info[index];
                if (element.sku_id === null && element.wrong_sku_id === null){
                this.product_infoRELESE.push(element)
                }
            }
        },
	},
}
</script>