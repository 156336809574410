<template>
  <div class="login-body">
    <div class="login-panel"></div>
    <div class="login-content">
      <img src="assets/layout/images/logo.png" alt="Organo Gold" />
      <h4 class="mb-5 font-weight-light" v-if="showMsg">This is a<span class="text-danger"> {{ env }} </span>environment! </h4>
      <h1><span>SIGN IN</span> TO ORGANO GOLD</h1>
      <p class="mb-3">Welcome, sign-in to.</p>
      <div class="login-input-wrapper">
        <InputText type="text" id="username" v-model="username" placeholder="Username" />
      </div>
      <div class="login-input-wrapper">
        <InputText id="password" v-model="password" type="password" placeholder="Password" @keyup.enter="login" />
      </div>
      <Button label="Sign In" icon="pi pi-check" type="submit" @click="login" :disabled="!username || !password"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      username: null,
      password: null,
      showMsg: false,
      env: process.env.VUE_APP_ENVIRONMENT
    };
  },
  created() {
    /**
      * Remove cookie in route path is login
    */
    $cookies.remove("token");
    localStorage.removeItem('dt-state-local');
  },
  mounted() {
    this.showEnv()
  },
  methods: {
    showEnv() {
      if (process.env.VUE_APP_ENVIRONMENT === 'TEST') (this.showMsg = true)
    },
    login() {
      const formData = {
        username: this.username,
        password: this.password
      };
      /**
        * login method in vuex store
        * This method use vuex for login and send username and password from form 
      */
      this.$store.dispatch("login", {
        username: formData.username,
        password: formData.password
      });
    }
  }
};
</script>
