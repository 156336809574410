<template>
    <div>
        <div class="col-4 d-inline-block mb-3" v-if="showClaimTicket">
            <p>Claim Ticket #</p>
            <InputText class="col-12" type="text" v-model="ticket" disabled />
        </div>
        <div class="col-4 d-inline-block v-top">
            <p>Claim Approved by Courier</p>
            <SelectButton v-model="courierCH" :options="yesNo" disabled/>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        propsData: {
            type: Object
        }
    },
    data() {
        return {
            yesNo: ['Yes', 'No'],
            courierCH: '',
            ticket: this.propsData.claim_ticket_number,
            showClaimTicket: true
        }
    },
    mounted() {
        switch (this.propsData.claim_approved) {
            case true:
                this.courierCH = "Yes";
            break;
            case false:
                this.courierCH = "No";
            break;
        }
        // show claim ticket
        if (this.propsData.exception_type_id === 14 && this.propsData.action_id === 2) {
            this.showClaimTicket = false
        }
    },
}
</script>