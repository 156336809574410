<template>
    <div class="row mt-3" v-if="actionID === 5">
        <div class="col-12">
            <div class="col-3 d-inline-block pr-1 hr">
                <p>Claim Ticket #</p>
                <InputText class="col-12" type="text" v-model="ticket" disabled />
            </div>
            <div class="col-3 d-inline-block pl-4 align-bottom hr">
                <p>Claim Approved by Courier</p>
                <SelectButton v-model="courierCH" :options="yesNo" disabled />
            </div>
            <div class="col-6 d-inline-block hr">
                <p>Claim Action</p>
                <InputText class="col-6" type="text" v-model="action" disabled />
            </div>
        </div>
        <div class="col-12 mt-3">
            <div class="card col-6 d-inline-block" v-if="this.action !== 'Refund'">
                <DataTable :value="product_infoRELESE" class="editable-cells-table release">
                    <template #header>
                    <div class="table-header">
                        <span class="m-auto">Products for Release</span>
                    </div>
                    </template>
                    <Column field="release_sku_id" header="Item ID"></Column>
                    <Column field="release_sku_name" header="Item Name"></Column>
                    <Column field="release_sku_quantity" header="Qty for Release"></Column>
                    <template #empty>
                        No product
                    </template>
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        propsData: {
            type: Object
        }
    },
    data() {
        return {
			showTableRelease: false,
            product_infoRELESE: [],
			ticket: this.propsData.claim_ticket_number,
			action: '',
			courierCH: '',
            yesNo: ['Yes', 'No'],
            reshiprCH: '',
            reshipTicket: null,
            actionID: false
        }
	},
	mounted() {
        if (this.propsData.customer_reship[0] !== undefined) {
            this.action = this.propsData.customer_reship[0].claim_action_name
            this.reshipTicket = this.propsData.customer_reship[0].reship_fee_amt
            switch (this.propsData.customer_reship[0].reship_fee) {
                case true:
                    this.reshiprCH = "Yes";
                break;
                case false:
                    this.reshiprCH = "No";
                break;
            }
        }
        this.actionID = this.propsData.action_id
		this.removeEmpty()
		switch (this.propsData.claim_approved) {
            case true:
                this.courierCH = "Yes";
            break;
            case false:
                this.courierCH = "No";
            break;
        }	
	},
	methods: {
		removeEmpty() {
            for (let index = 0; index < this.propsData.product_info.length; index++) {
                const element = this.propsData.product_info[index];
                if (element.sku_id === null && element.wrong_sku_id === null){
                this.product_infoRELESE.push(element)
                }
            }
        },
	},
}
</script>