<template>
    <div>
        <div class="hr" v-if="showRIO">
            <div class="col-sm-3 d-inline-block">
                <h6>Return Item Options:</h6>
                <SelectButton v-model="rio" :options="rio_options" disabled/>
            </div>
            <div class="col-sm-3 d-inline-block">
                <label>E-mail</label>
                <InputText class="col-sm-12" v-model="e_mail" disabled/>
            </div>
            <div class="col-sm-2 d-inline-block">
                <label for="phone">Phone</label>
                <InputMask class="col-sm-12" mask="(999) 9999-999" v-model="phone" placeholder="(999) 9999-999" disabled/>
            </div>
        </div>
        <div class="mt-4" v-if="rio === '3 Pickup'">
            <h6 class="col-sm-12">Picking up Address:</h6>
            <div class="col-sm-3 d-inline-block mb-2">
                <p>Country/Region</p>
                <InputText class="col-sm-12" type="text" v-model="PUcountry" disabled/>
            </div>
            <div class="col-sm-3 d-inline-block">
                <p>Street Address</p>
                <InputText class="col-sm-12" type="text" v-model="PUaddress" disabled/>
            </div>
            <div class="col-sm-2 d-inline-block">
                <p>City</p>
                <InputText class="col-sm-12" type="text" v-model="PUcity" disabled/>
            </div>
            <div class="col-sm-2 d-inline-block">
                <p>State/Province</p>
                <InputText class="col-sm-12" type="text" v-model="PUstate" disabled/>
            </div>
            <div class="col-sm-2 d-inline-block">
                <p>Zip/Postal Code</p>
                <InputText class="col-sm-12" type="text" v-model="PUzip" disabled/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        propsData: {
            type: Object
        }
    },
    data() {
        return {
            rio_options: ['E-Label', '3 Pickup'],
            rio: '',
            e_mail: this.propsData.email,
            phone: this.propsData.phone_number,
            PUcountry: this.propsData.pickup_country,
            PUaddress: this.propsData.pickup_street_address,
            PUcity: this.propsData.pickup_city,
            PUstate: this.propsData.pickup_state,
            PUzip: this.propsData.pickup_zip_code,
            showRIO: false
        }
    },
    mounted() {
        this.setButton()
        this.render()
    },
    methods: {
        setButton() {
            // set return item option button
            switch (this.propsData.return_item_option) {
                case true:
                    this.rio = "E-Label";
                break;
                case false:
                    this.rio = "3 Pickup";
                break;
            }
        },
        render() {
            switch (this.propsData.action_id) {
                case 1: case 4:
                    this.showRIO = true
                break;
            }
        }
    },
}
</script>