<template>
    <div class="col-sm-3" v-if="showPaymentEmail">
        <label class="d-block">Email for Payment</label>
        <InputText class="col-sm-12 mb-2" v-model="payment_email" placeholder="payment@email.com" disabled/>
    </div>
</template>

<script>
export default {
    props: {
        propsData: {
            type: Object
        }
    },
    data() {
        return {
            payment_email: this.propsData.payment_email,
            showPaymentEmail: false
        }
    },
    mounted() {
        this.render()
    },
    methods: {
        render() {
            switch (this.propsData.action_id) {
                case 2: case 3: case 6:
                    this.showPaymentEmail = true
                break;
            }
            // for this two type this section show on action 4
            if (this.propsData.exception_type_id === 14 || this.propsData.exception_type_id === 18) {
                switch (this.propsData.action_id) {
                    case 4:
                        this.showPaymentEmail = true
                    break;
                }
            }
        }
    },
}
</script>