<template>
    <div>
        <div class="mt-4" v-if="this.propsData.action_id === 1 || this.refund === 2">
            <div class="col-12">
                <h6>For Accounting</h6>
                <div class="row col-12 mb-3">
                    <div class="col-3 px-0">
                        <p>Check Refund</p>
                        <SelectButton v-model="check_refund" :options="yesNo" disabled/>
                    </div>
                    <div class="col-3 pr-0" v-if="check_refund == 'Yes'">
                        <p>Amount</p>
                        <InputText class="col-12" v-model="checkAmount" disabled/>
                    </div>
                </div>
                <div class="row col-12 mb-3" v-if="check_refund == 'Yes'">
                    <div class="col-3 px-0">
                        <p>Charge Processing Fee</p>
                        <SelectButton v-model="charge_processing_fee" :options="yesNo" disabled/>
                    </div>
                    <div class="col-3 pr-0">
                        <p>Check Payee Name</p>
                        <InputText class="col-12" v-model="dist_name_1" disabled />
                    </div>
                </div>
                <div class="row my-4" v-if="check_refund == 'Yes'">
                    <h6 class="col-sm-12">Mailing Address:</h6>
                    <div class="col-sm-3 d-inline-block">
                        <p>Country/Region:</p>
                        <InputText class="col-sm-12" v-model="check_country" disabled/>
                    </div>
                    <div class="col-sm-3 d-inline-block">
                        <p>Street Address:</p>
                        <InputText class="col-sm-12" v-model="check_address" disabled/>
                    </div>
                    <div class="col-sm-2 d-inline-block">
                        <p>City:</p>
                        <InputText class="col-sm-12" v-model="check_city" disabled/>
                    </div>
                    <div class="col-sm-2 d-inline-block">
                        <p>State/Province:</p>
                        <InputText class="col-sm-12" v-model="check_state" disabled/>
                    </div>
                    <div class="col-sm-2 d-inline-block">
                        <p>Zip/Postal Code:</p>
                        <InputText class="col-sm-12" v-model="check_zip" disabled/>
                    </div>
                </div>
            </div>
        </div>
        <!-- For Acc Section  -->
        <hr v-if="check_refund == 'Yes'">
        <div class="row" v-if="check_refund == 'Yes'">
            <div class="col-12">
                <h6 class="col-12">Accounting</h6>
                <div class="mb-3">
                    <div class="col-3 d-inline-block">
                        <p>Check Amount</p>
                        <InputText class="col-12" v-model="checkAmountACC"  disabled/>
                    </div>
                    <div class="col-3 d-inline-block">
                        <p>Check Number</p>
                        <InputText class="col-12" v-model="checkNumberACC" disabled/>
                    </div>
                    <div class="col-3 d-inline-block">
                        <p>Check Date</p>
                        <Calendar id="checkDate" v-model="checkDate" :showIcon="true" dateFormat="yy-mm-dd" disabled/>
                    </div>
                </div>
                <div class="col-12 my-4">
                    <div class="col-3 d-inline-block pl-0">
                        <p>Processed by Accounting</p>
                        <SelectButton v-model="acc_processed" :options="yesNo" disabled/>
                    </div>
                    <div class="col-3 d-inline-block align-top pl-1">
                        <p>Accounting Date Processed</p>
                        <Calendar id="processedDate" v-model="processedDate" :showIcon="true" dateFormat="yy-mm-dd" disabled/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        propsData: {
            type: Object
        }
    },
    data() {
        return {
            refund: '',
            yesNo: ['Yes', 'No'],
            check_refund: this.propsData.accounting[0].check_refund,
            checkAmount: this.propsData.accounting[0].check_amount,
            charge_processing_fee: this.propsData.accounting[0].charge_processing_fee,
            dist_name_1: this.propsData.dist_name_1,
            check_country: this.propsData.accounting[0].check_country,
            check_address: this.propsData.accounting[0].check_address,
            check_city: this.propsData.accounting[0].check_city,
            check_state: this.propsData.accounting[0].check_state,
            check_zip: this.propsData.accounting[0].check_zip,
            checkAmountACC: this.propsData.accounting[0].acc_check_amount,
            checkNumberACC: this.propsData.accounting[0].check_number,
            checkDate: this.propsData.accounting[0].check_date,
            acc_processed: this.propsData.accounting[0].acc_processed,
            processedDate: this.propsData.accounting[0].acc_date_processed,
        }
    },
    mounted() {
        if (this.propsData.customer_reship[0] !== undefined) {
            this.refund = this.propsData.customer_reship[0].claim_action_id
        }
        const accounting = this.propsData.accounting[0]
        // loop from accounting object and set yes/no for selected button 
        Object.keys(accounting).forEach((key) => {
            switch (accounting[key]) {
            case true:
                this[key] = 'Yes';
            break;
            case false:
                this[key] = 'No';
            break;
            }
        });
    },
}
</script>