<template>
    <div class="layout-dashboard">
        <div class="p-grid">
            <wrongProduct v-if="wrong" />
            <damagedProduct v-if="dmg" />
            <shortageProduct v-if="shortAge" />
            <neverReceivedProduct v-if="neverRP" />
            <orderCancellation v-if="orderCC" />
            <productExchange v-if="exchange" />
            <defectiveProduct v-if="defective"/>
            <adverse v-if="adverse"/>
            <systemForm v-if="system" />
            <miscellaneous v-if="miscellaneous"/>
            <payment v-if="payment"/>
            <customerRedirect v-if="customerRedirect"/>
        </div>
    </div>
</template>

<script>

// import ticket views
import wrongProduct from "../../components/form/wrongProduct/view.vue";
import damagedProduct from "../../components/form/damagedProduct/view.vue";
import shortageProduct from "../../components/form/shortageProduct/view.vue";
import neverReceivedProduct from "../../components/form/neverReceived/view.vue";
import orderCancellation from "../../components/form/orderCancellation/view.vue";
import productExchange from "../../components/form/productExchange/view.vue";
import defectiveProduct from "../../components/form/defectiveProduct/view.vue";
import adverse from "../../components/form/adverseEvent/view.vue";
import miscellaneous from "../../components/form/miscellaneous/view.vue";
import payment from "../../components/form/payment/view.vue";
import customerRedirect from "../../components/form/customerRedirect/view.vue";

import systemForm from "../../components/form/system/view.vue";

export default {
    data() {
        return {
            dmg: false,
            shortAge: false,
            neverRP: false,
            orderCC: false,
            exchange: false,
            wrong: false,
            defective: false,
            adverse: false,
            system: false,
            miscellaneous: false,
            payment: false,
            customerRedirect: false
        }
    },
    components: {
        damagedProduct,
        shortageProduct,
        neverReceivedProduct,
        orderCancellation,
        productExchange,
        wrongProduct,
        defectiveProduct,
        adverse,
        systemForm,
        miscellaneous,
        payment,
        customerRedirect
    },
    mounted() {
        this.loadView()
    },
    methods: {
        /**
         * Show component base on exception type
        */
        loadView() {
            const exType = parseInt(this.$route.query.exType)
            switch(exType) {
            case 13:
                this.wrong = true
                break;
            case 14:
                this.dmg = true
                break;
            case 15:
                this.shortAge = true
                break;
            case 16:
                this.neverRP = true
                break;
            case 17:
                this.orderCC = true
                break;
            case 18:
                this.exchange = true
                break;
            case 19:
                this.defective = true
                break;
            case 20:
                this.adverse = true
                break;
            case 23:
                this.payment = true
                break;
            case 24:
                this.miscellaneous = true
                break;
            case 27:
                this.customerRedirect = true
                break;
            default:
                this.system = true
            }
        }
    },
}
</script>
