<template>
    <div>
      <ContextMenu :model="menuModel" ref="cm" />
      <div class="p-grid">
        <div class="p-col-6">
          <div class="card card-w-title border-none">
            <DataTable :value="table_data" class="p-datatable-responsive" :rows="20" contextMenu :contextMenuSelection.sync="cMenu" @row-contextmenu="onRowContextMenu" :lazy="true" :loading="loading">
              <template #header>Transaction Details</template>
              <Column field="order_number" v-model="order_number" header="Order Number"></Column>
              <Column field="order_date" v-model="order_date" header="Order Date"></Column>
              <Column field="dist_id" v-model="dist_id" header="Distributor ID"></Column>
              <Column field="dist_name_1" v-model="dist_name_1" header="Distributor’s Name"></Column>
              <Column field="country" v-model="country" header="Country"></Column>
            </DataTable>
          </div>
        </div>
        <div class="p-col-6">
          <Accordion class="card card-w-title border-none p-field p-col-12 p-md-12 accordion-custom">
            <AccordionTab header="Add New Comment">
              <div class="p-datatable card card-w-title border-none p-field p-col-12 p-md-12 float-r" >
                <h4 class="p-datatable-header">Reason/Comment</h4>
                <Textarea id="txt" :value="textareaValue" :autoResize="true" rows="5" cols="30" class="w-inherit" />
              </div>
              <Button label="Save" class="m-2 float-right" @click="saveCommentInView"/>
              <div class="card card-w-title border-none p-field p-col-12 p-md-12">
                <DataTable class="comment_table" :value="new_comment_info_table">
                  <template #header>Reason/Comment Preview</template>
                  <Column field="comment_date" header="Date"></Column>
                  <Column field="comment_user" header="User"></Column>
                  <Column field="comment_body" header="Comment"></Column>
                  <Column bodyStyle="text-align: center" >
                    <template #header></template>
                    <template #body="slotProps">
                      <Button type="button" icon="pi pi-trash" :slotProps="slotProps" @click="deleteComment(slotProps.data.row_id)" class="center"></Button>
                    </template>
                  </Column>
                  <template #empty>
                    No new comments
                  </template>
                </DataTable>
              </div>
            </AccordionTab>
          </Accordion>
          <Accordion class="card card-w-title border-none p-field p-col-12 p-md-12 accordion-custom" v-if="comm">
            <AccordionTab header="Comment History">
                <DataTable class="comment_table" :value="comment_info" sortField="id" :sortOrder="-1">
                  <Column field="comment_date" sortable header="Date"></Column>
                  <Column field="comment_user" sortable header="User"></Column>
                  <Column field="comment_body" sortable header="Comment"></Column>
                </DataTable>
            </AccordionTab>
          </Accordion>
          <Accordion class="card card-w-title border-none p-field p-col-12 p-md-12 accordion-custom" v-if="filesForDownLoad">
            <AccordionTab header="Uploaded Files">
                <ul class="p-0" v-for="dwn in dwn_link" :key="dwn.paths">
                  <a :href="dwn.path">{{dwn.path.split("/")[5]}}</a>
                </ul>
            </AccordionTab>
          </Accordion>
          <div class="card card-w-title border-none p-field p-col-12 p-md-12">
            <FileUpload 
            name="files" 
            chooseLabel="Upload" 
            :multiple="true"
            :customUpload="true"
            @uploader="myUploader"
            accept=".csv, .xlx, .xlsx, .pdf, .doc, .txt, .jpg, .jpeg, .png, .docx, .PNG, .JPG, .JPEG" 
            :maxFileSize="10000000" >
            <template #empty>
              <p>Drag and drop files here to upload.</p>
            </template>
          </FileUpload>
          </div>
        </div>
        <div class="col-12 mt-2">
          <Button class="float-right" label="Submit" @click="submitForm" />
        </div>
      </div>
    </div>
</template>

<script>
import mainMethods from '../../../store/mainMethods.js'
import submitMethods from '../../../store/onSubmitActions.js'

export default {
    mixins: [ mainMethods, submitMethods ],
    props: {
        propsData: {
          type: Object
        },
        propsMainData: {
          type: Array
        }
    },
    data() {
        return {
          table_data: this.propsMainData,
          order_number: this.propsData.order_number,
          order_date: this.propsData.order_date,
          dist_id: this.propsData.dist_id,
          dist_name_1: this.propsData.dist_name_1,
          country: this.propsData.country,
          comment_info: this.propsData.em_comments,
          loading: false,
          cMenu: {},
          dwn_link: [],
          files: [],
          uploads: [],
          comm: false,
          filesForDownLoad: false,
          usaTime: null,
          textareaValue: '',
          new_comment_info: [],
          new_comment_info_table: [],
          
          menuModel: [
            {
              label: 'Copy', 
              icon: 'pi pi-fw pi-copy', 
              command: () => this.copy(this.table_data)
            }
          ],
        }
    },
    mounted() {
      this.showUploads(this.propsData)
      this.getUserName()
      this.getUSADate()
    },
    methods: {
      showUploads(DATA) {
        // show comment box
        DATA.em_comments.length > 0 ? this.comm = true : this.comm = false; // jshint ignore:line
        // show upload box
        if (DATA.em_upload.length > 0) {
          this.filesForDownLoad = true;
        // show all uploaded files
          for (let index = 0; index < DATA.em_upload.length; index++) {
            const element = DATA.em_upload[index];
            this.dwn_link.push(element);
          }
        }
      },
      // set canada date
      getUSADate() {
        const current_date = new Date().toISOString().slice(0,10)
        this.usaTime = current_date.toLocaleString("en-US", {timeZone: "America/Vancouver"})
      },
      saveCommentInView() {
        const txt = document.getElementById('txt')
        // get value from textarea
        if (txt.value) {
          txt.value.replace(/'/g, "\\'")
          // data for send
          this.new_comment_info.push(
            {
              "exception_id": this.propsData.id,
              "comment_body": txt.value,
              "comment_user": this.role.slice(0, 3).toUpperCase() + " " + this.user,
              "comment_date": this.usaTime
            }
          );
          // data for show in table
          this.new_comment_info_table.push(
            {
              "row_id": this.new_comment_info_table.length,
              "exception_id": this.propsData.id,
              "comment_body": txt.value,
              "comment_user": this.role.slice(0, 3).toUpperCase() + " " + this.user,
              "comment_date": this.usaTime
            }
          );
          // clear textarea after save comment
          txt.value = ""
        }
      },
      myUploader(event){
        document.body.style.cursor = 'wait'
      let uploadedFiles = 0
      for (let i = 0; i < event.files.length; i++) {
        let element = event.files[i]
        let files = element
        let data = new FormData();
        data.append('files', files);
        this.imageUpload(data).then((res) => {
          if(res.code === 200){
            let tmp = {
              "exception_id": this.propsData.id,
              "path": res.path[0]
            }
            this.uploads.push(tmp)
            uploadedFiles++
          }
          if (event.files.length === uploadedFiles) {
            this.submiting(this.uploads)
          }
        })
      }      
    },
      submiting(obj) {
        document.body.style.cursor = 'wait'
        // Update comments if not empty
        if (this.new_comment_info.length > 0) (this.uploadComment())

        // Update file if not empty
        if (obj.length > 0) (this.uploadFiles(obj))
        document.body.style.cursor = 'default'
        this.back()
      }
    }
}
</script>