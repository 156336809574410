import SockJS from 'sockjs-client';
import Stomp from 'stompjs';

const Emlive = (function() {
    let usersAtPageLoad = [];
    let self = {};

    const init = (options) => {
        initParams(options);
        initDefaultEvents();
        initEvents(options);
        connect();
    };

    const initParams = (options) => {
        options = options || {};
        assignRequiredProperty(options, self, "baseUrl");
        assignRequiredProperty(options, self, "pageId");
        assignRequiredProperty(options, self, "userId");
        assignRequiredProperty(options, self, "token");
        self.socketUrl = self.baseUrl + "/ws";
    };

    const initDefaultEvents = () => {
        self.events = {};
        const addChatMessage = (userName, message, date) => {};
        self.events.addChatMessage = addChatMessage;

        const addOnlineItem = (userId) => {};
        self.events.addOnlineItem = addOnlineItem;

        const removeOnlineItem = (userId) => {};
        self.events.removeOnlineItem = removeOnlineItem;

        const afterConnect = () => {};
        self.events.afterConnect = afterConnect;

        const pageLocked = () => {};
        self.events.pageLocked = pageLocked;

        const pageUnlocked = () => {};
        self.events.pageUnlocked = pageUnlocked;

        const displayOnlineUsers = (users) => {
            for (var i = 0, len = users.length; i < len; i++) {}
        };
        self.events.displayOnlineUsers = displayOnlineUsers;
    };

    const initEvents = (options) => {
        options = options || {};
        if(options.hasOwnProperty('events')) {
            let events = options.events;
            assignIfHasOwnProperty(events, self.events, "addChatMessage");
            assignIfHasOwnProperty(events, self.events, "addOnlineItem");
            assignIfHasOwnProperty(events, self.events, "removeOnlineItem");
            assignIfHasOwnProperty(events, self.events, "afterConnect");
            assignIfHasOwnProperty(events, self.events, "pageLocked");
            assignIfHasOwnProperty(events, self.events, "pageUnlocked");
            assignIfHasOwnProperty(events, self.events, "displayOnlineUsers");
        }
    };

    const assignIfHasOwnProperty = (source, target, property) => {
        if(source.hasOwnProperty(property)) {
            target[property] = source[property];
        }
    };

    const assignRequiredProperty = (source, target, property) => {
        if(!source.hasOwnProperty(property)) {
            throw property + " param is required";
        }
        assignIfHasOwnProperty(source, target, property);
    };

    const connect = () => {
        self.socket = new SockJS(self.socketUrl + '?token=' + self.token);
        self.stompClient = Stomp.over(self.socket);
        self.stompClient.debug = null;
        self.stompClient.connect({}, connectHandler, failureHandler);
    };

    const disconnect = () => {
        if (self.stompClient !== null) {
            self.stompClient.disconnect();
        }
    };

    const connectHandler = () => {
        getOnlineUsers();
        connectHandlerChat();
        connectHandlerOnline();
        connectHandlerExit();
        connectHandlerDisconnect();
        self.events.afterConnect();
    };

    const failureHandler = (error) => {
        setTimeout(connect, 10000);
    };

    const connectHandlerChat = () => {
        self.stompClient.subscribe('/topic/chat/' + self.pageId, function(response) {
            var responseObj = JSON.parse(response.body);
            self.events.addChatMessage(responseObj.userName, responseObj.message, responseObj.date);
        });
    };

    const connectHandlerOnline = () => {
        self.stompClient.subscribe('/topic/pagevisit/' + self.pageId, function(response) {
            var responseObj = JSON.parse(response.body);
            if (self.userId != responseObj.userId) {
                self.events.addOnlineItem(responseObj.userId);
            }
        });
    };

    const unlockChecker = (userId) => {
        if (usersAtPageLoad.length) {
            var index = usersAtPageLoad.indexOf(userId);
            usersAtPageLoad.splice(index, 1);
            if (!usersAtPageLoad.length) {
                self.events.pageUnlocked();
            }
        }
    };

    const connectHandlerExit = () => {
        self.stompClient.subscribe('/topic/pageexit/' + self.pageId, function(response) {
            var responseObj = JSON.parse(response.body);
            unlockChecker();
            self.events.removeOnlineItem(responseObj.userId);
        });
    };

    const connectHandlerDisconnect = () => {
        self.stompClient.subscribe('/topic/disconnect', function(response) {
            var responseObj = JSON.parse(response.body);
            unlockChecker();
            self.events.removeOnlineItem(responseObj.userId);
        });
    };

    const sendChatMessage = (message) => {
        message = message.trim();
        if (message) {
            const messageObj = JSON.stringify({
                'pageId': self.pageId,
                'message': message
            });
            self.stompClient.send("/app/chat/" + self.pageId, {}, messageObj);
        }
    };

    const getOnlineUsers = () => {
        let url = self.baseUrl + '/api/users/' + self.pageId;
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', 'Bearer ' + self.token);
        fetch(url, {
                method: 'GET',
                headers: headers
        })
            .then(response => response.json())
            .then(json => {
                self.events.displayOnlineUsers(json);
                if (json.length) {
                    self.events.pageLocked();
                    usersAtPageLoad = json;
                }
            });
    };

    const destroyConnection = () => {
        if (self.stompClient !== null && self.stompClient !== 'undefined') {
            self.stompClient.disconnect();
        }
    };

    return {
        init,
        sendChatMessage,
        destroyConnection
    };
})();

export default Emlive;
