<template>
    <div class="col-12">
        <div class="row mb-1 mt-3">
            <div class="col-4 d-inline-block">
                <h6>Exception Type</h6>
                <InputText class="col-12" type="text" v-model="exc_type" disabled />
            </div>
            <div class="col-4 d-inline-block">
                <h6>Action</h6>
                <InputText class="col-12" type="text" v-model="exc_action" disabled />
            </div>
            <div class="col-4 d-inline-block">
                <h6>Date</h6>
                <Calendar id="icon" v-model="current_date" :showIcon="true" dateFormat="dd/mm/yy" disabled />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        propsData: {
            type: Object
        }
    },
    data() {
        return {
            exc_type: this.propsData.exception_message,
            exc_action: this.propsData.action_name,
            current_date: this.propsData.date_created
        }
    },
}
</script>