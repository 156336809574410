<template>
    <div>
        <div class="col-sm-3 mt-3" v-if="this.propsData.action_id === 12">
            <label class="d-block">Email for Payment</label>
            <InputText class="col-sm-12 mb-2" v-model="this.propsData.email" placeholder="payment@email.com" disabled/>
        </div>
        <div class="shipping-box my-4 hr">
            <h6 class="col-sm-12">{{ shippingTitle }}</h6>
            <div class="col-sm-3 d-inline-block mb-2">
                <p>Country/Region:</p>
                <InputText class="col-sm-12" v-model="new_country" disabled />
            </div>
            <div class="col-sm-3 d-inline-block">
                <p>Street Address:</p>
                <InputText class="col-sm-12" v-model="new_address" disabled />
            </div>
            <div class="col-sm-2 d-inline-block">
                <p>City:</p>
                <InputText class="col-sm-12" v-model="new_city" disabled />
            </div>
            <div class="col-sm-2 d-inline-block">
                <p>State/Province:</p>
                <InputText class="col-sm-12" v-model="new_state" disabled />
            </div>
            <div class="col-sm-2 d-inline-block">
                <p>Zip/Postal Code:</p>
                <InputText class="col-sm-12" v-model="new_zip" disabled />
            </div>
        </div>
        <div class="col-12 my-4 hr">
            <h5>Shipping And Returns</h5>
        </div>
        <div v-if="this.propsData.action_id === 13">
            <h6 class="col-sm-12">Customer's Information</h6>
            <div class="col-sm-2 d-inline-block align-bottom mb-2">
                <label for="phone" class="d-block">Phone</label>
                <InputMask
                    class="col-sm-12"
                    mask="(999) 9999-999"
                    v-model="phone"
                    placeholder="(999) 9999-999"
                    disabled
                />
            </div>
            <div class="col-sm-2 d-inline-block align-bottom mb-2">
                <label for="e_mail" class="d-block">E-mail</label>
                <InputText
                    class="col-sm-12"
                    id="e_mail"
                    v-model="e_mail"
                    placeholder="max@max.com"
                    disabled
                />
            </div>
        </div>

        <div v-if="this.propsData.action_id === 12">
            <div class="col-sm-3 d-inline-block mb-4">
                <p>Waive intercept fee</p>
                <SelectButton v-model="intercept" :options="yesNo" disabled />
            </div>
            <div class="col-sm-3 d-inline-block v-top" v-if="this.intercept === 'No'">
                <p>Intercept Amount</p>
                <InputText
                    class="col-sm-12"
                    type="text"
                    v-model="intercept_amt"
                    disabled
                />
            </div>
        </div>

        <div v-if="this.propsData.action_id === 13">
            <div class="shipping-box my-4">
                <h6 class="col-sm-12 mb-3">Pick up Location Information</h6>
                <div class="col-sm-3 d-inline-block mb-2">
                    <p>Pick up Location:</p>
                    <InputText class="col-sm-12" v-model="ups_name" disabled />
                </div>
                <div class="col-sm-3 d-inline-block">
                    <p>Street Address:</p>
                    <InputText
                        class="col-sm-12"
                        v-model="ups_address"
                        disabled
                    />
                </div>
                <div class="col-sm-2 d-inline-block">
                    <p>City:</p>
                    <InputText class="col-sm-12" v-model="ups_city" disabled />
                </div>
                <div class="col-sm-2 d-inline-block">
                    <p>State/Province:</p>
                    <InputText class="col-sm-12" v-model="ups_state" disabled />
                </div>
                <div class="col-2 d-inline-block">
                    <p>Package Available Date</p>
                    <Calendar
                        v-model="available"
                        :showIcon="true"
                        :manualInput="false"
                        dateFormat="yy-mm-dd"
                        disabled
                    />
                </div>

                <div class="col-sm-3 d-inline-block mt-3">
                    <label for="phone" class="d-block">Phone</label>
                    <InputMask
                        class="col-sm-12"
                        mask="(999) 9999-999"
                        v-model="ups_phone"
                        placeholder="(999) 9999-999"
                        disabled
                    />
                </div>
                <div class="col-sm-6 d-inline-block">
                    <p>Hours of operation</p>
                    <InputText class="col-sm-12" v-model="ups_hours" disabled />
                </div>
                <div class="col-2 offset-1 d-inline-block">
                    <p>Pickup by Date</p>
                    <Calendar
                        v-model="pickup_date"
                        :showIcon="true"
                        :manualInput="false"
                        dateFormat="yy-mm-dd"
                        disabled
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import customer from '../../../store/customer.js';

export default {
    mixins: [customer],
    props: {
        propsData: {
            type: Object
        }
    },
    data() {
        return {
            newShipping: true,
            new_country: '',
            new_address: '',
            new_city: '',
            new_state: '',
            new_zip: '',
            customerJSON: [],
            intercept: 'Yes',
            intercept_amt: null,
            yesNo: ['Yes', 'No'],
            customerID: null,
            redirect_intercept_fee: null,
            phone: null,
            e_mail: null,
            error: false,
            ups_name: '',
            ups_address: '',
            ups_city: '',
            ups_state: '',
            available: null,
            pickup_date: null,
            ups_phone: '',
            ups_hours: '',
            maxDateValue: null,
            availableDatePickup: true,
            shippingTitle: 'New Shipping Address:'
        };
    },
    mounted() {
        this.propsData.customer_redirect[0] !== undefined &&
            this.populateDataCustomer(this.propsData);
        this.shippingAddress(this.propsData)
    },
    methods: {
        shippingAddress(data) {
            this.shippingTitle = 'Shipping Address'
            this.new_country = data.country,
            this.new_address = data.street_address,
            this.new_city = data.city,
            this.new_state = data.state,
            this.new_zip = data.zip_code
        }
    },
};
</script>
