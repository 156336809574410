<template>
    <div>
        <div class="p-grid">
            <backBtn />
        </div>
        <div class="p-grid">
            <div class="p-col-6">
                <div class="card card-w-title pt-2 ">
                <DataTable :value="table_data" class="p-datatable-responsive" :rows="20" :lazy="true" :loading="loading">
                    <template #header>Distributor Details</template>
                    <Column field="dist_id" header="Distributor ID"></Column>
                    <Column field="dist_name" header="Distributor’s Name"></Column>
                    <Column field="country" header="Country"></Column>
                </DataTable>
                </div>
            </div>
            <div class="p-col-6">
                <Accordion class="card card-w-title p-field p-col-12 p-md-12 accordion-custom">
                    <AccordionTab header="Add New Comment">
                        <div class="p-datatable card card-w-title p-field p-col-12 p-md-12 float-r" >
                        <h4 class="p-datatable-header">Reason/Comment</h4>
                        <Textarea id="txt" :value="value" :autoResize="true" rows="5" cols="30" class="w-inherit" />
                        </div>
                        <Button label="Save" class="m-2 float-right" @click="saveComment"/>
                        <div class="card card-w-title p-field p-col-12 p-md-12">
                        <DataTable class="comment_table" :value="new_comment_info_table">
                            <template #header>Reason/Comment Preview</template>
                            <Column field="comment_date" header="Date"></Column>
                            <Column field="comment_user" header="User"></Column>
                            <Column field="comment" header="Comment"></Column>
                            <Column bodyStyle="text-align: center" >
                                <template #header></template>
                                <template #body="slotProps">
                                <Button type="button" icon="pi pi-trash" :slotProps="slotProps" @click="deleteComment(slotProps.data.row_id)" class="center"></Button>
                                </template>
                            </Column>
                            <template #empty>
                            No new comments
                            </template>
                        </DataTable>
                        </div>
                    </AccordionTab>
                </Accordion>
                <Accordion class="card card-w-title p-field p-col-12 p-md-12 accordion-custom" v-if="this.comment_info.length > 0">
                    <AccordionTab header="Comment History">
                        <DataTable class="comment_table" :value="comment_info" sortField="id" :sortOrder="-1">
                        <Column field="comment_date" sortable header="Date"></Column>
                        <Column field="comment_user" sortable header="User"></Column>
                        <Column field="comment" sortable header="Comment"></Column>
                        </DataTable>
                    </AccordionTab>
                </Accordion>
                <Accordion class="card card-w-title border-none p-field p-col-12 p-md-12 accordion-custom" v-if="filesForDownLoad">
                    <AccordionTab header="Uploaded Files">
                        <ul class="p-0" v-for="dwn in dwn_link" :key="dwn.paths">
                        <a :href="dwn.path">{{dwn.path.split("/")[5]}}</a>
                        </ul>
                    </AccordionTab>
                </Accordion>
                <div class="card card-w-title p-field p-col-12 p-md-12">
                    <FileUpload 
                        name="files" 
                        chooseLabel="Upload" 
                        :multiple="true"
                        :customUpload="true"
                        @uploader="myUploader"
                        accept=".csv, .xlx, .xlsx, .pdf, .doc, .txt, .jpg, .jpeg, .png, .docx, .PNG, .JPG, .JPEG" 
                        :maxFileSize="10000000" >
                        <template #empty>
                        <p>Drag and drop files here to upload.</p>
                        </template>
                    </FileUpload>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="col-3 d-inline-block">
                    <label>Customer Inquiry</label>
                    <Dropdown 
                        class="col-12" 
                        v-model="selectedType" 
                        :options="exc_type" 
                        optionLabel="inquiry_type" 
                        disabled
                        :placeholder="selectedTypePlaceholder" 
                        @change="onDropdownChange" />
                </div>
                <div class="col-3 d-inline-block align-top">
                    <p>Escalation Needed</p>
                    <SelectButton v-model="escalation" :options="yesNo" @input="escalationBTN"/>
                </div>
                <div class="col-3 mt-2" v-if="escalation === 'Yes'">
                    <label for="icon">Due Date</label>
                    <Calendar 
                        id="icon" 
                        v-model="dueDate" 
                        :showIcon="true" 
                        dateFormat="yy/mm/dd" 
                        :manualInput="false" 
                        :minDate="minDate" 
                        @date-select="formatCalendar($event)"/>
                </div>
            </div>
            <div class="col-12 mt-2" v-if="this.selectedTypePlaceholder === 'Other'">
                <div class="col-3">
                    <p>Other</p>
                    <Textarea class="col-12" v-model="other" :autoResize="true" rows="5" cols="30" />
                </div>
            </div>
        </div>
        <div class="col-sm-3 my-2" v-if="this.showOther === 'OG Pay - Education' || this.showOther === 'OG Pay - Issues'">
            <label class="d-block">Mobile Wallet</label>
            <InputText v-model="mobile_wallet" class="w-100" @input="wallet()"/>
        </div>
        <div class="row col-12 status mt-3">
            <div class="col-sm-3">
                <h6>Status</h6>
                <Dropdown 
                    class="col-12" 
                    v-model="selectedStatus" 
                    :options="statusDropdown" 
                    optionLabel="status_value" 
                    :placeholder="selectedStatusPlaceholder" 
                    @change="onStatusChange" />
            </div>
        </div>
        <div class="row">
            <div class="col-12 my-2">
                <Button class="float-right mr-2" label="Submit" @click="submitForm" />
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
// import methods from mixins
import mainMethods from '../../store/mainMethods.js'
// component
import backBtn from '../html/back_button.vue'

export default {
    mixins: [mainMethods],
    components: {
        backBtn
    },
    data() {
        return {
            inquiry_type_id: null,
            mobile_wallet: null,
            table_data: [],
            empty_dist_id: 123456789,
            exc_type: [],
            selectedType: null,
            new_comment_info:[],
            new_comment_info_table: [],
            comment_info: [],
            files: [],
            value: null,
            user: null,
            comm: false,
            uploads: [],
            yesNo: ['Yes', 'No'],
            selectedStatus: null,
            statusDropdown: [],
            escalation: '',
            status_id: 1,
            usaTime: null,
            escalation_status: null,
            country: null,
            dist_id: null,
            dist_name: null,
            dist_name_2: null,
            iso2: null,
            iso3: null,
            loading: false,
            selectedTypePlaceholder: '',
            selectedStatusPlaceholder: '',
            other: '',
            minDate: null,
            dueDate: null,
            filesForDownLoad: false,
            dwn_link: [],
            created_by: ''
        }
    },
    mounted() {
        this.getUserName()
        this.getException()
        this.getStatusAll()
        this.getUSATime()
        this.getData()
    },
    methods: {
		getData() {
			this.loading = true
            axios.get(`api/v1/inquiries/read/by-id`, {
                params: {
                    id: this.$route.params.id
                }
            }).then(res => {
                this.loading = false
                this.table_data.push(res.data)
                this.created_by = res.data.created_by
                this.country = res.data.country
                this.dist_id = res.data.dist_id
                this.dist_name = res.data.dist_name
                this.dist_name_2 = res.data.dist_name_2
                this.iso2 = res.data.iso2
                this.iso3 = res.data.iso3
                this.inquiry_type_id = res.data.inquiry_type_id
                this.escalation_status = res.data.escalation_status
                this.selectedTypePlaceholder = res.data.inquiry_type.inquiry_type
                this.status_id = res.data.status_id
                this.comment_info = res.data.comments
                this.other = res.data.Inquiry_type_other
                this.dueDate = res.data.due_date
                this.mobile_wallet = res.data.mobile_wallet

                this.showUploads(res.data)

                if (res.data.escalation_status === true) {
                    this.escalation = 'Yes'
                } else  if (res.data.escalation_status === false) {
                    this.escalation = 'No'
                }

                switch (res.data.status_id) {
                    case 1:
                        this.selectedStatusPlaceholder = "Open"
                    break;
                    case 2:
                        this.selectedStatusPlaceholder = "In Progress"
                    break;
                    case 3:
                        this.selectedStatusPlaceholder = "Pending"
                    break;
                    case 4:
                        this.selectedStatusPlaceholder = "Closed"
                    break;
                }
            })
        },
        showUploads(DATA) {
            if (DATA.uploads.length > 0) {
                this.filesForDownLoad = true
                for (let index = 0; index < DATA.uploads.length; index++) {
                    const element = DATA.uploads[index];
                    this.dwn_link.push(element);
                }
            }
        },
        getUSATime() {
            const current_date = new Date().toISOString().slice(0,10)
            this.usaTime = current_date.toLocaleString("en-US", {timeZone: "America/Vancouver"});
        },
        wallet() {
            this.mobile_wallet = this.mobile_wallet.replace(/[^\d]/,'')
            if (this.mobile_wallet.length > 50) {
                this.mobile_wallet = this.mobile_wallet.substring(0, 50);
            }
        },
		saveComment() {
			const txt = document.getElementById('txt');
			// get value from textarea
			if (txt.value) {
                txt.value.replace(/'/g, "\\'");
                // data for send
                this.new_comment_info = 
                    {
                        "inquiry_id": +this.$route.params.id,
                        "comment": txt.value,
                        "comment_user": this.user,
                        "comment_date": this.usaTime
                    }
                // data for show in table
                this.new_comment_info_table.push(
                    {
                        "row_id": this.new_comment_info_table.length,
                        "inquiry_id": this.$route.params.id,
                        "comment": txt.value,
                        "comment_user": this.user,
                        "comment_date": this.usaTime
                    }
                );
                // clear textarea after save comment
                txt.value = "";
			}
        },
        getStatusAll() {
            axios.get(`api/v1/local/exceptions/status/all`).then(res => {
                res.data.map(val => {
                    let statusAll = {
                        "id": val.id,
                        "status_value": val.status_value
                        };
                    this.statusDropdown.push(statusAll);
                });
                this.statusDropdown.splice(3, 1);
            });
        },
        onStatusChange(event) {
            this.status_id = event.value.id
            if (this.status_id === 5) {
                this.status_id = 4
            }
        },
        getException() {
            axios.get(`api/v1/inquiry-type/read/by-status`, {
                params: {
                    status: true
                }
            }).then(res => {
                res.data.map(val => {
                let dropdownData = {
                        "id": val.id,
                        "inquiry_type": val.inquiry_type,
                        "inquiry_status": val.inquiry_status
                    }
                    this.exc_type.push(dropdownData);
                })
            })
        },
        onDropdownChange(data) {
            this.inquiry_type_id = data.value.id
        },
        formatCalendar(event) {
            const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(event);
            const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(event);
            const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(event);
            
            this.dueDate = `${ye}-${mo}-${da}`;
        },
        escalationBTN(data) {
            switch (data) {
                case 'Yes':
                    this.escalation_status = true
                    this.minDate = new Date()
                break;
                default:
                    this.escalation_status = false
                break;
            }
        },
        notInSystemJSON() {
            return {
                "id": this.$route.params.id,
                "dist_id": 123456,
                "dist_name": "",
                "dist_name_2": "",
                "country": "",
                "inquiry_type_id": this.inquiry_type_id,
                "Inquiry_type_other": this.other,
                "escalation_status": this.escalation_status,
                "status_id": this.status_id,
                "created_by": this.created_by,
                "due_date": this.dueDate,
                "mobile_wallet": this.mobile_wallet
            }
        },
        inSystemJSON() {
            return {
                "id": this.$route.params.id,
                "dist_id": this.dist_id,
                "dist_name": this.dist_name,
                "dist_name_2": this.dist_name_2,
                "iso2": this.iso2,
                "iso3": this.iso3,
                "country": this.country,
                "inquiry_type_id": this.inquiry_type_id,
                "Inquiry_type_other": this.other,
                "escalation_status": this.escalation_status,
                "status_id": this.status_id,
                "created_by": this.created_by,
                "due_date": this.dueDate,
                "mobile_wallet": this.mobile_wallet
            }
        },
        uploadComment() {
            if (this.new_comment_info.length !== 0) {
                axios.post(`api/v1/inquiry-comments/create`, this.new_comment_info).then(res => {
                    if (res.status === 201) {
                        this.showToast('Comment Created', 'Successfully added new comment', 'success', 1000)
                    }
                })
            }
        },
        myUploader(event){
            document.body.style.cursor = 'wait'
            let uploadedFiles = 0
            for (let i = 0; i < event.files.length; i++) {
                let element = event.files[i]
                let files = element
                let data = new FormData();
                data.append('files', files);
                this.imageUpload(data).then((res) => {
                    if(res.code === 200){
                        let tmp = {
                            "inquiry_id": +this.$route.params.id,
                            "path": res.path[0]
                            }
                        this.uploads.push(tmp)
                        uploadedFiles++
                    }
                    
                    if (event.files.length === uploadedFiles) {
                        this.submiting(this.uploads)
                    }
                })
            }      
        },
        uploadFile(data) {
            if (data.length > 0) {
                axios.post(`api/v1/inquiry-upload/bulk/create`, data)
            }
        },
        submiting(data) {
            document.body.style.cursor = 'wait'

            let JSON_Data;
            if (this.country !== '') {
                JSON_Data = this.inSystemJSON()
            } else {
                JSON_Data = this.notInSystemJSON()
            }
            
            this.uploadFile(data)
            this.uploadComment()

            axios.put(`api/v1/inquiries/update`, JSON_Data).then(res => {
                if (res.status === 200) {
                    document.body.style.cursor = 'default'
                    this.showToast('Exception Updated', 'Successfully', 'success', 1000)
                    this.goBack()
                }
            })
        },
        goBack() {
            setTimeout(() => {
                this.back()
            }, 800);
        }
    }
}
</script>
