<template>
  <div :class="containerClass">
    <AppTopBar
      :topbarMenuActive="topbarMenuActive"
      :horizontal="isHorizontal()"
      @menubutton-click="onMenuButtonClick"
    ></AppTopBar>
    <transition name="layout-menu-container">
      <div class="layout-menu-container" v-show="isMenuVisible()">
        <div class="layout-menu-logo">
          <button class="p-link">
            <img src="@/assets/logo.png" alt="Organo Gold" />
          </button>
        </div>
        <div class="layout-menu-wrapper">
          <div class="menu-scroll-content">
            <AppInlineProfile
              @profile-click="onProfileClick"
              :expanded="profileExpanded"
            ></AppInlineProfile>
            <AppMenu
              :model="menuGrouped"
              :layoutMode="layoutMode"
              :active="menuActive"
              @root-menuitem-click="onRootMenuItemClick"
            ></AppMenu>
          </div>
        </div>
      </div>
    </transition>
    <div class="layout-main">
      <AppBreadcrumb></AppBreadcrumb>
      <div class="layout-content">
        <router-view />
      </div>
    </div>
    <div v-if="staticMenuMobileActive" class="layout-mask"></div>
    <AppFooter/>
  </div>
</template>

<script>
import AppTopBar from "./components/main/AppTopbar.vue";
import AppInlineProfile from "./components/main/AppInlineProfile.vue";
import AppMenu from "./components/main/AppMenu.vue";
import AppBreadcrumb from "./components/main/AppBreadcrumb.vue";
import AppFooter from './components/main/AppFooter.vue';
import EventBus from "./event-bus";

export default {
  data() {
    return {
      layoutMode: "static",
      staticMenuDesktopInactive: false,
      staticMenuMobileActive: false,
      overlayMenuActive: false,
      topbarMenuActive: false,
      menuActive: false,
      darkMenu: true,
      profileExpanded: true
    };
  },
  /**
   * Create left main menu
   * label - Title 
   * to - route - this set in router/index
  */
  created: function() {
    this.menuGrouped = [
      {
        items: [
          {
            label: "Transaction Dashboard",
            icon: "pi pi-fw pi-home",
            to: "/dashboard"
          },
          {
            label: "Exception Dashboard",
            icon: "pi pi-fw pi-folder",
            to: "/exception"
          },
          {
            label: "Worklist",
            icon: "pi pi-fw pi-list",
            to: "/worklist"
          },
          {
            separator:true
          },
          {
            label: "Cognition Worklist",
            icon: "pi pi-fw pi-map",
            to: "/cognition-worklist"
          },
          {
            separator:true
          },
          {
            label: "Distributor’s dashboard",
            icon: "pi pi-fw pi-user",
            to: "/distributor"
          },
          {
            label: "Inquiries Worklist",
            icon: "pi pi-fw pi-bars",
            to: "/inquiries-worklist"
          }
        ]
      }
    ];
  },
  watch: {
    $route() {
      this.menuActive = false;
    }
  },
  methods: {
    onMenuButtonClick(event) {
      this.menuClick = true;
      this.topbarMenuActive = false;

      if (this.layoutMode === "overlay") {
        if (this.isDesktop()) this.overlayMenuActive = !this.overlayMenuActive;
        else this.staticMenuMobileActive = !this.staticMenuMobileActive;
      } else {
        if (this.isDesktop())
          this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
        else this.staticMenuMobileActive = !this.staticMenuMobileActive;
      }
      event.preventDefault();
    },
    isMenuVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === "static")
          return !this.staticMenuDesktopInactive;
        else if (this.layoutMode === "overlay") return this.overlayMenuActive;
        else return true;
      } else {
        return true;
      }
    },
    onProfileClick(event) {
      this.profileClick = true;
      this.profileExpanded = !this.profileExpanded;
      if (this.isHorizontal() || this.isSlim()) {
        EventBus.$emit("reset_active_index");
      }

      event.preventDefault();
    },
    isHorizontal() {
      return this.layoutMode === "horizontal";
    },
    isSlim() {
      return this.layoutMode === "slim";
    },
    isDesktop() {
      return window.innerWidth > 1024;
    },
    isMobile() {
      return window.innerWidth <= 640;
    },
    onRootMenuItemClick() {
      this.menuActive = !this.menuActive;
    }
  },
  computed: {
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-horizontal": this.layoutMode === "horizontal",
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-slim": this.layoutMode === "slim",
          "layout-static-inactive": this.staticMenuDesktopInactive,
          "layout-mobile-active": this.staticMenuMobileActive,
          "layout-overlay-active": this.overlayMenuActive,
          "layout-menu-dark": this.darkMenu,
          "layout-menu-light": !this.darkMenu
        }
      ];
    }
  },
  components: {
    AppTopBar,
    AppInlineProfile,
    AppMenu,
    AppBreadcrumb,
		AppFooter
  }
};
</script>

<style lang="scss">
  @import "App.scss";
</style>
