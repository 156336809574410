<template>
  <div class="exception-body access-denied">
    <div class="exception-panel"></div>
    <div class="exception-content">
      <img src="@/assets/logo.png" alt="Organo Gold" />
      <h1><span class="exception-name">ACCESS</span> DENIED</h1>
      <p>You don't have the necessary permissions!</p>
      <a class="pointer" @click="goDashboard">Back to Login</a>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goDashboard() {
      window.location = "/login";
    }
  }
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
