<template>
  <div>
    <spinnerHtml />
    <div v-if="!spinner">
      <div class="p-grid">
        <backBtn />
        <div class="col-6 mt-1">
          <Button class="mt-2 float-right w-20" label="Download as PDF" @click="downloadPDF()" />
        </div>
      </div>
      <div id="content" ref="printMe">
        <ContextMenu :model="menuModel" ref="cm" />
        <div class="p-grid">
          <div class="p-col-6">
            <div class="card card-w-title border-none">
              <DataTable :value="p_info" class="p-datatable-responsive" :rows="20" >
                <template #header>Transaction Details</template>
                <Column field="order_number" v-model="order_number" header="Order Number"></Column>
                <Column field="order_date" v-model="order_date" header="Order Date"></Column>
                <Column field="dist_id" v-model="dist_id" header="Distributor ID"></Column>
                <Column field="dist_name_1" v-model="dist_name_1" header="Distributor’s Name"></Column>
                <Column field="country" v-model="country" header="Country"></Column>
              </DataTable>
            </div>
            <div class="card card-w-title border-none mt-3">
              <div>
                <h4>Exception Category</h4>
                <InputText class="p-field p-md-12 mb-3" type="text" v-model="exception_category" disabled />
              </div>
              <div>
                <h4>Exception Type</h4>
                <InputText class="p-field p-md-12 mb-3" type="text" v-model="exception_message" disabled />
              </div>
              <div v-if="edit">
                <h4 class="col-12 pl-0">Edit - {{ exc_name }}</h4>
                <!-- phone -->
                <InputMask class="col-sm-12 mb-3" mask="(999) 9999-999" v-model="editSystemExc" placeholder="(999) 9999-999" @change="checkPhone" v-if="phone" disabled />
                <!-- e-mail -->
                <InputText class="col-sm-12 mb-3" v-model="editSystemExc" placeholder="correct@email.com" @change="emailChecker" v-if="badEmail" disabled />
                <!-- free text -->
                <InputText class="col-sm-12 mb-3" type="text" v-model="editSystemExc" v-if="freeText" disabled />
                <!-- number field -->
                <InputNumber class="col-sm-12 px-0 mb-3" v-model="editSystemExc" mode="decimal" :useGrouping="false" v-if="zipField" disabled />
              </div>
              <div>
                <h4>Date Created</h4>
                <div class="p-field p-col-12 p-md-12 p-0">
                  <Calendar id="icon" v-model="date_created" :showIcon="true" dateFormat="dd/mm/yy" disabled />
                </div>
              </div>
              <div>
                <h4 class="mt-3">Status</h4>
                  <InputText class="col-sm-12" type="text" v-model="status_placeholder" disabled />
              </div>
            </div>
          </div>
          <div class="p-col-6">
            <Accordion class="card card-w-title border-none p-field p-col-12 p-md-12 accordion-custom">
              <AccordionTab header="Add New Comment">
                <div class="p-datatable card card-w-title border-none p-field p-col-12 p-md-12 float-r" >
                  <h4 class="p-datatable-header">Reason/Comment</h4>
                  <Textarea id="txt" :value="textareaValue" :autoResize="true" rows="5" cols="30" class="w-inherit" />
                </div>
                <Button label="Save" class="m-2 float-right" @click="saveCommentInView"/>
                <div class="card card-w-title border-none p-field p-col-12 p-md-12">
                  <DataTable class="comment_table" :value="new_comment_info_table">
                    <template #header>Reason/Comment Preview</template>
                    <Column field="comment_date" header="Date"></Column>
                    <Column field="comment_user" header="User"></Column>
                    <Column field="comment_body" header="Comment"></Column>
                    <Column bodyStyle="text-align: center" >
                      <template #header></template>
                      <template #body="slotProps">
                        <Button type="button" icon="pi pi-trash" :slotProps="slotProps" @click="deleteComment(slotProps.data.row_id)" class="center"></Button>
                      </template>
                    </Column>
                    <template #empty>
                      No new comments
                    </template>
                  </DataTable>
                </div>
              </AccordionTab>
            </Accordion>
            <Accordion class="card card-w-title border-none p-field p-col-12 p-md-12 accordion-custom" v-if="comm">
              <AccordionTab header="Comment History">
                  <DataTable class="comment_table" :value="comment_info" sortField="id" :sortOrder="-1">
                    <Column field="comment_date" sortable header="Date"></Column>
                    <Column field="comment_user" sortable header="User"></Column>
                    <Column field="comment_body" sortable header="Comment"></Column>
                  </DataTable>
              </AccordionTab>
            </Accordion>
            <Accordion class="card card-w-title border-none p-field p-col-12 p-md-12 accordion-custom" v-if="filesForDownLoad">
              <AccordionTab header="Uploaded Files">
                  <ul class="p-0" v-for="dwn in dwn_link" :key="dwn.paths">
                    <a :href="dwn.path">{{dwn.path.split("/")[5]}}</a>
                  </ul>
              </AccordionTab>
            </Accordion>
            <div class="card card-w-title border-none p-field p-col-12 p-md-12">
              <FileUpload 
                name="files" 
                chooseLabel="Upload" 
                :multiple="true"
                :customUpload="true"
                @uploader="myUploader"
                accept=".csv, .xlx, .xlsx, .pdf, .doc, .txt, .jpg, .jpeg, .png, .docx, .PNG, .JPG, .JPEG" 
                :maxFileSize="10000000" >
                <template #empty>
                  <p>Drag and drop files here to upload.</p>
                </template>
          </FileUpload>
            </div>
            <div class="mr-2 mt-2">
              <Button class="float-right mr-0" label="Submit" @click="submitForm" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import jsPDF from "jspdf"

// mixins
import mainMethods from '../../../store/mainMethods.js'
import submitMethods from '../../../store/onSubmitActions.js'
import spinnerJS from '../../../store/spinner.js'
import downloadPDF from '../../../store/downloadPDF.js'

// componenet
import spinnerHtml from '../../html/spinner.vue'
import backBtn from '../../html/back_button.vue'

export default {
  mixins: [ spinnerJS, downloadPDF, mainMethods, submitMethods ],
  components: {
    spinnerHtml,
    backBtn
  },
  data() {
    return {
      cMenu: {},
      p_info: [],
      comment_info: [],
      dwn_link: [],
      order_date: null,
      exception_category: null,
      sku_value: null,
      exception_message: null,
      date_created: null,
      order_number: null,
      dist_id: null,
      dist_name_1: null,
      country: null,
      comment_body: null,
      copyText: null,
      user: null,
      comm: false,
      filesForDownLoad: false,
      exc_name: null,
      editSystemExc: null,
      phone: false,
      freeText: false,
      badEmail: false,
      zipField: false,
      edit: true,
      userID: null,
      correction_status: null,
      getCorrection: [],
      status_placeholder: null,
      output: null,
      pdf_name: '',
      textareaValue: '',
      uploads: [],
      new_comment_info: [],
      new_comment_info_table: [],
      
      menuModel: [
        {
          label: 'Copy', 
          icon: 'pi pi-fw pi-copy', 
          command: () => this.copy(this.p_info)
        }
      ]
    };
  },
  mounted() {
    this.getComments()
    this.getCorrections()
    this.getData()
    this.getUserName()
    this.getUSADate()
  },
  methods: {
    getData() {
      axios.get(`api/v1/local/exception/${this.$route.params.id}`).then(res => {
        // context menu need to be object
        this.cMenu = res.data[0]
        this.p_info = res.data
        const DATA = this.p_info[0]
        this.exception_category = DATA.exception_category
        this.exception_message = DATA.exception_message
        this.date_created = DATA.date_created
        this.status_placeholder = DATA.status
        this.pdf_name = `${DATA.exception_message} - ${new Date().getTime()}`
        if (DATA.exception_corrections.length > 0) (this.editSystemExc = DATA.exception_corrections[0].correction_value)
        if (DATA.sku_value.length > 0) (this.exception_message = DATA.exception_message + " - " + DATA.sku_value)
        DATA.status_id === 5 ? this.correction_status = true : this.correction_status = false
        this.uploadExist(DATA)
        this.getEditField(DATA)
        this.removeSpinner()
      })
    },
    getCorrections() {
      axios.get(`api/v1/local/corrections/${this.$route.params.id}`).then(res => {
        this.getCorrection = res.data
      })
    },
    getEditField(DATA) {
      switch (DATA.exception_type_id) {
        case 1:
          this.exc_name = DATA.exception_message
          this.phone = true
        break;
        case 2: case 108:
          this.exc_name = DATA.exception_message
          this.badEmail = true
        break;
        case 3: case 5: case 7: case 8: case 21: case 72:
          this.exc_name = DATA.exception_message
          this.freeText = true
        break;
        case 4: case 105: case 106:
          this.exc_name = DATA.exception_message
          this.zipField = true
        break;
        case 6:
          this.exc_name = DATA.exception_message
          this.zipField = true
        break;
        case 25: case 26: case 98: case 97: case 95:
          const EXC_NAME = DATA.exception_message
          this.exc_name = EXC_NAME
          this.freeText = true
        break;
        default:
          this.edit = false
        break;
      }
    },
    getComments() {
      axios.get(`api/v1/local/comments/${this.$route.params.id}`).then(res => {
        this.comment_info = res.data
        res.data.length > 0 ? this.comm = true : this.comm = false
      });
    },
    uploadExist(axiosData) {
      for (let index = 0; index < axiosData.em_upload.length; index++) {
        const element = axiosData.em_upload[index]
        this.download_name = element.path.split("/")[5]
        this.dwn_link.push(element)
      }
      axiosData.em_upload.length > 0 ? this.filesForDownLoad = true : this.filesForDownLoad = false
    },
    // set canada date
    getUSADate() {
      const current_date = new Date().toISOString().slice(0,10)
      this.usaTime = current_date.toLocaleString("en-US", {timeZone: "America/Vancouver"})
    },
    saveCommentInView() {
      const txt = document.getElementById('txt')
      // get value from textarea
      if (txt.value) {
        txt.value.replace(/'/g, "\\'")
        const COMMENT_USER = this.role.slice(0, 3).toUpperCase()
        // data for send
        this.new_comment_info.push(
          {
            "exception_id": this.$route.params.id,
            "comment_body": txt.value,
            "comment_user": `${COMMENT_USER} ${this.user}`,
            "comment_date": this.usaTime
          }
        );
        // data for show in table
        this.new_comment_info_table.push(
          {
            "row_id": this.new_comment_info_table.length,
            "exception_id": this.$route.params.id,
            "comment_body": txt.value,
            "comment_user": `${COMMENT_USER} ${this.user}`,
            "comment_date": this.usaTime
          }
        );
        // clear textarea after save comment
        txt.value = ""
      }
    },
    myUploader(event){
      document.body.style.cursor = 'wait'
      let uploadedFiles = 0
      for (let i = 0; i < event.files.length; i++) {
        let element = event.files[i]
        let files = element
        let data = new FormData();
        data.append('files', files);
        this.imageUpload(data).then((res) => {
          if(res.code === 200){
            let tmp = {
              "exception_id": this.ID_exc,
              "path": res.path[0]
            }
            this.uploads.push(tmp)
            uploadedFiles++
          }
          if (event.files.length === uploadedFiles) {
            this.submitCommentOrFile(this.uploads)
          }
        })
      }      
    },
    submitForm() {
      const pbar = document.getElementsByClassName("p-progressbar-determinate");
      const JWTToken = $cookies.get("token");
      // File ready for uplaods
      if (pbar.length !== 0) {
        document.getElementsByClassName("p-fileupload-buttonbar")[0].children[1].click();
      } else if (JWTToken) {
        let uploads = [];
        this.submitCommentOrFile(uploads);
      }
    },
    submitCommentOrFile(obj) {
      // Update comments if not empty
      if (this.new_comment_info.length > 0) (this.uploadComment())

      // Update file if not empty
      if (obj.length > 0) (this.uploadFiles(obj))
      // go back
      this.back()
    }
  }
}
</script>

<style>
body .p-datatable .p-datatable-tbody > tr {
  background: #f5f5f5 !important;
  color: #212121;
}
.p-fileupload-buttonbar button.p-button.p-component {
  opacity: 0 !important;
}
.p-fileupload-row {
  margin-top: 20px !important ;
}
body .p-fileupload .p-fileupload-buttonbar {
  padding: 0.3em 1em !important;
}
body .p-button.p-button-text-icon-left .p-button-text {
  padding: 0.429em 1em 0.429em 1em !important;
}
</style>
