<template>
    <div class="my-3">
        <div class="col-sm-3 d-inline-block">
            <p>Package has been shipped?</p>
            <SelectButton v-model="shipped" :options="yesNo" disabled />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        propsData: {
            type: Object
        }
    },
    data() {
        return {
            yesNo: ['Yes', 'No'],
            shipped: ''
        }
    },
    mounted() {
        switch (this.propsData.package_shipped) {
            case true:
                this.shipped = "Yes";
            break;
            case false:
                this.shipped = "No";
            break;
        }
    },
}
</script>