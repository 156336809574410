<template>
    <div class="col-12 row mt-3">
        <div class="col-3 d-inline-block">
            <p>Batch or Lot Number:</p>
            <InputText class="col-sm-12" v-model="batch_lot_number" disabled/>
        </div>
        <div class="col-3 d-inline-block">
            <p>Date Received</p>
            <Calendar id="received_date" v-model="received_date" :showIcon="true" dateFormat="dd/mm/yy" disabled/>
        </div>
        <div class="col-3 d-inline-block pl-4">
            <p>Storage Conditions</p>
            <Textarea class="col-sm-12" v-model="storage_conditions" :autoResize="true" rows="3" cols="30" disabled/>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        propsData: {
            type: Object
        }
    },
    data() {
        return {
            batch_lot_number: this.propsData.product_compliance[0].batch_lot_number,
            received_date: this.propsData.product_compliance[0].received_date,
            storage_conditions: this.propsData.storage_conditions
        }
    }
}
</script>