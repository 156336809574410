<template>
    <div class="layout-dashboard">
        <div class="p-grid">
            <div class="p-col-12">
                <div class="card card-w-title">
                    <TabView class="p-tabview p-component p-tabview-top mb-3" @tab-change="selectTab($event)">
                        <TabPanel header="Open" :disabled="click_disabled" />
                        <TabPanel header="In Progress" :disabled="click_disabled" />
                        <TabPanel header="Closed" :disabled="click_disabled" />
                    </TabView>
                    <worklist />
                </div>
            </div>
        </div>
        <router-view></router-view>
    </div>
</template>

<script>
import worklist from "../../components/cognition/worklist";
import EventBus from "../../event-bus";

export default {
    data() {
        return {
            click_disabled: true
        }
    },
    components: {
        worklist
    },
    mounted() {
        this.disableClick()
    },
    methods: {
        /**
         * Disabled to change status before data loaded
        */
        disableClick() {
            EventBus.$on('cognition_status_change', (pending) => this.click_disabled = pending)
        },
        /**
         * selectTab method evit event to child component
         * after click on TabPanel emit id to child and load table with that status
         * status is id of TabPanel
        */
        selectTab(event) {
            let status = ++event.index
            EventBus.$emit('selectTab', status)
            this.click_disabled = true
        }
    }
};
</script>
