<template>
  <div>
    <div class="p-grid">
      <div class="p-col-12">
        <h3 class="ml-1 mt-2">Filters</h3>
        <div class="p-grid card-w-title border-none mt-2 ml-2">
            <div class="p-col-3 sizes pl-0">
              <h6 class="first">Order Number</h6>
              <InputText type="number" class="p-inputtext-lg" v-model.number="filters['f_order_number']" v-on:keyup.enter="filtering" />
            </div>
            <div class="p-col-3 sizes">
              <h6 class="first">Order Date</h6>
              <Calendar v-model="filters['f_order_date']" :showIcon="true" :maxDate="maxDateValue" :manualInput="false" dateFormat="yy-mm-dd" :showButtonBar="true" @show="showCalendar"/>
            </div>
            <div class="p-col-3 sizes">
              <h6 class="first">Distributor ID</h6>
              <InputText type="number" class="p-inputtext-lg" v-model.number="filters['f_dist_id']" v-on:keyup.enter="filtering" />
            </div>
            <div class="p-col-3 sizes pr-0">
              <h6 class="first">Distributor Name</h6>
              <InputText type="text" class="p-inputtext-lg" v-model="filters['f_dist_name']" v-on:keyup.enter="filtering" />
            </div>
        </div>
          <div class="p-col-12 sizes">
            <!-- Not in System -->
            <router-link :to="{ path: '/exception/not_in_system/create' }">
              <Button label="Not in System" iconPos="right" icon="pi pi-plus" style="width: 10% !important" v-if="isCSR"/>
            </router-link>
            <!-- Search -->
            <Button label="Search" iconPos="right" icon="pi pi-search"
            :disabled="!filters['f_order_number'] && !filters['f_order_date'] && !filters['f_dist_id'] && !filters['f_dist_name']"
            class="p-button filters_btn"
            @click="filtering"/>
          </div>
      </div>
    </div>
    <div class="p-grid">
      <div class="p-col-12">
        <div class="card card-w-title border-none">
          <p class="m-0 p-0 counter" v-if="counter">Showing {{ fr }}-{{ to }} of {{ totalRecords }} transaction.</p>
          <DataTable
            :value="transaction"
            class="p-datatable-responsive"
            :lazy="true"
            :paginator="true"
            :rows="20"
            :totalRecords="totalRecords"
            :loading="loading"
            stateStorage="local"
            stateKey="dt-state-local"
            :filters.sync="filters"
            @page="onPage($event.first, $event.rows, $event.page)">
            <template #header>Transaction Dashboard</template>
            <Column field="order_number" header="Order Number"></Column>
            <Column field="record_date" header="Order Date"></Column>
            <Column field="dist_id" header="Distributor ID"></Column>
            <Column field="dist_name_1" header="Distributor’s Name"></Column>
            <Column field="country" header="Country"></Column>
            <Column headerStyle="width: 8em; text-align: center" bodyStyle="text-align: center" >
              <template #header></template>
              <template #body="slotProps">
                <router-link :to="{ path: '/dashboard/create/' + slotProps.data.order_number } ">
                  <Button type="button" icon="pi pi-plus" class="center mr-2" v-if="isCSR"></Button>
                </router-link>
                <router-link :to="{ path: '/dashboard/view/' + slotProps.data.order_number }">
                  <Button type="button" icon="pi pi-search" class="center"></Button>
                </router-link>
              </template>
            </Column>
            <template #empty> No transaction found. </template>
          </DataTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      transaction: null,
      loading: false,
      counter: false,
      filters: {},
      filterData: [],
      totalRecords: 0,
      from: 0,
      limit: 20,
      fr: null,
      to: null,
      role: null,
      isCSR: true,
      sort: 'DESC',
      maxDateValue: null
    };
  },
  mounted() {
    this.getUserName()
    this.isCSRLogIn()
    this.setMaxDate()
    
    if (Object.keys(this.filters).length !== 0) {
      this.prepareFilterData();
      setTimeout(() => {
        let page = localStorage.getItem('page');
        if (page == null || page == undefined) {
          page = 0;
        }
        this.setData((page * this.limit),this.limit, page);
        localStorage.removeItem('page');
        this.setPagination();
      }, 500);
    }
  },
  methods: {
    showCalendar() {
      const BTN = document.querySelector('.p-datepicker-buttonbar button:last-child .p-button-label')
      BTN.innerText = 'Clear'
    },
    // search for data from input field(s)
    filtering() {
      this.filterData = [];
      this.prepareOrderDate();
      this.prepareFilterData();
      this.setData(this.from, this.limit, 0);
      this.setPagination();
    },
    // on page changed
    onPage(first, rows, page) {
      this.setData(first, rows, page);
    },
    // Get all data for user from token
    getUserName() {
      let token = $cookies.get("token")
      let base64Url = token.split('.')[1];
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      let dataFromToken = JSON.parse(jsonPayload);
      this.role = dataFromToken.roles[0];
    },
    setMaxDate() {
      let today = new Date();
      this.maxDateValue = today;
    },
    // Only CSR and CSR LEAD can create ticket
    isCSRLogIn() {
      if (this.role !== "csr" && this.role !== "csrlead") (this.isCSR = false)
    },
    // load data
    setData(first, rows, page){
      this.loading = true;
      axios.post(`api/v1/transaction/dashboard/filters/${first}/${rows}/${this.sort}`, this.filterData).then(res => {
        this.fr = first + 1
        this.to = first + rows
        this.to > this.totalRecords ? this.to = this.totalRecords : this.to = this.to
        localStorage.setItem('page', page);
        if (res.data.length > 0 && res.data[0].hasOwnProperty('code')) {
          this.transaction = null
        } else {
          this.transaction = res.data
          this.counter = true;
        }
        this.loading = false;
      })
    },
    prepareFilterData(){
      let datas = {
        order_number: this.filters['f_order_number'],
        record_date: this.filters['f_order_date'],
        dist_id: this.filters['f_dist_id'],
        dist_name: this.filters['f_dist_name']
      };
      this.filterData.push(this.removeEmpty(datas));
    },
    prepareOrderDate(){
      if (this.filters['f_order_date'] !== undefined && this.filters['f_order_date'] !== null && this.filters['f_order_date'].length == 10) {
        return false
      } else {
        if (this.filters['f_order_date'] != null || this.filters['f_order_date'] != undefined) {
            const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(this.filters['f_order_date'])
            const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(this.filters['f_order_date'])
            const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(this.filters['f_order_date'])

            this.filters['f_order_date'] = `${ye}-${mo}-${da}`
        }
      }
      if (this.filters['f_order_date'] === "1970-01-01") (this.filters['f_order_date'] = null)
    },
    removeEmpty(obj){
      Object.entries(obj).forEach(([key, val])  =>
        (val && typeof val === 'object') && removeEmpty(val) ||
        (val === null || val === "" || val == undefined) && delete obj[key]
      );
      return obj;
    },
    setPagination(){
      axios.post(`api/v1/transaction/dashboard/filters/count`, this.filterData)
      .then(res => {
        this.totalRecords = res.data;
      })
    }
  },
};
</script>

<style lang="scss">
.border-none {
  border: none !important;
}
.center {
  justify-content: end !important;
  padding: 6px !important;
}
.p-inputtext-lg {
  width: 98%;
}
.filters_btn {
  float: right !important;
}
.card.card-w-title {
  padding-bottom: 0 !important;
}
.counter {
  text-align: end;
  font-size: small;
  font-style: italic;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
