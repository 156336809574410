<template>
  <div class="layout-footer">
    <div class="p-grid">
      <div class="p-col-12 text-center">
        <img src="@/assets/logo.png" alt="Organo Gold" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppFooter"
};
</script>

<style scoped>
.text-center {
  text-align: center;
}
</style>
