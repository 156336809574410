<template>
    <div class="layout-dashboard">
        <div class="p-grid">
            <systemForm v-if="system" />
            <damagedProduct v-if="dmg" />
            <shortageProduct v-if="shortAge" />
            <neverReceivedProduct v-if="neverRP" />
            <orderCancellation v-if="orderCC" />
            <productExchange v-if="exchange" />
            <wrongProduct v-if="wrong" />
            <defectiveProduct v-if="defective"/>
            <adverse v-if="adverse"/>
            <miscellaneous v-if="miscellaneous"/>
            <payment v-if="payment"/>
            <customerRedirect v-if="customerRedirect"/>
        </div>
    </div>
</template>

<script>

// import ticket update views
import systemForm from "../../components/form/system/SystemForm.vue";
import damagedProduct from "../../components/form/damagedProduct/update.vue";
import shortageProduct from "../../components/form/shortageProduct/update.vue";
import neverReceivedProduct from "../../components/form/neverReceived/update.vue";
import orderCancellation from "../../components/form/orderCancellation/update.vue";
import productExchange from "../../components/form/productExchange/update.vue";
import wrongProduct from "../../components/form/wrongProduct/update.vue";
import defectiveProduct from "../../components/form/defectiveProduct/update.vue";
import adverse from "../../components/form/adverseEvent/update.vue";
import miscellaneous from "../../components/form/miscellaneous/update.vue";
import payment from "../../components/form/payment/update.vue";
import customerRedirect from "../../components/form/customerRedirect/update.vue";

import Emlive from "../../emlive";
import SocketHelper from "../../SocketHelper";

export default {
    data() {
        return {
            system: false,
            dmg: false,
            shortAge: false,
            neverRP: false,
            orderCC: false,
            exchange: false,
            wrong: false,
            defective: false,
            adverse: false,
            miscellaneous: false,
            payment: false,
            customerRedirect: false,
            time: 1800,
            timer: null
        }
    },
    components: {
        systemForm,
        damagedProduct,
        shortageProduct,
        neverReceivedProduct,
        orderCancellation,
        productExchange,
        wrongProduct,
        defectiveProduct,
        adverse,
        miscellaneous,
        payment,
        customerRedirect
    },
    mounted() {
        // Load exception view base on type id
        this.loadExceptionView()

        //establish socket connection
        this.socketSetup();

        //add listeners for click, scroll and key press
        this.addActivityListeners();

        //start 30min timer
        this.startTimer();
    },
    methods: {
        /**
         * Load exception view base on type id
         */
        loadExceptionView() {
            const exType = parseInt(this.$route.query.exType)
            switch(exType) {
            case 13:
                this.wrong = true
                break;
            case 14:
                this.dmg = true
                break;
            case 15:
                this.shortAge = true
                break;
            case 16:
                this.neverRP = true
                break;
            case 17:
                this.orderCC = true
                break;
            case 18:
                this.exchange = true
                break;
            case 19:
                this.defective = true
                break;
            case 20:
                this.adverse = true
                break;
            case 23:
                this.payment = true
                break;
            case 24:
                this.miscellaneous = true
                break;
            case 27:
                this.customerRedirect = true
                break;
            default:
                this.system = true
            }
        },
        /**
         * Either establish socket connection or redirect if someone is already on the page
         */
        socketSetup() {
            //hit user api of Emlive and see if someone is already on this page
            fetch(SocketHelper.getApiEndpoint(SocketHelper.getCurrentPageId()), {
                method: 'GET',
                headers: SocketHelper.getHeadersForApiRequest()
            })
            .then(response => response.json())
            .then(json => {
                if (json.length) {
                    //if there's a user on this page, return to worklist
                    this.$router.push("/worklist");
                } else {
                    //establish socket connection
                    //comment out only line below if you want to disable socket functionality
                    this.connectSocket();
                }
            });
        },

        /**
         * Initialize socket connection
         */
        connectSocket() {
            //get jwt from cookie
            const token = $cookies.get("token");

            //get current page id (same as exception id)
            const pageId = SocketHelper.getCurrentPageId();
            const baseUrl = process.env.VUE_APP_SOCKET_URL;

            //set options for socket connection
            const opt = {
                baseUrl: baseUrl,
                pageId: pageId,
                userId: SocketHelper.getUsername(),
                token: token,
                events: {}
            }

            //initialize socket connection
            Emlive.init(opt);
        },

        /**
         * Set timer for page visit, max half an hour of inactivity
         */
        startTimer() {
            this.timer = setTimeout(() => {
                this.startTimer();
                this.time--;
                if (this.time == 0) {
                    clearTimeout(this.timer);
                    this.$router.push("/worklist");
                }
            }, 1000);
        },

        /**
         * Adding event listeners for activity purposes
         * Every scroll, click or keydown will reset timer
         */
        addActivityListeners() {
            window.addEventListener('scroll', this.resetTimer);
            window.addEventListener('click', this.resetTimer);
            window.addEventListener('keydown', this.resetTimer);
        },

        /**
         * Reset timer to default value
         */
        resetTimer() {
            this.time = 1800;
        }
    },
    beforeDestroy() {
        //destroy socket connection before leaving
        //this is necessary because this is SPA, so user never loses connection while moving through components
        Emlive.destroyConnection();

        //remove timer
        clearTimeout(this.timer);
    }
}
</script>
