import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";
import VueCookies from 'vue-cookies';
/**
 * Import components for vies base on path
*/
import NotFound from "../views/main_page/NotFound.vue";
import Dashboard from "../views/transaction/Dashboard.vue";
import TransactionView from "../views/transaction/Transaction_View.vue";
import Index from "../views/ticket/update.vue";
import worklist from "../views/worklist/index.vue";
import Create_Exception from "../components/form/create_exception.vue";
import Ticket_View from "../views/ticket/view.vue";
import Not_In_System from "../components/form/notInSystem/create.vue";
import exception from "../components/exception/exception_dashboard.vue";

import distributor_dashboard from "../components/distributor/dashboard.vue"
import distributor_ticket from "../components/distributor/create.vue"
import inquiries_worklist from "../views/inquires/index.vue";
import inquiries_ticket from "../components/distributor/update.vue"
import crud from "../components/distributor/crud/table"
import create_type from "../components/distributor/crud/create/createType.vue"
import edit_type from "../components/distributor/crud/edit/editType.vue"

import cognition_ticket from "../components/cognition/update.vue"
import cognition_worklist from "../views/cognition/index.vue";

/**
 * Use vue router and cookie
*/
Vue.use(VueRouter);
Vue.use(VueCookies);
/**
 * path == component
*/
const routes = [
  {
    path: "/",
    redirect: "/worklist",
    component: worklist
  },
  {
    path: "/dashboard",
    component: Dashboard
  },
  {
    path: "/dashboard/view/:id",
    component: TransactionView,
    props: true
  },
  {
    path: "/dashboard/view/ticket/:id",
    component: Ticket_View,
    props: true
  },
  {
    path: "/dashboard/create/:id",
    component: Create_Exception,
    props: true
  },
  {
    path: "/exception/not_in_system/create",
    component: Not_In_System
  },
  {
    path: "/worklist/update/:id",
    component: Index,
    props: true
  },
  {
    path: "/worklist",
    component: worklist
  },
  {
    path: "/exception",
    component: exception
  },
  {
    path: "/exception/view/ticket/:id",
    component: Ticket_View,
    props: true
  },
  {
    path: "/distributor",
    component: distributor_dashboard
  },
  {
    path: "/distributor/ticket/create/:id",
    component: distributor_ticket,
    props: true
  },
  {
    path: '/inquiries-worklist',
    component: inquiries_worklist,
  },
  {
    path: '/inquiries-worklist/ticket/:id',
    component: inquiries_ticket,
    props: true
  },
  {
    path: '/crud',
    component: crud
  },
  {
    path: '/crud/type/create',
    component: create_type
  },
  {
    path: '/crud/type/edit/:id',
    component: edit_type,
    props: true
  },
  {
    path: '/cognition-worklist',
    component: cognition_worklist,
  },
  {
    path: '/cognition-worklist/ticket/:id',
    component: cognition_ticket,
    props: true
  },
  {
    path: "*",
    component: NotFound
  }
];
/**
 * Set vue router
 * process.env.BASE_URL - env file
*/
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
// Before enter to next path check if user is login
router.beforeEach((to, from, next) => {
  if (to.path !== '/login' && !isAuthenticated()) {
    next({ path: '/login' });
  } else {
    isAuthenticated();
    next();
  }
});
// get token from cookie
const getAuthToken = () => $cookies.get("token");
// check is authenticated
const isAuthenticated = () => {
  if (getAuthToken()) {
    const token = getAuthToken().replace("Bearer ", "");
    // set token to sessionid key in headers and send to back
    axios.defaults.headers.common['sessionid'] = token; // jshint ignore:line
    return true;
  }
  return false;
};

export default router;
