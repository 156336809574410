<template>
  <div>
    <spinnerHtml />
    <div v-if="!spinner">
      <div class="p-grid">
        <backBtn />
        <div class="col-6 mt-1">
          <Button class="mt-2 float-right w-20" label="Download as PDF" @click="downloadPDF()" />
        </div>
      </div>
      <div id="content" ref="printMe">
        <mainSection :propsData="this.axios_data" :propsMainData="this.table_data" />
        <exceptionType :propsData="this.axios_data"/>
        <div class="hr">
          <div class="card col-6 d-inline-block" v-if="notInSystem">
            <DataTable :value="product_infoRE" class="editable-cells-table order-details" >
              <template #header>Order Details</template>
              <Column field="sku_id" header="Item ID"></Column>
              <Column field="sku_name" header="Item Name"></Column>
              <Column field="sku_quantity" header="Qty"></Column>
            </DataTable>
          </div>
        </div>
        <hr v-if="notInSystem">
        <div class="col-12">
          <h5>Accounting</h5>
        </div>
        <!-- payment releated -->
        <div class="row my-3">
          <div class="col-12">
            <h6 class="col-12">Credit Card Details:</h6>
            <div class="col-3 d-inline-block">
              <p>Credit Card Number</p>
              <InputText class="col-12" v-model="creditCardNumber" disabled placeholder="****-****-****-****"/>
            </div>
            <div class="col-3 d-inline-block">
              <p>Cardholder Name</p>
              <InputText class="col-12" v-model="cardName" disabled />
            </div>
            <div class="col-3 d-inline-block">
              <p>Expiry Date</p>
              <Calendar v-model="expiry_date" :showIcon="true" disabled/>
            </div>
          </div>
        </div>
        <div class="row my-4">
          <div class="col-12">
            <h6 class="col-12">Credit Card Details:</h6>
            <div class="col-3 d-inline-block">
              <p>Transaction Amount</p>
              <InputText class="col-12" v-model="transaction_amount" disabled />
            </div>
            <div class="col-3 d-inline-block">
              <p>Transaction Date</p>
              <Calendar v-model="transaction_date" :showIcon="true" disabled />
            </div>
          </div>
        </div>
        <!-- payment for acc -->
        <div class="row col-12 my-4">
          <div class="col-3">
            <p>Charge has been found</p>
            <SelectButton v-model="charge_found" :options="yesNo" disabled />
          </div>
          <div class="col-3 pr-0">
            <p>Charge Details</p>
            <InputText class="col-12" v-model="chargeDetails" disabled/>
          </div>
        </div>
        <div class="col-9 p-0">
          <status :propsData="this.axios_data"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import jsPDF from "jspdf"

// import methods from mixins
import accounting from '../../../store/accounting.js'
import spinnerJS from '../../../store/spinner.js'
import downloadPDF from '../../../store/downloadPDF.js'

// componenet
import spinnerHtml from '../../html/spinner.vue'
import backBtn from '../../html/back_button.vue'

// HTML section
import mainSection from '../html_part/main_section.vue'
import exceptionType from '../html_part/exception_type.vue'
import status from '../html_part/status.vue'

export default {
  mixins: [ accounting, spinnerJS, downloadPDF ],
  components: {
    spinnerHtml,
    backBtn,
    mainSection,
    exceptionType,
    status
  },
  data() {
    return {
      axios_data: [],
      table_data: [],
      product_infoRE: [],
      sectionUpdate: true,
      chargeDetails: '',
      payment: [],
      accounting: [],
      accountingID: null,
      paymentShow: true,
      yesNo: ['Yes', 'No'],
      charge_found: null,
      charge_processing_fee: null,
      checkRefundBool: null,
      processingFeeBoll: null,
      accProcessedBoll: null,
      paymentACCBool: null,
      paymentRelatedShow: true,
      creditCardNumber: null,
      cardName: '',
      expiry_date: null,
      transaction_amount: null,
      transaction_date: null,
      type_id: null,
      paymentRelatedShowACC: true,
      notInSystem: true,
      output: null,
      pdf_name: ''
    };
  },
  mounted() {
    this.getData()    
  },
  methods: {
    getData() {
      axios.get(`api/v1/local/exception/${this.$route.params.id}`).then(res => {
        this.axios_data = res.data[0]
        this.table_data = res.data
        const DATA = this.axios_data
        if (DATA.accounting[0] !== undefined) (this.populateDataAccounting(DATA))
        if (DATA.order_number === 0) (this.notInSystem = false)
        this.pdf_name = `${this.axios_data.exception_message} - ${this.axios_data.action_name}-${new Date().getTime()}`
        this.removeEmpty(DATA)
        this.removeSpinner()
      })
    },
    removeEmpty(DATA) {
      for (let index = 0; index < DATA.product_info.length; index++) {
        const element = DATA.product_info[index];
        element.sku_id !== null ? this.product_infoRE.push(element) : this.product_infoRELESE.push(element)
      }
    },
  }
}
</script>
