import axios from "axios";
export default {
    methods: {
        /**
         * Base on exception type and action render elements
         * Every type was different action and elements
        */
        onAction(event) {
            this.adverse_event = false;
            this.action_name = event.value.id;
            const type = this.type_id;
            const action = event.value.id;
            if (type === 13) {
                switch(action) {
                    case 1:
                        this.wrong = true;
                        this.reasonSection = false;
                        this.showQTYReturn = false;
                        this.showTableRelease = false;
                        this.shipping = false;
                        this.showReturnItem = true;
                        this.showPayment = false;
                        this.wrongHeader = 'Products for Return';
                    break;
                    case 2:
                        this.wrong = true;
                        this.reasonSection = false;
                        this.showQTYReturn = false;
                        this.showTableRelease = false;
                        this.shipping = false;
                        this.showReturnItem = false;
                        this.pickUP = false;
                        this.showPayment = true;
                        this.wrongHeader = "Products to Keep";
                    break;
                    case 3:
                        this.reasonSection = false;
                        this.showQTYReturn = false;
                        this.showTableRelease = true;
                        this.wrong = true;
                        this.shipping = true;
                        this.showReturnItem = false;
                        this.pickUP = false;
                        this.showPayment = true;
                        this.wrongHeader = "Products to Keep";
                    break;
                    case 4:
                        this.reasonSection = false;
                        this.showQTYReturn = false;
                        this.showTableRelease = true;
                        this.wrong = true;
                        this.shipping = true;
                        this.showReturnItem = true;
                        this.showPayment = false;
                        this.wrongHeader = 'Products for Return';
                    break;
                }
            }
            if (type === 14) {
                switch(action) {
                    case 1:
                        this.showQTYReturn = true;
                        this.showTableRelease = false;
                        this.shipping = false;
                        this.showReturnItem = false;
                        this.headerTitle = "Order Details/Products for Return";
                        this.showPayment = false;
                    break;
                    case 2:
                        this.showQTYReturn = false;
                        this.showTableRelease = false;
                        this.itsAllOK = true;
                        this.shipping = false;
                        this.showReturnItem = false;
                        this.headerTitle = "Order Details";
                        this.showPayment = true;
                    break;
                    case 3:
                        this.showQTYReturn = false;
                        this.showTableRelease = true;
                        this.itsAllOK = false;
                        this.shipping = true;
                        this.showReturnItem = false;
                        this.headerTitle = "Order Details";
                        this.showPayment = true;
                    break;
                    case 4:
                        this.showQTYReturn = true;
                        this.showTableRelease = true;
                        this.itsAllOK = false;
                        this.shipping = true;
                        this.showReturnItem = false;
                        this.headerTitle = "Order Details/Products for Return";
                        this.showPayment = true;
                    break;
                }
            }
            if (type === 15) {
                switch(action) {
                    case 1:
                        this.showQTYReturn = true;
                        this.showTableRelease = false;
                        this.headerTitle = "Order Details/Products for Return";
                        this.showReturnItem = true;
                        this.itsAllOK = false;
                        this.shipping = false;
                    break;
                    case 2:
                        this.showQTYReturn = false;
                        this.showTableRelease = false;
                        this.itsAllOK = true;
                        this.showReturnItem = false;
                        this.shipping = false;
                        this.pickUP = false;
                        this.headerTitle = "Order Details";
                    break;
                    case 3:
                        this.showQTYReturn = false;
                        this.showTableRelease = true;
                        this.shipping = true;
                        this.showReturnItem = false;
                        this.itsAllOK = false;
                        this.pickUP = false;
                        this.headerTitle = "Order Details";
                    break;
                }
            }
            if (type === 16) {
                switch(action) {
                    case 5:
                        this.showQTYReturn = false;
                        this.showTableRelease = false;
                        this.itsAllOK = true;
                        this.shipping = true;
                        this.lost = true;
                    break;
                    case 6:
                        this.showPayment = true
                        this.showQTYReturn = false;
                        this.showTableRelease = true;
                        this.itsAllOK = false;
                        this.shipping = true;
                    break;
                }
            }
            if (type === 17) {
                this.reasonSection = true;
                this.showQTYReturn = true;
                this.showTableRelease = false;
                this.shipping = false;
                this.showReturnItem = false;
                this.headerTitle = "Order Details/Products for Return";
                this.reasonTitle = "Reason for cancellation";
                axios.get(`api/v1/local/reasons/all`).then(res => {
                    res.data.map(val => {
                    if (val.id === 9 || val.id === 10) {
                        return;
                    } else {
                        let dropdownReasons = {
                        "id": val.id,
                        "reason_value": val.reason_value
                        };
                        this.reasons.push(dropdownReasons);
                    }
                    });
                });
            }
            if (type === 18) {
                this.reasonSection = true;
                this.showQTYReturn = true;
                this.showTableRelease = true;
                this.shipping = true;
                this.showReturnItem = false;
                this.showPayment = true;
                this.reasonTitle = "Reason for Exchange";
                this.headerTitle = "Order Details/Products for Return";
                axios.get(`api/v1/local/reasons/all`).then(res => {
                    res.data.map(val => {
                    if (val.id === 1 || val.id === 8 || val.id === 9 || val.id === 10) {
                        let dropdownReasons = {
                        "id": val.id,
                        "reason_value": val.reason_value
                        };
                        this.reasons.push(dropdownReasons);
                    } else {
                        return;
                    }
                    });
                });
            }
            if (type === 19) {
                switch(action) {
                    case 1:
                        this.showQTYReturn = true;
                        this.showTableRelease = false;
                        this.shipping = false;
                        this.showReturnItem = true;
                        this.showPayment = false;
                        this.headerTitle = "Order Details/Products for Return";
                        this.adverse_event = true;
                    break;
                    case 2:
                        this.showQTYReturn = false;
                        this.showTableRelease = false;
                        this.itsAllOK = true;
                        this.shipping = false;
                        this.showReturnItem = false;
                        this.headerTitle = "Order Details";
                        this.showPayment = true;
                        this.adverse_event = true;
                    break;
                    case 3:
                        this.showQTYReturn = false;
                        this.showTableRelease = true;
                        this.itsAllOK = false;
                        this.shipping = true;
                        this.pickUP = false;
                        this.showReturnItem = false;
                        this.headerTitle = "Order Details";
                        this.showPayment = true;
                        this.adverse_event = true;
                    break;
                    case 4:
                        this.showQTYReturn = true;
                        this.showTableRelease = true;
                        this.itsAllOK = false;
                        this.shipping = true;
                        this.showReturnItem = true;
                        this.headerTitle = "Order Details/Products for Return";
                        this.showPayment = false;
                        this.adverse_event = true;
                    break;
                }
            }
            if (type === 20) {
                switch(action) {
                    case 1:
                        this.showQTYReturn = true;
                        this.showTableRelease = false;
                        this.shipping = false;
                        this.showReturnItem = true;
                        this.showPayment = false;
                        this.headerTitle = "Order Details/Products for Return";
                        this.adverse_event = true;
                    break;
                    case 4:
                        this.showQTYReturn = true;
                        this.showTableRelease = true;
                        this.itsAllOK = false;
                        this.shipping = true;
                        this.showReturnItem = true;
                        this.headerTitle = "Order Details/Products for Return";
                        this.showPayment = false;
                        this.adverse_event = true;
                    break;
                }
            }
            if (type === 23) {
                switch(action) {
                    case 7:
                        this.showQTYReturn = false;
                        this.itsAllOK = true;
                        this.paymentRelatedShow = true;
                    break;
                    case 8:
                        this.showQTYReturn = false;
                        this.itsAllOK = true;
                        this.paymentRelatedShow = true;
                    break;
                }
            }
            if (type === 24) {
                switch(action) {
                    case 9:
                        this.showQTYReturn = false;
                        this.itsAllOK = true;
                    break;
                    case 10:
                        this.showQTYReturn = false;
                        this.itsAllOK = true;
                    break;
                    case 11:
                        this.showQTYReturn = false;
                        this.itsAllOK = true;
                        this.explanationShow = true;
                    break;
                }
            }
            if (type === 27) {
                switch(action) {
                    case 12:
                        this.showQTYReturn = false;
                        this.showPayment = true;
                        this.newShipping = true;
                        this.itsAllOK = true;
                    break;
                    case 13:
                        this.showQTYReturn = false;
                        this.shipping = true;
                        this.itsAllOK = true;
                    break;
                }
            }
        },
    },
};
