<template>
    <div class="p-col-6">
        <Button class="ml-2 mt-1" label="Back" @click="backToPrevisionPage()" />
    </div>
</template>

<script>
export default {
    created() {
        // export this methods
        this.$root.$refs.back = this;
    },
    methods: {
        backToPrevisionPage() {
            window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
        },
    },
}
</script>
