export default {
    methods: {
        populateDataCustomer(DATA) {
            const customer = DATA.customer_redirect[0]
            this.new_country = customer.redirect_country
            this.new_address = customer.redirect_street_address
            this.new_city = customer.redirect_city
            this.new_state = customer.redirect_state
            this.new_zip = customer.redirect_zip_code
            this.intercept_amt = customer.redirect_intercept_amt
            this.ups_name = customer.ups_name
            this.ups_address = customer.ups_street_address
            this.ups_city = customer.ups_city
            this.ups_state = customer.ups_state
            this.ups_phone = customer.ups_phone_number
            this.ups_hours = customer.ups_operation_hours
            this.available = customer.package_available_date
            this.pickup_date = customer.pickup_by_date
            this.available && this.disabledPickuUp()
            switch (customer.redirect_intercept_fee) {
                case true:
                    this.intercept = 'Yes';
                    this.redirect_intercept_fee = true;
                break;
                case false:
                    this.intercept = 'No';
                    this.redirect_intercept_fee = false;
                break;
            }
        },
        disabledPickuUp() {
            const MAX = this.available.split('-')
            this.maxDateValue = new Date(MAX[0], MAX[1] - 1, MAX[2])
            this.availableDatePickup = false
        },
        formatCalendarDate(event, id) {
            const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(event);
            const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(event);
            const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(event);
            switch (id) {
                case "available":
                    this.available = `${ye}-${mo}-${da}`;
                    this.disabledPickuUp()
                break;
                case "pickup_date":
                    this.pickup_date = `${ye}-${mo}-${da}`;
                break;
            }
        },
    }
};