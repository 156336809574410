<template>
  <div class="layout-dashboard">
    <div class="p-grid">
      <div class="p-col-12">
        <div class="card card-w-title">
          <TabView class="p-tabview p-component p-tabview-top" @tab-change="select($event)">
            <TabPanel header="Open" :disabled="click_disabled" />
            <TabPanel header="In Progress" :disabled="click_disabled" />
            <TabPanel header="Pending" :disabled="click_disabled" />
            <TabPanel header="Closed" :disabled="click_disabled" />
          </TabView>
          <worklist />
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import worklist from "../../components/worklist/table.vue";
import EventBus from "../../event-bus";

export default {
  data() {
    return {
      click_disabled: true
    }
  },
  components: {
    worklist
  },
  mounted() {
    this.disableClick()
  },
  methods: {
    /**
      * Disabled to change status before data loaded
    */
    disableClick() {
      EventBus.$on('status_changed', (pending) => {
        this.click_disabled = pending
      })
    },
    /**
      * select method evit event to child component
      * after click on TabPanel emit id to child and load table with that status
      * status is id of TabPanel
    */
    select(event) {
      let status
      switch (event.index) {
        case 0:
          status = 'open'
        break;
        case 1:
          status = 'in progress'
        break;
        case 2:
          status = 'pending'
        break;
        case 3:
          status = 'closed'
        break;
      }
      EventBus.$emit('select', status)
      this.click_disabled = true
    }
  }
}
</script>
