import axios from "axios";
export default {
  methods: {
    populateDataAccounting(DATA) {
      const accounting = DATA.accounting[0];
      // set data
      this.checkAmount = accounting.check_amount;
      this.checkAmountACC = accounting.acc_check_amount;
      this.checkNumberACC = accounting.check_number;
      this.checkDate = accounting.check_date;
      this.processedDate = accounting.acc_date_processed;
      this.check_country = accounting.check_country;
      this.check_address = accounting.check_street_address;
      this.check_city = accounting.check_city;
      this.check_state = accounting.check_state;
      this.check_zip = accounting.check_zip_code;
      this.amount_paid = accounting.amount_paid;
      this.amount_deducted = accounting.amount_deducted;
      this.amount_refunded = accounting.amount_refunded;
      this.app_date = accounting.log_date_processed;
      this.cardName = accounting.cardholder_name;
      this.expiry_date = accounting.expiry_date;
      this.transaction_amount = accounting.trx_amount;
      this.transaction_date = accounting.trx_date;
      this.chargeDetails = accounting.charge_details;
      this.explanation = accounting.explanation;
      // loop from accounting object and set yes/no for selected button 
      Object.keys(accounting).forEach((key) => {
        switch (accounting[key]) {
          case true:
            this[key] = 'Yes';
          break;
          case false:
            this[key] = 'No';
          break;
        }
      });
      // Only accounting can see credit card number
      this.showCreditCardToACC(DATA);
    },
    // set accounting section hide/show
    visibilityAccSection(DATA) {
      const accounting = DATA.accounting[0];
      // for accounting section
      accounting.check_refund === null ? this.forACCSection = false : this.forACCSection = true // jshint ignore:line
      // accounting section
      accounting.acc_processed === null ? this.accountingSection = false : this.accountingSection = true // jshint ignore:line
    },
    // Only accounting can see credit card number
    showCreditCardToACC(DATA) {
      const encrypted = DATA.accounting[0].credit_card_number;
      const decrypted = {
        "text": encrypted
      };
      if (this.role === "accounting" && encrypted !== null) {
        axios.post(`api/v1/encryptor/decrypt`, decrypted).then(res => { this.creditCardNumber = res.data; });
      }
    },
    // copy address button from shipping
    copyToMailAddress() {
      this.check_country = this.current_country;
      this.check_address = this.current_address;
      this.check_city = this.current_city;
      this.check_state = this.current_state;
      this.check_zip = this.current_zip;
    },
    // set value for selected button 
    accSelectedBtn(selected, button) {
      switch (selected) {
        case 'Yes':
          this[button] = true;
          break;
        case 'No':
          this[button] = false;
          break;
        default:
          this[button] = null;
          break;
      }
    },
    // Format date in calendar to USA data yyyy-mm-dd
    formatCalendarDate(event, id) {
      const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(event);
      const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(event);
      const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(event);
      switch (id) {
        case "checkDate":
          this.checkDate = `${ye}-${mo}-${da}`;
          break;
        case "processedDate":
          this.processedDate = `${ye}-${mo}-${da}`;
          break;
        case "app_date":
          this.log_date_processed = `${ye}-${mo}-${da}`;
          break;
        case "expiry_date":
          this.expiry_date = `${ye}-${mo}-${da}`;
          break;
        case "transaction_date":
          this.transaction_date = `${ye}-${mo}-${da}`;
          break;
        case "safety_call_date":
          this.safety_call_date = `${ye}-${mo}-${da}`;
          break;
      }
    },
    // Substring - limit string lenght
    subString(event, field) {
      this.addslashes(this[field]);
      if (event.length > 255) {
        this[field] = event.substr(0, 255);
        this.showToast('Characters Limit', 'Characters Limit extended, max characters is 255.', 'warning', 2000);
      }
    },
    prepareAccJSON() {
      let accJSON = {
        exception_id: this.ID_exc,
        check_refund: this.checkRefundBool,
        check_amount: this.checkAmount,
        charge_processing_fee: this.processingFeeBoll,
        check_payee_name: this.dist_name_1,
        check_country: this.check_country,
        check_street_address: this.check_address,
        check_city: this.check_city,
        check_state: this.check_state,
        check_zip_code: this.check_zip,
        acc_check_amount: this.checkAmountACC,
        check_number: this.checkNumberACC,
        check_date: this.checkDate,
        acc_processed: this.accProcessedBoll,
        acc_date_processed: this.processedDate,
        amount_paid:  parseFloat(this.amount_paid),
        amount_deducted: parseFloat(this.amount_deducted),
        amount_refunded: parseFloat(this.amount_refunded),
        log_approved: this.isAPPBool,
        log_date_processed: this.log_date_processed,
        credit_card_number: this.creditCardNumber,
        cardholder_name: this.cardName,
        expiry_date: this.expiry_date,
        trx_amount: parseFloat(this.transaction_amount),
        trx_date: this.transaction_date,
        explanation: this.explanation,
        charge_found: this.paymentACCBool,
        charge_details: this.chargeDetails,
      };
      this.accounting.push(accJSON);
    },
    // check if data need to create or update by accounting id
    getAccounting(json) {
      axios.get(`api/v1/local/accounting/${this.$route.params.id}`).then(res => {
        const response = res.data;
        if (response.length > 0) {
          this.accountingID = response[0].id;
          this.updateAccounting(json);
        } else {
          this.insertAccounting(json);
        }
      });
    },
    updateAccounting(accJSON) {
      axios.put(`api/v1/local/accounting/update/${this.accountingID}`, accJSON).then(res => {});
    },
    insertAccounting(accJSON) {
      axios.post(`api/v1/local/accounting/insert`, accJSON).then(res => {});
    },
  }
};