import axios from "axios";
export default {
    methods: {
        mappingReassing(type, action, department, secondDepartment, thirdDepartment) {
            // wrong product
            if (type === 13 || type === 27) {
                switch (action) {
                    case 1: case 2: case 3: case 12:
                        this.assing.map(val => {
                            if (val.assing_value === secondDepartment || val.assing_value === department) {
                                let departmentAll = {
                                    "id": val.id,
                                    "assing_value": val.assing_value
                                };
                                this.reassingMap.push(departmentAll);
                            }
                        });
                    break;
                    default:
                        this.assing.map(val => {
                            if (val.assing_value === department) {
                                let departmentAll = {
                                    "id": val.id,
                                    "assing_value": val.assing_value
                                };
                                this.reassingMap.push(departmentAll);
                            }
                        });
                    break;
                }
            }
            // damaged product and product exchange
            if (type === 14 || type === 18) {
                this.assing.map(val => {
                    if (val.assing_value === secondDepartment || val.assing_value === department) {
                        let departmentAll = {
                            "id": val.id,
                            "assing_value": val.assing_value
                        };
                        this.reassingMap.push(departmentAll);
                    }
                });
            } else if (type === 15 ||type === 16 ||type === 17 || type === 23 || type === 24) {
                switch (action) {
                    case 1: case 5:  case 6: case 9: case 10: case 11:
                        this.assing.map(val => {
                            if (val.assing_value === secondDepartment || val.assing_value === department) {
                                let departmentAll = {
                                    "id": val.id,
                                    "assing_value": val.assing_value
                                };
                                this.reassingMap.push(departmentAll);
                            }
                        });
                    break;
                    case 2: case 3: case 4:
                        this.assing.map(val => {
                            if (val.assing_value === department) {
                                let departmentAll = {
                                    "id": val.id,
                                    "assing_value": val.assing_value
                                };
                                this.reassingMap.push(departmentAll);
                            }
                        });
                    break;
                    // action id 7/8 for payment releated
                    default:
                        this.assing.map(val => {
                            if (val.assing_value === secondDepartment) {
                                let departmentAll = {
                                    "id": val.id,
                                    "assing_value": val.assing_value
                                };
                                this.reassingMap.push(departmentAll);
                            }
                        });
                    break;
                }
            } else if (type === 19 || type === 20) {
                switch (action) {
                    case 1: case 2: case 3: 
                        this.assing.map(val => {
                            if (val.assing_value === secondDepartment || val.assing_value === department || val.assing_value === thirdDepartment) {
                                let departmentAll = {
                                    "id": val.id,
                                    "assing_value": val.assing_value
                                };
                                this.reassingMap.push(departmentAll);
                            }
                        });
                    break;
                    case 4:
                        this.assing.map(val => {
                            if (val.assing_value === department || val.assing_value === thirdDepartment) {
                                let departmentAll = {
                                    "id": val.id,
                                    "assing_value": val.assing_value
                                };
                                this.reassingMap.push(departmentAll);
                            }
                        });
                    break;
                }
            }
        },
        /**
         * Get all department from axios
         * Map data and push to array
         * Data for dropdown must be in array 
        */
        getDepartmentAll(DATA) {
            const TYPE = DATA.exception_type_id;
            const ACTION = DATA.action_id;
            const ROLE = this.role;
            let department; 
            let secondDepartment; 
            let thirdDepartment;
            this.reassingMap = [];

            // get all department from UM
            axios.get(`api/v1/user/roles/all`).then(res => {
                res.data.map(val => {
                    if (val.roleCode === "csr" || val.roleCode === "csrlead" || val.roleCode === "accounting" || val.roleCode === "logistics") {
                        switch (val.roleCode) {
                            case "csr":
                                val.roleCode = "CSR";
                            break;
                            case "csrlead":
                                val.roleCode = "CSRLead";
                            break;
                            case "accounting":
                                val.roleCode = "Accounting";
                            break;
                            case "logistics":
                                val.roleCode = "Logistics";
                            break;
                        }
                        let departmentAll = {
                            "id": val.id,
                            "assing_value": val.roleCode
                        };
                        this.assing.push(departmentAll);
                    }
                });
                // Reassing map base on user
                switch (ROLE) {
                    case 'csr':
                        this.mappingReassing(TYPE, ACTION, department = "Logistics", secondDepartment = "Accounting", thirdDepartment = 'CSRLead');
                        break;
                    case 'logistics':
                        this.mappingReassing(TYPE, ACTION, department = "CSR", secondDepartment = "Accounting", thirdDepartment = 'CSRLead');
                        break;
                    case 'accounting':
                        this.mappingReassing(TYPE, ACTION, department = "Logistics", secondDepartment = "CSR", thirdDepartment = 'CSRLead');
                        break;
                    case 'csrlead':
                        this.mappingReassing(TYPE, ACTION, department = "CSR", secondDepartment = "Accounting", thirdDepartment = 'Logistics');
                    break;
                }
            });
        }
    }
};