<template>
  <div>
    <spinnerHtml />
    <div v-if="!spinner">
      <div class="p-grid">
        <backBtn />
        <div class="p-col-6 align-end" v-if="this.axios_data.returned_from !== ''">
          <router-link
            :to="{ path: '/exception/view/ticket/' + this.ID_exc, query:{exType: this.exception_type_id} }" target="_blank">
            <Button label="View All Sections" type="button" icon="pi pi-search" iconPos="right" class="center mr-2 mt-1" style="width:170px"></Button>
          </router-link>
        </div>
      </div>
      <ContextMenu :model="menuModel" ref="cm" />
      <div class="p-grid">
        <div class="p-col-6">
          <div class="card card-w-title border-none">
            <DataTable :value="table_data" class="p-datatable-responsive" :rows="20" contextMenu :contextMenuSelection.sync="cMenu" @row-contextmenu="onRowContextMenu" :lazy="true" :loading="loading">
              <template #header>Transaction Details</template>
              <Column field="order_number" v-model="order_number" header="Order Number"></Column>
              <Column field="order_date" v-model="order_date" header="Order Date"></Column>
              <Column field="dist_id" v-model="dist_id" header="Distributor ID"></Column>
              <Column field="dist_name_1" v-model="dist_name_1" header="Distributor’s Name"></Column>
              <Column field="country" v-model="country" header="Country"></Column>
            </DataTable>
          </div>
        </div>
        <div class="p-col-6">
          <Accordion class="card card-w-title border-none p-field p-col-12 p-md-12 accordion-custom">
            <AccordionTab header="Add New Comment">
              <div class="p-datatable card card-w-title border-none p-field p-col-12 p-md-12 float-r" >
                <h4 class="p-datatable-header">Reason/Comment</h4>
                <Textarea id="txt" :value="value" :autoResize="true" rows="5" cols="30" class="w-inherit" />
              </div>
              <Button label="Save" class="m-2 float-right" @click="saveComment"/>
              <div class="card card-w-title border-none p-field p-col-12 p-md-12">
                <DataTable class="comment_table" :value="new_comment_info_table">
                  <template #header>Reason/Comment Preview</template>
                  <Column field="comment_date" header="Date"></Column>
                  <Column field="comment_user" header="User"></Column>
                  <Column field="comment_body" header="Comment"></Column>
                  <Column bodyStyle="text-align: center" >
                    <template #header></template>
                    <template #body="slotProps">
                        <Button type="button" icon="pi pi-trash" :slotProps="slotProps" @click="deleteComment(slotProps.data.row_id)" class="center"></Button>
                    </template>
                  </Column>
                  <template #empty>
                    No new comments
                  </template>
                </DataTable>
              </div>
            </AccordionTab>
          </Accordion>
          <Accordion class="card card-w-title border-none p-field p-col-12 p-md-12 accordion-custom" v-if="comm">
            <AccordionTab header="Comment History">
                <DataTable :value="comment_info" sortField="id" :sortOrder="-1">
                  <Column field="comment_date" sortable header="Date"></Column>
                  <Column field="comment_user" sortable header="User"></Column>
                  <Column field="comment_body" sortable header="Comment"></Column>
                </DataTable>
            </AccordionTab>
          </Accordion>
          <Accordion class="card card-w-title border-none p-field p-col-12 p-md-12 accordion-custom" v-if="filesForDownLoad">
            <AccordionTab header="Uploaded Files">
                <ul class="p-0" v-for="dwn in dwn_link" :key="dwn.paths">
                  <a :href="dwn.path">{{dwn.path.split("/")[5]}}</a>
                </ul>
            </AccordionTab>
          </Accordion>
          <div class="card card-w-title border-none p-field p-col-12 p-md-12">
            <FileUpload 
            name="files" 
            chooseLabel="Upload" 
            :multiple="true"
            :customUpload="true"
            @uploader="myUploader"
            accept=".csv, .xlx, .xlsx, .pdf, .doc, .txt, .jpg, .jpeg, .png, .docx, .PNG, .JPG, .JPEG" 
            :maxFileSize="10000000" >
            <template #empty>
              <p>Drag and drop files here to upload.</p>
            </template>
          </FileUpload>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="row mb-1 mt-3">
          <div class="col-4 d-inline-block">
            <h6>Exception Type</h6>
            <InputText class="col-12" type="text" v-model="exc_type" disabled />
          </div>
          <div class="col-4 d-inline-block">
            <h6>Action</h6>
            <InputText class="col-12" type="text" v-model="exc_action" disabled />
            </div>
          <div class="col-4 d-inline-block">
            <h6>Date</h6>
            <Calendar id="icon" v-model="current_date" :showIcon="true" dateFormat="dd/mm/yy" disabled />
          </div>
        </div>
      </div>
      <hr>
      <div>
        <div class="card col-6 d-inline-block">
          <DataTable :value="product_infoRE" class="editable-cells-table order-details">
            <template #header>Order Details</template>
            <Column field="sku_id" header="Item ID"></Column>
            <Column field="sku_name" header="Item Name"></Column>
            <Column field="sku_quantity" header="Qty"></Column>
            <template #empty>
                No product
            </template>
          </DataTable>
        </div>
        <div class="card col-6 d-inline-block" v-if="releaseTableShow">
          <DataTable :value="product_infoRELESE" class="editable-cells-table release" ref="dt" dataKey="id">
            <template #header>
              <div class="table-header">
                <span class="m-auto">Products for Release</span>
              </div>
            </template>
            <Column field="release_sku_id" header="Item ID"></Column>
            <Column field="release_sku_name" header="Item Name"></Column>
            <Column field="release_sku_quantity" header="Qty for Release"></Column>
            <template #empty>
                No product
            </template>
          </DataTable>
        </div>

        <div class="card col-6 d-inline-block" v-if="lostProductShow">
          <DataTable :value="product_info_lost" editMode="cell" class="editable-cells-table wrong" ref="dt" dataKey="id">
            <template #header>
              <div class="table-header">
                <span class="m-auto">Lost Products</span>
              </div>
            </template>
              <Column field="lost_sku_id" header="Item ID"></Column>
              <Column field="lost_sku_name" header="Item Name"></Column>
              <Column field="lost_sku_quantity" header="Qty"></Column>
              <template #empty>
                No product
              </template>
          </DataTable>
        </div>

      </div>
      <div class="col-sm-3 mt-3" v-if="showPayment">
        <label for="payment_email" class="d-block">Email for Payment</label>
        <InputText class="col-sm-12 mb-2" id="payment_email" v-model="payment_email" placeholder="payment@email.com" @change="emailChecker"/>
        <small class="row col-sm-12 text-danger d-block" v-if="error">E mail address is not correct!</small>
      </div>
      <hr v-if="shipping">
      <div class="shipping-box my-4" v-if="shipping">
        <h6 class="col-sm-12 mb-3">Shipping Address:</h6>
        <div class="col-sm-3 d-inline-block mb-2">
          <p>Country/Region:</p>
          <InputText class="col-sm-12" v-model="current_country" />
        </div>
        <div class="col-sm-3 d-inline-block">
          <p>Street Address:</p>
          <InputText class="col-sm-12" v-model="current_address" />
        </div>
        <div class="col-sm-2 d-inline-block">
          <p>City:</p>
          <InputText class="col-sm-12" v-model="current_city" />
        </div>
        <div class="col-sm-2 d-inline-block">
          <p>State/Province:</p>
          <InputText class="col-sm-12" v-model="current_state" />
        </div>
        <div class="col-sm-2 d-inline-block">
          <p>Zip/Postal Code:</p>
          <InputText class="col-sm-12" v-model="current_zip" />
        </div>
      </div>
      <hr>
      <div class="col-12 mb-4" v-if="shippingTitle">
        <h5>Shipping And Returns</h5>
      </div>

      <div class="mb-3" v-if="reshipping">
        <div class="col-3 d-inline-block align-bottom">
          <p>Charge Reshipment Fee</p>
          <SelectButton v-model="reshiprCH" :options="yesNo" @input="reshipChanged"/>
        </div>
        <div class="col-3 d-inline-block pr-1" v-if="this.reshipField === true">
          <p>Reshipment Fee Amount</p>
          <InputText class="col-12" type="text" v-model="reshipTicket" @change="reshipTicketMethod"/>
        </div>
      </div>
      

      <!-- For Accounting  -->
      <div class="row mt-3" v-if="this.newSection">
        <div class="col-12">
          <div class="col-3 d-inline-block pr-1">
            <p>Claim Ticket #</p>
            <InputText class="col-12" type="text" v-model="ticket" @change="courierTicket" />
          </div>
          <div class="col-3 d-inline-block pl-4 align-bottom">
            <p>Claim Approved by Courier</p>
            <SelectButton v-model="courierCH" :options="yesNo" @input="courierChanged" :disabled="!ticket"/>
          </div>
          <div class="col-3 d-inline-block">
            <p>Claim Action</p>
            <Dropdown 
              class="col-12" 
              v-model="selectedClaim" 
              :options="claim" 
              optionLabel="action_name" 
              :placeholder="selectedClaim" 
              @change="prepareClaimData($event)"
            />
          </div>
        </div>
        <div class="col-12 mt-3">
          <div class="card col-6 d-inline-block" v-if="this.selectedClaimActionID === 1">
            <DataTable :value="product_info_release" editMode="cell" class="editable-cells-table pfr">
              <template #header>
                <div class="table-header">
                  <i class="pi pi-plus" id="addRelease" @click="openModal"></i>
                  <span class="m-auto">Products for Release</span>
                </div>
              </template>
              <Column header="#" class="w-25">
                <template #body="slotProps">
                  <i class="pi pi-ban" @click="deleteAddedProduct(event = 'release', slotProps)"></i>
                </template>
              </Column>
              <Column field="release_sku_id" header="Item ID"></Column>
              <Column field="release_sku_name" header="Item Name"></Column>
              <Column field="release_sku_quantity" header="Qty for Release">
                <template #editor="slotProps">
                  <InputNumber v-model="slotProps.data[slotProps.column.field]" @input="onCellEdit($event, slotProps, table = `release`)"/>
                </template>
              </Column>
              <template #empty>No product</template>
            </DataTable>
          </div>
        </div>
      </div>
      <!-- For Accounting  -->
      <div class="mt-4" v-if="forACCSection">
        <div class="col-12">
          <h6>For Accounting</h6>
          <div class="row col-12 mb-3">
            <div class="col-3 px-0">
              <p>Check Refund</p>
              <SelectButton v-model="check_refund" :options="yesNo" @input="accSelectedBtn($event, field = 'checkRefundBool')" />
            </div>
            <div class="col-3 pr-0" v-if="check_refund == 'Yes'">
              <p>Amount</p>
              <InputText class="col-12" v-model="checkAmount" @change="replaceCommaToDot($event, field = 'checkAmount')"/>
            </div>
          </div>
          <div class="row col-12 mb-3" v-if="check_refund == 'Yes'">
            <div class="col-3 px-0">
              <p>Charge Processing Fee</p>
              <SelectButton v-model="charge_processing_fee" :options="yesNo" @input="accSelectedBtn($event, field = 'processingFeeBoll')" />
            </div>
            <div class="col-3 pr-0">
              <p>Check Payee Name</p>
              <InputText class="col-12" v-model="dist_name_1" />
            </div>
          </div>
          <div class="row my-4" v-if="check_refund == 'Yes'">
            <h6 class="col-sm-12">Mailing Address:
              <Button icon="pi pi-copy" class="p-button" @click="copyToMailAddress" v-tooltip.top="'Copy address from shipping!'"/>
            </h6>
            <div class="col-sm-3 d-inline-block">
              <p>Country/Region:</p>
              <InputText class="col-sm-12" v-model="check_country" />
            </div>
            <div class="col-sm-3 d-inline-block">
              <p>Street Address:</p>
              <InputText class="col-sm-12" v-model="check_address" />
            </div>
            <div class="col-sm-2 d-inline-block">
              <p>City:</p>
              <InputText class="col-sm-12" v-model="check_city" />
            </div>
            <div class="col-sm-2 d-inline-block">
              <p>State/Province:</p>
              <InputText class="col-sm-12" v-model="check_state" />
            </div>
            <div class="col-sm-2 d-inline-block">
              <p>Zip/Postal Code:</p>
              <InputText class="col-sm-12" v-model="check_zip" />
            </div>
          </div>
        </div>
      </div>
      <!-- For Acc Section  -->
      <hr v-if="accountingSection">
      <div class="row" v-if="accountingSection">
        <div class="col-12">
          <h6 class="col-12">Accounting</h6>
          <div class="mb-3">
            <div class="col-3 d-inline-block">
              <p>Check Amount</p>
              <InputText class="col-12" v-model="checkAmountACC" @change="replaceCommaToDot($event, field = 'checkAmountACC')" />
            </div>
            <div class="col-3 d-inline-block">
              <p>Check Number</p>
              <InputText class="col-12" v-model="checkNumberACC" @input="subString($event, field = 'checkNumberACC')" />
            </div>
            <div class="col-3 d-inline-block">
              <p>Check Date</p>
              <Calendar id="checkDate" v-model="checkDate" :showIcon="true" :manualInput="false" dateFormat="yy-mm-dd" @date-select="formatCalendarDate($event, calendar = 'checkDate')"/>
            </div>
          </div>
          <div class="col-12 my-4">
            <div class="col-3 d-inline-block pl-0">
              <p>Processed by Accounting</p>
              <SelectButton v-model="acc_processed" :options="yesNo" @input="accSelectedBtn($event, field = 'accProcessedBoll')" />
            </div>
            <div class="col-3 d-inline-block align-top pl-1">
              <p>Accounting Date Processed</p>
              <Calendar id="processedDate" v-model="processedDate" :showIcon="true" :maxDate="maxDateValue" :manualInput="false" dateFormat="yy-mm-dd" @date-select="formatCalendarDate($event, calendar = 'processedDate')"/>
            </div>
          </div>
        </div>
      </div>
      <!-- status section  -->
      <div class="col-9 p-0">
        <div class="col-4">
          <p>Status</p>
          <Dropdown class="col-12" v-model="selectedStatus" :options="statusDropdown" optionLabel="status_value" :placeholder="current_status" @change="onStatusChange"/>
        </div>
        <div class="col-4 mt-3" v-if="reassigned">
          <p>Assing To:</p>
          <Dropdown class="col-12" v-model="assingToDepartment" :options="reassingMap" :placeholder="reassing" optionLabel="assing_value" @change="assingToChange"/>
        </div>
      </div>
      <div class="col-12">
        <Button class="float-right" label="Submit" @click="submitForm"/>
      </div>
    </div>
    <!-- POP UP -->
    <Dialog :visible.sync="productDialog" :style="{width: '900px'}" :modal="true" class="p-fluid popUp">
      <DataTable
        :value="werehouse"
        :scrollable="true"
        scrollHeight="400px"
        :loading="loading"
        dataKey="id"
        class="werehouse"
        :filters="filters">
        <template #header>
          <div class="table-header">
            <span class="p-input-icon-right">
              <i class="pi pi-search" />
              <InputText v-model="filters['global']" placeholder="Global Search" />
            </span>
          </div>
        </template>
        <Column field="item_code" header="Item ID"></Column>
        <Column field="item_name_1" header="Item Name"></Column>
        <Column>
          <template #body="slotProps">
            <Button icon="pi pi-plus" class="center" @click="addProduct(slotProps.data)" />
          </template>
        </Column>
      </DataTable>
    </Dialog>
  </div>
</template>

<script>
import axios from "axios";
// import methods from mixins
import getAndSet from '../../../store/getAndSet.js'
import reassingMap from '../../../store/reassingMaping.js'
import ticketFlow from '../../../store/ticketFlow.js'
import onSubmitActions from '../../../store/onSubmitActions.js'
import selectedButton from '../../../store/selectedButton.js'
import mainMethods from '../../../store/mainMethods.js'
import accounting from '../../../store/accounting.js'
import spinnerJS from '../../../store/spinner.js'
// componenet
import spinnerHtml from '../../html/spinner.vue'
import backBtn from '../../html/back_button.vue'

export default {
  mixins: [mainMethods, getAndSet, reassingMap, ticketFlow, onSubmitActions, selectedButton, accounting, spinnerJS],
  components: {
    spinnerHtml,
    backBtn
  },
  data() {
    return {
      department: null,
      role: null,
      assingTo: null,
      assingToDepartment: null,
      assing: [],
      reassigned: false,
      reassing: null,
      axios_data: [],
      table_data: [],
      exc_type: null,
      exc_action: null,
      selectedAction: null,
      loading: true,
      cMenu: {},
      p_info: [],
      product_info: [],
      comment_info: [],
      new_comment_info:[],
      new_comment_info_table: [],
      dwn_link: [],
      filesForDownLoad: false,
      files: [],
      current_status: null,
      value: null,
      date_created: null,
      order_date: null,
      order_number: null,
      dist_id: null,
      dist_name_1: null,
      country: null,
      comment_body: null,
      copyText: null,
      ticket: null,
      reshipTicket: null,
      courierCH: null,
      reshiprCH: null,
      yesNo: ['Yes', 'No'],
      checked_bool: null,
      reshipField: null,
      user: null,
      ID_exc: null,
      releaseTableShow: false,
      current_date : null,
      selectedStatus: null,
      statusDropdown: [],
      status_id: null,
      uploads: [],
      comm: false,
      menuModel: [
        {
          label: 'Copy',
          icon: 'pi pi-fw pi-copy',
          command: () => this.copy(this.table_data)
        }
      ],
      product_infoRE: [],
      product_infoRELESE: [],
      product_info_lost: [],
      // shipping box
      current_country: null,
      current_address: null,
      current_city: null,
      current_state: null,
      current_zip: null,
      shipping: false,
      usaTime: null,
      
      sectionUpdate: true,
      shippingTitle: true,
      // last section
      check_refund: 'No',
      checkAmount: null,
      charge_processing_fee: null,
      accounting: [],
      checkRefundBool: null,
      processingFeeBoll: null,
      checkAmountACC: null,
      checkNumberACC: '',
      checkDate: '',
      acc_processed: null,
      processedDate: '',
      accProcessedBoll: null,
      forACCSection: false,
      accountingSection: false,
      accountingID: null,
      check_country: '',
      check_address: '',
      check_city: '',
      check_state: '',
      check_zip: '',
      maxDateValue: null,
      exception_type_id: 0,
      returned_from: '',
      selectedClaim: '',
      claim: [],
      selectedClaimActionID: null,
      werehouse: [],
      productDialog: false,
      product_info: [],
      filters: {},
      product_info_release: [],
      PROD_INFO: [],
      oldData: null,
      plus: false,
      IDForUpdate: false,
      delProd: false,
      IDForDelete: [],
      newSection: true,
      reshipShow: false,
      reshipping: false,
      payment_email: null,
      error: false,
      showPayment: true,
      lostProductShow: false
    };
  },
  mounted() {
    this.getUserName()
    this.setMaxDate()
    this.getData()
    this.claimAction()
  },
  methods: {
    getData() {
      axios.get(`api/v1/local/exception/${this.$route.params.id}`).then(res => {
        this.axios_data = res.data[0]
        this.table_data = res.data
        // all data
        const DATA = this.axios_data
        this.cMenu = DATA;
        this.product_info = DATA.product_info;
        this.ID_exc = DATA.id
        this.exception_type_id = DATA.exception_type_id
        this.dist_name_1 = DATA.dist_name_1
        this.exc_type = DATA.exception_message
        this.exc_action = DATA.action_name
        this.selectedAction = DATA.action_id
        this.current_date = DATA.date_created
        this.ticket = DATA.claim_ticket_number
        this.comment_info = DATA.em_comments;
        this.order_number = DATA.order_number
        this.date_created = DATA.record_date;
        this.current_status = DATA.status
        this.status_id = DATA.status_id
        this.payment_email = DATA.payment_email
        // shipping address
        this.current_country = DATA.country
        this.current_address = DATA.street_address
        this.current_city = DATA.city
        this.current_state = DATA.state
        this.current_zip = DATA.zip_code
        // spinner false
        this.loading = false
        // canada date
        const now_date = new Date().toISOString().slice(0,10)
        this.usaTime = now_date.toLocaleString("en-US", {timeZone: "America/Vancouver"});
        // populate accounting data from accounting.js file
        if (DATA.accounting[0] !== undefined) (this.populateDataAccounting(DATA))
        if (DATA.customer_reship.length > 0) (this.populateReship(DATA))
        this.getWerehouse(DATA.warehouse_number)
        // methods
        this.removeEmpty(DATA)
        this.showUploads(DATA)
        this.selectedBtn(DATA)
        this.render(DATA)
        this.renderElByRole(DATA)
        this.getDepartmentAll(DATA)
        this.getStatusAll(DATA)
        this.removeSpinner()

        if (DATA.customer_reship.length > 0) {
          if (DATA.customer_reship[0].claim_action_id === 1) {
            this.forACCSection = false
            this.accountingSection = false
          }
        }
      })
    },
    populateReship(DATA) {
      this.selectedClaimActionID = DATA.customer_reship[0].claim_action_id
      this.selectedClaim = DATA.customer_reship[0].claim_action_name
      this.reshiprCH = DATA.customer_reship[0].reship_fee
      this.reshipTicket = DATA.customer_reship[0].reship_fee_amt

      switch (DATA.customer_reship[0].reship_fee) {
        case true:
          this.reshiprCH = 'Yes';
          this.reshipField = true;
        break;
        case false:
          this.reshiprCH = 'No';
          this.reshipField = false;
        break;
      }

      if (DATA.customer_reship[0].claim_action_id === 2) {
        this.forACCSection = true
      } else {
        this.forACCSection = false
        this.accountingSection = false
      }
    },
    addProduct(params) {
      this.product_info_release.push({
        "exception_id": this.ID_exc,
        "release_sku_id": params.item_code,
        "release_sku_name": params.item_name_1,
        "release_sku_quantity": null
      })
      // close modal after product added
      this.productDialog = false;
      this.plus = true
    },
    deleteAddedProduct(table, props) {
      switch (table) {
        case 'release':
          this.product_info_release.splice(props.index, 1)
          this.product_info_release.every(e =>
            e.release_sku_quantity !== undefined &&
            e.release_sku_quantity !== 0 &&
            e.release_sku_quantity !== null
          ) ? this.itsAllOK = true : this.itsAllOK = false
          this.product_info_release.length === 0 ? this.itsAllOK = false : 0
          this.IDForDelete.push(props.data.id)
          this.delProd = true
        break;
      }
    },
    getWerehouse(wereHouse) {
      axios.get(`api/v1/inventory/conditions/warehouse/${wereHouse}`).then(res => {
        this.werehouse = res.data
      });
    },
    claimAction() {
      axios.get(`api/v1/local/claim/action/all`).then(res => {
        res.data.map(val => {
          let dropdownData = {
            "id": val.id,
            "action_name": val.action_name
            };
          this.claim.push(dropdownData);
        });
      })
    },
    prepareClaimData(event) {
      this.selectedClaimActionID = event.value.id
      this.selectedClaim = event.value.action_name
      if (event.value.id === 2) {
        // this.accountingSection = true
        this.forACCSection = true
      } else {
        this.forACCSection = false
        // this.accountingSection = false
      }
    },
    onCellEdit(newValue, props, table) {
      switch (table) {
        case 'release':
          this.product_info_release.every(e =>
            e.release_sku_quantity !== undefined &&
            e.release_sku_quantity !== 0 &&
            e.release_sku_quantity !== null) ? this.itsAllOK = true : this.itsAllOK = false

          this.product_info_release[props.index].release_sku_quantity = newValue
          this.IDForUpdate = true
        break;
      }
    },
    openModal(event) {
      this.product = {};
      this.submitted = false;
      this.productDialog = true;
      // clear search input
      this.filters = {};
    },
    renderElByRole(DATA) {
      const ROLE = this.role
      switch (ROLE) {
        case "csr":
          this.sectionUpdate = false
          this.shippingTitle = false
          this.newSection = false
          if (DATA.action_id === 5) {
            this.showPayment = false
            
        this.lostProductShow = true
          }
          break;
        case "logistics":
          if (DATA.action_id === 5) {
            this.showPayment = false
            this.sectionUpdate = false
            
        this.lostProductShow = true
          } else {
            this.newSection = false
            this.reshipping = true
          }
          break;
        case "accounting":
          this.sectionUpdate = false
          if (DATA.action_id === 5) {
            this.forACCSection = true
            this.accountingSection = true
            
        this.lostProductShow = true
          } else {
            this.newSection = false
            this.reshipping = true
          }
          break;
      }
    },
    removeEmpty(DATA) {
      for (let index = 0; index < DATA.product_info.length; index++) {
        const element = DATA.product_info[index];
        
        if (element.sku_id !== null) {
          this.product_infoRE.push(element)
        } else if (element.release_sku_id !== null) {
          this.product_infoRELESE.push(element) 
          this.product_info_release.push(element) 
        } else {
          this.product_info_lost.push(element) 
        }
      }
    },
    render(DATA) {
      const actionID = DATA.action_id
      switch(actionID) {
        case 5:
          this.shipping = true
        break;
        case 6:
          this.releaseTableShow = true
          this.shipping = true
        break;
      }
    },
    reshipTicketMethod() {
      if (!event.target.value) {
        this.reshipCH = ''
        this.reshipField = null
      }
    },
    reshipChanged(Check) {
      Check === "Yes" ? this.reshipField = true : this.reshipField = false
    },
    courierTicket() {
      if (!event.target.value) {
        this.courierCH = ''
        this.checked_bool = null
      }
    },
    courierChanged(courierCheck) {
      courierCheck === "Yes" ? this.checked_bool = true : this.checked_bool = false
    },
    prepareJSON (department) {
      const datas = [
        {
          order_number: this.axios_data.order_number,
          order_date: this.axios_data.order_date,
          dist_id: this.axios_data.dist_id,
          dist_name_1: this.axios_data.dist_name_1,
          dist_name_2: this.axios_data.dist_name_2,
          iso2: this.axios_data.iso2,
          iso3: this.axios_data.iso3,
          country: this.axios_data.country,
          exception_type: this.axios_data.exception_type_id,
          status: this.status_id,
          created_by: this.axios_data.created_by,
          date_created: this.axios_data.date_created,
          claim_ticket_number: this.ticket,
          claim_approved: this.checked_bool,
          action_id: this.selectedAction,
          country: this.current_country,
          street_address: this.current_address,
          city: this.current_city,
          state: this.current_state,
          zip_code: this.current_zip,
          department: department,
          payment_email: this.payment_email,
          returned_from: this.returned_from
        }
      ];
      return datas
    },
    reship() {
      return [
              {
                "exception_id": this.ID_exc,
                "claim_action_id": this.selectedClaimActionID,
                "reship_fee": this.reshipField,
                "reship_fee_amt": this.reshipTicket
              }
            ]
    },
    myUploader(event){
      document.body.style.cursor = 'wait'
      let uploadedFiles = 0
      for (let i = 0; i < event.files.length; i++) {
        let element = event.files[i]
        let files = element
        let data = new FormData();
        data.append('files', files);
        this.imageUpload(data).then((res) => {
          if(res.code === 200){
            let tmp = {
              "exception_id": this.ID_exc,
              "path": res.path[0]
            }
            this.uploads.push(tmp)
            uploadedFiles++
          }
          if (event.files.length === uploadedFiles) {
            this.submiting(this.uploads)
          }
        })
      }      
    },
    submiting(obj) {
      document.body.style.cursor = 'wait'
      // accounting section
      this.prepareAccJSON()
      this.getAccounting(this.accounting)

      // json for backend
      const department = this.ticketMaps()
      const datas = this.prepareJSON(department)

      // Update comments if not empty
      if (this.new_comment_info.length > 0) (this.uploadComment())

      // Update file if not empty
      if (obj.length > 0) (this.uploadFiles(obj))

      if (this.plus) {
        let newData = this.product_info_release.slice(this.oldData)
        axios.post(`api/v1/local/product/info/insert`, this.product_info_release)
      }
      if (this.IDForUpdate) {
        axios.put(`api/v1/local/product/info/update/bulk`, this.product_info_release)
        
      }
      if (this.delProd) {
        this.IDForDelete.forEach(id => axios.delete(`api/v1/local/product/info/delete/${id}`))
      }

      axios.get(`api/v1/local/customer/reship/${this.ID_exc}`).then(res => {
        if (res.data.length === 0) {
          axios.post(`api/v1/local/customer/reship/insert`, this.reship())
        } else {
          axios.put(`api/v1/local/customer/reship/update/${res.data[0].id}`, this.reship())
        }
      })

      // Update this exception
      axios.put(`api/v1/local/exception/update/${this.ID_exc}`, datas).then(res => {
        const RESPONSE = res.data[0]
        if (RESPONSE.code === 400) {
          this.showToast('Exception NOT Updated', RESPONSE.message, 'danger', 2500)
          document.body.style.cursor = 'default'
        } else {
          this.showToast('Successfully', 'Exception Updated', 'success', 1200)
          document.body.style.cursor = 'default'
          this.back()
        }
      })
    }
  }
}
</script>
