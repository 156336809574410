<template>
  <div>
    <div class="p-grid">
      <backBtn />
      <div class="col-6 global-search">
        <span class="p-input-icon-right">
          <i class="pi pi-search mr-2" />
          <InputText v-model="global" class="w-3" placeholder="Global Search" @input="globalSearch"/>
        </span>
      </div>
    </div>
    <div class="p-grid">
      <div class="p-col-12">
        <ContextMenu :model="menuModel" ref="cm" />
        <div class="card card-w-title">
          <DataTable
            :value="transaction"
            class="p-datatable-responsive"
            :lazy="true"
            :totalRecords="totalRecords"
            :loading="loading"
            contextMenu
            :autoLayout="true"
            :contextMenuSelection.sync="cMenu"
            @row-contextmenu="onRowContextMenu">
            <template #header>Transaction View</template>
            <Column field="id" header="Exception ID"></Column>
            <Column field="exception_message" header="Exception Type"></Column>
            <Column field="status" header="Status"></Column>
            <Column field="department" header="Current Department"></Column>
            <Column field="order_number" header="Order Number"></Column>
            <Column field="order_date" header="Order date"></Column>
            <Column field="dist_id" header="Distributor ID"></Column>
            <Column field="dist_name_1" header="Distributor’s Name"></Column>
            <Column field="country" header="Country"></Column>
            <Column field="created_by" header="Created by"></Column>
            <Column field="date_created" header="Date Created"></Column>
            <Column>
              <template #body="slotProps">
                <router-link
                  :to="{ path: '/exception/view/ticket/' + id, query:{exType: type} }"
                  :slotProps="slotProps" >
                  <Button type="button" icon="pi pi-search" @click.middle="updateEx(slotProps.data.id, slotProps.data.exception_type_id)" @click="updateEx(slotProps.data.id, slotProps.data.exception_type_id)" class="center"></Button>
                </router-link>
              </template>
            </Column>
            <template #empty>
              No records found.
            </template>
          </DataTable>
          <Paginator :rows="15" :totalRecords="totalRecords" @page="onPage($event)"></Paginator>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// component
import backBtn from '../html/back_button.vue'

export default {
  components: {
    backBtn
  },
  data() {
    return {
      transaction: null,
      loading: true,
      id: 0,
      type: 0,
      totalRecords: 0,
      from: 0,
      limit: 15,
      global: null,
      getAll: "all",
      sort: "DESC",
      allException: "api/v1/local/exceptions",
      allExceptionCount: "api/v1/local/exceptions/count",
      dataAPI: "api/v1/local/exception/order/view/status/id/sort",
      countAPI: "api/v1/local/exception/order/view/status/count",
      copyText: null,
      cMenu: {},
      menuModel: [
        {
          label: 'Copy',
          icon: 'pi pi-fw pi-copy',
          command: () => this.copy(this.transaction)
        }
      ],
    };
  },
  mounted() {
    this.getData()
    this.getCount()
  },
  methods: {
    // get all exception
    getData() {
      axios.get(`${this.allException}/${this.from}/${this.limit}`).then(res => {
        this.cMenu = res.data[0];
        this.transaction = res.data;
        this.loading = false;
      })
    },
    // get count
    getCount() {
      axios.get(`${this.allExceptionCount}`).then(res => (this.totalRecords = res.data));
    },
    // get exception by search
    getDataSearch() {
      axios.get(`${this.countAPI}/${this.getAll}/${this.global}/${this.getAll}`).then(res => (this.totalRecords = res.data));
    },
    // get count by searching exception
    getDataSearchCount() {
      axios.get(`${this.dataAPI}/${this.getAll}/${this.from}/${this.limit}/${this.sort}/${this.global}/${this.getAll}`).then(res => {
        this.cMenu = res.data[0];
        this.transaction = res.data;
        this.loading = false;
      });
    },
    /**  
     * use pagination
     * if global true get new data with filter
    */
    getDataFilterPagination(first, rows) {
      axios.get(`${this.dataAPI}/${this.getAll}/${first}/${rows}/${this.sort}/${this.global}/${this.getAll}`).then(res => {
        this.cMenu = res.data[0];
        this.transaction = res.data;
        this.loading = false;
      });
    },
    getDataPagination(first, rows) {
      axios.get(`${this.allException}/${first}/${rows}`).then(res => {
        this.transaction = res.data;
        this.loading = false;
      });
    },
    // on page change
    onPage($event) {
      this.loading = true;
      if (this.global) {
        this.getDataFilterPagination($event.first, $event.rows)
      } else {
        this.getDataPagination($event.first, $event.rows)
      }
    },
    // search by status
    globalSearch() {
      if (this.global) {
        this.getDataSearch()
        this.getDataSearchCount()
      } else {
        this.getData()
        this.getCount()
      }
    },
    onRowContextMenu(event) {
      this.copyText = event.originalEvent.target.innerText;
      this.$refs.cm.show(event.originalEvent);
    },
    copy() {
      const copyText = this.copyText;
      navigator.clipboard.writeText(copyText).then((ev) => {
        this.showToast("Text Copy", copyText, "info", 1500);
      }, (err) => {
        this.showToast("Text NOT Copy", "Could not copy text", "warning",3000);
      });
    },
    showToast(title, body, style, duration) {
      this.$bvToast.toast(body, {
        title: title,
        autoHideDelay: duration,
        variant: style
      });
    },
    updateEx(id, type) {
      this.id = id
      this.type = type
    },
  }
};
</script>

<style lang="scss">
.center {
  justify-content: end !important;
  padding: 6px !important;
}
.counter {
  text-align: end;
  font-size: small;
  font-style: italic;
}
.global-search {
  text-align: end;
  padding-top: 15px;
}
</style>
