<template>
    <div>
        <div class="row">
            <div class="col-3 mb-3">
                <Button 
                    label="Back" 
                    class="custom-btn" 
                    @click="$router.go(-1)" />
            </div>
            <h4 class="col-12 mb-3">Create New Type</h4>
            <div class="p-field col-3">
                <label for="title">Title</label>
                <InputText class="col-12" id="title" type="text" v-model="title" />
            </div>
        </div>
        <div class="row col-3 mt-3">
            <Button 
                label="Save" 
                class="custom-btn" 
                @click="create" />
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            title: ''
        }
    },
    mounted() {
    },
    methods: {
        create() {
            let json = {
                "inquiry_type": this.title,
                "inquiry_status": true
            }
            
            axios.post(`api/v1/inquiry-type/create`, json).then(res => {
                if (res.status === 201) {
                    this.refresh()
                }
            })
            
        },
        refresh() {
            setTimeout(() => {
                this.$router.go()
            }, 500)
        }
    }
}
</script>