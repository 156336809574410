<template>
    <div>
        <div class="p-grid">
            <div class="p-col-12">
                <h3 class="ml-2">Filters</h3>
                <div class="p-grid">
                    <div class="p-col-3">
                        <label class="first">Distributor ID</label>
                        <InputText 
                            type="number" 
                            class="p-inputtext-lg" 
                            v-model.number="filters['f_dist_id']" 
                            v-on:keyup.enter="filtering" />
                    </div>
                    <div class="p-col-3">
                        <label class="first">Distributor Name</label>
                        <InputText 
                            type="text" 
                            class="p-inputtext-lg" 
                            v-model="filters['f_dist_name']" 
                            v-on:keyup.enter="filtering" />
                    </div>
                    <div class="p-col-3">
                        <Button 
                        label="Search" 
                        iconPos="right" 
                        icon="pi pi-search"
                        :disabled="!filters['f_dist_id'] && !filters['f_dist_name']"
                        class="p-button mt-4"
                        @click="filtering" />
                    </div>
                </div>
                <div class="p-col-12">
                    <router-link :to="{ path: '/distributor/ticket/create/not_in_system' }">
                        <Button 
                            label="Not in System" 
                            iconPos="right" 
                            icon="pi pi-plus" 
                            style="width: 10% !important" 
                            v-if="isCSR" />
                    </router-link>
                </div>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12">
                <div class="card card-w-title border-none">
                    <p class="m-0 p-0 counter" v-if="counter">Showing {{ fr }}-{{ to }} of {{ totalRecords }} records.</p>
                    <DataTable
                        :value="transaction"
                        class="p-datatable-responsive"
                        :lazy="true"
                        :paginator="true"
                        :rows="20"
                        :totalRecords="totalRecords"
                        :loading="loading"
                        :filters.sync="filters"
                        @page="onPage($event.first, $event.rows, $event.page)">
                        <template #header>Distributor’s dashboard</template>
                        <Column field="dist_id" header="Distributor ID"></Column>
                        <Column field="dist_name" header="Distributor’s Name">
                            <template #body="slotProps">
                                {{slotProps.data.dist_name ? slotProps.data.dist_name : slotProps.data.dist_name_1}}
                            </template>
                        </Column>
                        <Column field="country" header="Country"></Column>
                        <Column headerStyle="width: 8em; text-align: center" bodyStyle="text-align: center" >
                            <template #header>#</template>
                            <template #body="slotProps">
                                <router-link :to="{ path: '/distributor/ticket/create/' + slotProps.data.dist_id } ">
                                    <Button type="button" icon="pi pi-plus" class="center mr-2" v-if="isCSR"></Button>
                                </router-link>
                                <router-link :to="{ path: '/dashboard/view/' + slotProps.data.dist_id }">
                                    <Button type="button" icon="pi pi-search" class="center"></Button>
                                </router-link>
                            </template>
                        </Column>
                        <template #empty> No transaction found. </template>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            transaction: [],
            loading: false,
            counter: false,
            filters: {},
            filterData: [],
            totalRecords: 0,
            from: 0,
            limit: 20,
            fr: null,
            to: null,
            role: null,
            isCSR: true,
            sort: 'DESC',
            maxDateValue: null
        };
    },
    mounted() {
        this.getUserName()
        this.isCSRLogIn()
        this.setMaxDate()
        
        if (Object.keys(this.filters).length !== 0) {
            this.prepareFilterData();
            setTimeout(() => {
                let page = localStorage.getItem('page');
                if (page == null || page == undefined) {
                    page = 0;
                }
                this.setData((page * this.limit),this.limit, page);
                localStorage.removeItem('page');
                this.setPagination();
            }, 500);
        }
    },
    methods: {
        // search for data from input field(s)
        filtering() {
            this.filterData = [];
            this.prepareFilterData();
            this.setData(this.from, this.limit, 0);
            this.setPagination();
        },
        // on page changed
        onPage(first, rows, page) {
            this.setData(first, rows, page);
        },
        // Get all data for user from token
        getUserName() {
            let token = $cookies.get("token")
            let base64Url = token.split('.')[1];
            let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            let dataFromToken = JSON.parse(jsonPayload);
            this.role = dataFromToken.roles[0];
        },
        setMaxDate() {
            let today = new Date();
            this.maxDateValue = today;
        },
        // Only CSR and CSR LEAD can create ticket
        isCSRLogIn() {
            if (this.role !== "csr" && this.role !== "csrlead") (this.isCSR = false)
        },
        // load data
        setData(first, rows, page) {
            this.transaction = []
            if (this.filterData[0].dist_id === undefined) {
                this.loading = true;
                axios.post(`api/v1/transaction/dashboard/filters/${first}/${rows}/${this.sort}`, this.filterData).then(res => {
                    this.fr = first + 1
                    this.to = first + rows
                    this.to > this.totalRecords ? this.to = this.totalRecords : this.to = this.to
                    if (res.data.length > 0 && res.data[0].hasOwnProperty('code')) {
                        this.transaction = null
                    } else {
                        this.transaction = res.data
                        this.counter = true;
                    }
                    this.loading = false;
                })
            } else {
                this.loading = true;
                axios.get(`api/v1/distributor-dashboard/read/by-dist-id`, {
                    params: {
                        dist_id: this.filterData[0].dist_id
                    }
                }).then(res => {
                    this.transaction.push(res.data)
                    this.loading = false
                })
            }
        },
        prepareFilterData(){
            let datas = {
                dist_id: this.filters['f_dist_id'],
                dist_name: this.filters['f_dist_name']
            };
            this.filterData.push(this.removeEmpty(datas));
        },
        removeEmpty(obj){
            Object.entries(obj).forEach(([key, val])  =>
                (val && typeof val === 'object') && removeEmpty(val) ||
                (val === null || val === "" || val == undefined) && delete obj[key]
            );
            return obj;
        },
        setPagination() {
            if (this.filterData[0].dist_id === undefined) {
                axios.post(`api/v1/transaction/dashboard/filters/count`, this.filterData)
                .then(res => {
                    this.totalRecords = res.data;
                })
            }
        }
    }
}
</script>

<style lang="scss">
.border-none {
    border: none !important;
}
.center {
    justify-content: end !important;
    padding: 6px !important;
}
// .p-inputtext-lg {
//     width: 98%;
// }
.filters_btn {
    float: right !important;
}
.card.card-w-title {
    padding-bottom: 0 !important;
}
.counter {
    text-align: end;
    font-size: small;
    font-style: italic;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
</style>
