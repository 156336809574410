<template>
    <div class="row col-12">
        <h5 class="col-12 mb-3 hr">CSR Lead</h5>
        <div class="col-3">
            <p>Safety Call Case Number</p>
            <InputText class="col-sm-12" type="text" v-model="safety_call" disabled/>
        </div>
        <div class="col-3 pr-0 mb-2">
            <p>Safety Call Transfer Date</p>
            <Calendar id="safe" v-model="safety_call_date" :showIcon="true" dateFormat="yy-mm-dd" disabled/>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        propsData: {
            type: Object
        }
    },
    data() {
        return {
            safety_call: this.propsData.product_compliance[0].safety_call_number,
            safety_call_date: this.propsData.product_compliance[0].safety_call_date
        }
    }
}
</script>