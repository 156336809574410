<template>
  <div class="layout-dashboard">
    <div class="p-grid">
      <tableApp />
    </div>
  </div>
</template>

<script>
import tableApp from "../../components/transaction/Transaction_View.vue";

export default {
  components: {
    tableApp
  }
};
</script>
