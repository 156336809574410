import EventBus from "../event-bus";

export default {
    data() {
        return {
            spinner: true
        };
    },
    methods: {
        // remove spinner after load ended
        removeSpinner() {
            this.spinner = false;
            // emit event with eventbus
            EventBus.$emit('spinnerFalse', this.spinner);
        }
    },
};