export default {
  methods: {
    populateComplianceData(DATA) {
      const compliance = DATA.product_compliance[0];
      // set substatus
      switch (compliance.sub_status) {
        case 1:
          this.subStatus = "Open";
        break;
        case 2:
          this.subStatus = "In Progress";
        break;
        case 3:
          this.subStatus = "Pending";
        break;
        case 5:
          this.subStatus = "Closed";
        break;
      }
      // set data to variables
      this.batch_lot_number = compliance.batch_lot_number;
      this.received_date = compliance.received_date;
      this.safety_call = compliance.safety_call_number;
      this.safety_call_date = compliance.safety_call_date;
      this.natureOFCompliance = compliance.compliant_nature;
      this.causeAnalysis = compliance.cause_analysis;
      this.mnfDetails = compliance.manufacture_investigation_details;
      this.SSDetails = compliance.sample_sensory_details;
      this.labDetails = compliance.lab_test_details;
      this.invDetails = compliance.investigation_extend_details;
      // loop from compliance object and set yes/no for selected button 
      Object.keys(compliance).forEach((key) => {
        switch (compliance[key]) {
          case true:
            this[key] = 'Yes';
          break;
          case false:
            this[key] = 'No';
          break;
        }
      });
    },
    // remove status open for csr lead if already ticket are splited
    removeStatusOpen(DATA) {
      if (DATA.product_compliance[0].sub_status !== null) {
        this.current_status = 'Choose Status';
        this.status_id = null;
      }
    },
    // selected button
    complianceSelectButton(selected, button) {
      switch (selected) {
        case 'Yes':
          this[button] = true;
          break;
        case 'No':
          this[button] = false;
          break;
        default:
          this[button] = null;
          break;
      }
    },
    complianceGetTicket(DATA) {
      // when ticket split set status to open automatic, because dont show if status dont change
      const compliance = DATA.product_compliance[0].sub_status;
      if (compliance !== null && compliance !== 1) {
        this.compliance = true;
      } else {
        this.compliance = false;
      }
    },
    // set main status for compliance
    setStatusCompilance(DATA) {
      const sub_status = DATA.product_compliance[0].sub_status;
      switch (sub_status) {
        case 1:
          this.current_status = "Open";
          this.status_id = sub_status;
        break;
        case 2:
          this.current_status = "In Progress";
          this.status_id = sub_status;
        break;
        case 3:
          this.current_status = "Pending";
          this.status_id = sub_status;
        break;
        case 5:
          this.current_status = "Closed";
          this.status_id = sub_status;
        break;
      }
    },
    // Validate compliance section
    maxLenght(event, field) {
      // add slashes for spec. characters
      this.addslashes(this[field]);
      switch (field) {
        case 'natureOFCompliance': case 'causeAnalysis':
          if (event.length > 300) {
            this[field] = event.substr(0, 300);
            this.showToast('Characters Limit', 'Characters Limit extended, max characters is 300.', 'warning', 2000);
          }
        break;
        case 'mnfDetails': case 'SSDetails': case 'labDetails': case 'invDetails':
          if (event.length > 300) {
            this[field] = event.substr(0, 200);
            this.showToast('Characters Limit', 'Characters Limit extended, max characters is 200.', 'warning', 2000);
          }
        break;
      }
    }
  }
};