import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import VueCookies from 'vue-cookies';
import VueRouter from "vue-router";

Vue.use(Vuex);
Vue.use(VueCookies);
Vue.use(VueRouter);

export default new Vuex.Store({
  state: {
    idToken: null,
    userID: null
  },
  mutations: {
    authUser(state, userData) {
      state.idToken = userData.token;
      state.userID = userData.userID;
    }
  },
  actions: {
    /**
     * LOGIN method
     * accept username and password
     * return jwt token and set cookie
    */
    login({ commit }, authData) {
      axios
        .post(process.env.VUE_APP_LOGIN_URL, {
          username: authData.username,
          password: authData.password
        })
        .then(res => {
          commit("authUser", {
            token: res.data.token
          });
          let token = res.data.token;
          let base64Url = token.split('.')[1];
          let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
          let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          }).join(''));

          const dataFromToken = JSON.parse(jsonPayload);
          const expdate = new Date(dataFromToken.exp * 1000);
          const JWTtoken = token.replace("Bearer ", "");
          // set cookie
          $cookies.set('token', JWTtoken, expdate);
          window.location.replace("/");
        })
    }
  },
  modules: {}
});
